import axios from 'axios';
import {BaseResponse} from "../BaseResponse";
import getFirstMonthOfTheYear from "../../lib/getFirstMonthOfTheYear";
import getNowMonth from "../../lib/getNotMonth";

export async function getGasPriceForm() {
    const response = await axios.get<BaseResponse<GasPriceForm>>('/price/form')

    return response.data
}

export async function getPriceArr(
    startMonth: string = getFirstMonthOfTheYear(),
    endMonth: string = getNowMonth()
) {
    const response = await axios.get<BaseResponse<GasPriceTrend[]>>(`/price`, {
        params: {
            startMonth: startMonth,
            endMonth: endMonth
        }
    })

    return response.data
}

export async function postPrice(gasPriceTrendDto: GasPriceTrend) {
    const response = await axios.post<BaseResponse<string>>(`/price`, gasPriceTrendDto)

    return response.data
}

export async function putPrice(gasPriceTrendDto: GasPriceTrend) {
    const response = await axios.put<BaseResponse<string>>(`/price`, gasPriceTrendDto)

    return response.data
}

export async function deletePrice(uid: string) {
    const response = await axios.delete<BaseResponse<string>>(`/price/${uid}`)

    return response.data
}

export interface GasPriceCompanyType {
    id: number
    title: string
    value: string
    color: string
}

export interface GasPriceEnergyType {
    id: number
    title: string
    value: string
}

export interface GasPriceForm {
    companyType: GasPriceCompanyType[]
    energyType: GasPriceEnergyType[]
}

export interface PriceGetPayload {
    startDate: string
    endDate: string
}

export type GasPrice = {
    companyName: string
    butane: string
    propane: string

    [key: string]: any
}

export type GasPriceTrend = {
    uid: string | undefined
    yearMonth: string
    data: GasPrice[]
}