import React, {useState} from "react";
import BasicNavButton from "../basic/component/BasicNavButton";
import {RowDiv} from "../../components/style/div/RowDiv";
import styled from "styled-components";
import MemberListContainer from "./list/MemberListContainer";
import MemberPendingContainer from "./pending/MemberPendingContainer";
import MemberInactiveContainer from "./inactive/MemberInactiveContainer";
import {TitleDiv} from "../../components/style/div/TitleDiv";
import {TitleText} from "../../components/style/text/TitleText";

interface MemberScreenProps {

}

function MemberScreen(props: MemberScreenProps) {

    const [selected, setSelected] = useState(1)

    return (
        <>
            <TitleDiv>
                <TitleText>
                    회원 관리
                </TitleText>
            </TitleDiv>

            <TabGroupDiv>
                <BasicNavButton isSelected={selected == 1} onClick={() => setSelected(1)}>
                    회원 목록
                </BasicNavButton>
                <BasicNavButton isSelected={selected == 2} onClick={() => setSelected(2)}>
                    승인 대기
                </BasicNavButton>
                <BasicNavButton isSelected={selected == 3} onClick={() => setSelected(3)}>
                    휴면 회원
                </BasicNavButton>
            </TabGroupDiv>

            {selected == 1 && <MemberListContainer/>}
            {selected == 2 && <MemberPendingContainer/>}
            {selected == 3 && <MemberInactiveContainer/>}
        </>
    )
}

const TabGroupDiv = styled(RowDiv)`
  width: 280px;
  justify-content: space-between;
  margin-bottom: 20px;
`

export default MemberScreen