import React, {useEffect} from "react";
import TankRetestScreen from "./TankRetestScreen";
import TankRetestTable from "./TankRetestTable";
import {useAsync2} from "../../../hooks/useAsync2";
import {getRetestList} from "../../../api/tank/retest/getRetestList";
import {getRetestForm} from "../../../api/tank/retest/getRetestForm";


interface TankRecheckContainerProps {

}

function TankRetestContainer(props: TankRecheckContainerProps) {

    const {data: retestList, execute: executeGetRetestList} = useAsync2(getRetestList, true)
    const {data: form} = useAsync2(getRetestForm, true)

    return (
        <>
            <TankRetestScreen
                form={form}
                data={retestList}
                get={executeGetRetestList}
            />
            <TankRetestTable
                data={retestList}
            />
        </>
    )
}

export default TankRetestContainer