import axios from 'axios';
import {BaseResponse} from "./BaseResponse";

export async function signIn(signInRequest: SignInRequest) {
    const response = await axios.post<BaseResponse<ErpAdmin>>(`/signIn`, signInRequest)

    return response.data
}

interface ErpAdminToken {
    accessToken: string,
    refreshToken: string
}

export interface SignInRequest {
    userId: string,
    password: string
}

export interface ErpAdmin {
    uid: string
    adminId: string
    token: ErpAdminToken
}