import {useCallback, useEffect, useState} from "react";
import {AxiosError} from "axios";
import handleHttpError from "../api/HttpErrorHandler";
import {BaseResponse} from "../api/BaseResponse";
import {useDispatch} from "react-redux";
import {refreshTokenAsync} from "../redux/refreshTokenModule";

export const useAsync2 = <T>(
    asyncFunction: (...payload1: any[]) => Promise<BaseResponse<T>>,
    immediate = false,
    immediateParams?: any[],
    executeCallback?: (data: T) => void
) => {
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [data, setData] = useState<T>();
    const [error, setError] = useState<AxiosError | number>();
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    let retryCount = 0;

    const reset = useCallback(() => {
        setData(undefined)
    }, [])

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback(async (...payload1: any[]) => {
        setStatus('loading');
        setIsLoading(true)
        let response = null
        try {
            response = await asyncFunction(...payload1)
            if (response.code == 200) {
                setData(response.result);
                executeCallback?.(response.result)
                setStatus('success');
                setIsLoading(false)
            } else if (response.code == 402) {
                if (retryCount == 0) {
                    retryCount++;
                    dispatch(refreshTokenAsync.request())
                }
            } else {
                handleHttpError(response.code, response.message)
                setError(response.code);
                setStatus('error');
                setIsLoading(false)
            }
        } catch (e) {
            alert("일시적인 오류입니다.")
            setError(error);
            setStatus('error');
            setIsLoading(false)
        }
        return response?.result
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute(immediateParams);
        }
    }, [execute, immediate]);

    return {execute, reset, status, data, error, isLoading};
};