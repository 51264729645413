import React, {useEffect, useState} from 'react';
import TankCertificateScreen from "./TankCertificateScreen";
import {getCertificateList} from "../../../api/tank/certification/getCertificateList";
import TankListTable from "./TankListTable";
import TopButton from "../../../components/style/button/TopButton";
import {getCertificateForm} from "../../../api/tank/certification/getCertificatelForm";
import styled from "styled-components";
import {useAsync2} from "../../../hooks/useAsync2";
import {fileDownload, SingleFileDownload, TankInfo} from "../../../lib/fileDownload";
import {useLocation} from "react-router";
import ConfirmModal from "../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../components/style/button/CircularIndeterminate";


interface TankCertificationContainerComponentProps {
}

function TankCertificationContainer(props: TankCertificationContainerComponentProps) {

    const [tankInfo, setTankInfo] = useState<Array<TankInfo>>([])

    const {data, execute: getList, status: getListStatus} = useAsync2(getCertificateList, false)
    const {data: form} = useAsync2(getCertificateForm, true, [], (data) => {
            getList(1, data.dateType[1].value[0].value, 1)
        }
    )

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    useEffect(() => {
        if (location.search !== "") {
            getList(params.get("startDate"), params.get("endDate"), params.get("searchType"), params.get("searchQuery"))
        }
    }, [location.search])

    function download() {
        if (tankInfo.length == 0) {
            return;
        }
        if (tankInfo.length == 1) {
            SingleFileDownload(tankInfo[0])
        } else {
            fileDownload("저장탱크_합격증.zip", tankInfo)
        }

        setTankInfo([])
    }

    return (
        <>
            {(getListStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankCertificateScreen
                form={form}
                getList={getList}
                result={data?.length}
                selectCount={tankInfo?.length}
                download={download}
            />
            <TankListTable
                data={data}
                setTankInfo={setTankInfo}
                form={form}
            />
            <Div>
                <TopButton/>
            </Div>
        </>
    );
}

export const Div = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export default TankCertificationContainer