import {combineReducers} from "redux";
import {all} from "redux-saga/effects";
import {signInReducer, signInSaga} from "./signInModule";
import {signUpReducer, signUpSaga} from "./signUpModule";
import {checkDuplicateIdReducer, checkDuplicateIdSaga} from "./checkDuplicateIdModule";
import {logOutReducer} from "./logoutModule";

export const accountReducers = combineReducers({
    signInReducer,
    signUpReducer,
    checkDuplicateIdReducer,
    logOutReducer
})

export function* accountSaga() {
    yield all([
        signInSaga(),
        signUpSaga(),
        checkDuplicateIdSaga()
    ]);
}