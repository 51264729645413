import React, {useEffect, useState} from 'react';
import {GridOptions} from "ag-grid-community";
import MemberPendingTableButton from "./MemberPendingTableButton";
import {Member, MemberForm} from "../../../api/member/Member";
import CommonMemberScreen from "../CommonMemberScreen";
import {getFormattedContents} from "../../../components/format/Formatter";
import {getPhoneNumber} from "../../../lib/getManagerPhoneNumber";


interface FormDataType {
    dateType: number
    startDate: string
    endDate: string
    searchType: number
    searchQuery: string
}

interface MemberListProps {
    memberArr: Member[]
    handleGet: (
        dateType: number,
        startDate: string,
        endDate: string,
        searchType: number,
        searchQuery: string) => void
    handlePut: (memberType: Member) => void
    form?: MemberForm
}

function MemberPendingScreen(props: MemberListProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({})

    useEffect(() => {
        if (props.memberArr && props.form) {
            setGridOptions({

                columnDefs: [
                    {
                        headerName: "기능",
                        field: "userId",
                        cellRendererFramework: (data: any) => MemberPendingTableButton(data, props.handlePut),
                        width: 60
                    },
                    {
                        headerName: "회사명",
                        field: "companyName",
                        width: 100
                    },
                    {
                        headerName: "사업자 번호",
                        field: "businessNumber",
                        valueFormatter: (params) => getFormattedContents(
                            params.value, "bizNo"
                        ),
                        width: 90
                    },
                    {
                        headerName: "대표자명",
                        field: "ownerName",
                        width: 84
                    },
                    {
                        headerName: "주소",
                        field: "address",
                        width: 406
                    },
                    {
                        headerName: "담당자명",
                        field: "mobile1.name",
                        width: 84
                    },
                    {
                        headerName: "담당자 연락처",
                        field: "mobile1",
                        valueFormatter: (params) => {
                            return getPhoneNumber(params.value, props.form?.memberPhoneCarrierType)
                        },
                        width: 100
                    },
                    {
                        headerName: "신청 일자",
                        field: "createdAt",
                        valueFormatter: (params) => getFormattedContents(
                            params.value, "YYYY-MM-DD"
                        ),
                        width: 100
                    },
                ],

                rowData: props.memberArr
            })
        }
    }, [props.memberArr, props.form])

    function onSubmit(data: FormDataType) {
        props.handleGet(
            1,
            data.startDate,
            data.endDate,
            data.searchType,
            data.searchQuery
        )
    }

    return (
        <>
            <CommonMemberScreen
                form={props.form}
                onSubmit={onSubmit}
                gridOptions={gridOptions}
                isPending={true}
            />
        </>
    );
}

export default MemberPendingScreen
