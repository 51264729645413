import React from "react";
import SmsRegistrationScreen from "./SmsRegistrationScreen";
import {useAsync2} from "../../../hooks/useAsync2";
import {getSmsRegistrationForm} from "../../../api/sms/registration/getSmsRegistrationForm";
import {getMemberForm, getMemberList} from "../../../api/member/Member";
import {postSmsList} from "../../../api/sms/registration/postSmsList";
import {SmsContainerDto} from "../../../api/sms/getSmsList";
import {getSmsGroupList} from "../../../api/sms/registration/getSmsGroupList";
import {Pageable} from "../../../api/pageable";
import {useHistory} from "react-router-dom";


interface SmsSendContainerProps {

}

function SmsRegistrationContainer(props: SmsSendContainerProps) {
    const history = useHistory()
    const {data: form} = useAsync2(getSmsRegistrationForm, true)
    const {data: memberForm} = useAsync2(getMemberForm, true)
    const {data: memberList, execute: executeMember} = useAsync2(getMemberList, true)
    const {execute: executeSmsGroup} = useAsync2(getSmsGroupList, false)
    const {execute: executePost} = useAsync2(postSmsList, false)

    function getNextSmsGroupList(offset: number, limit: number): Promise<Pageable<SmsContainerDto> | undefined> {
        return executeSmsGroup(offset, limit).then(
            r => {
                return r
            }
        )
    }

    function postSmsGroup(smsGroup: SmsContainerDto) {
        executePost(smsGroup).then(r => {
            if (r?.includes("Complete Save")) {
                alert("메시지 발송 요청이 완료되었습니다.")
            }
            history.go(0)
        })
    }

    return (
        <>
            <SmsRegistrationScreen
                form={form}
                memberForm={memberForm}
                memberList={memberList}
                getNextGroupList={getNextSmsGroupList}
                getMemberList={executeMember}
                post={postSmsGroup}
            />
        </>
    )
}

export default SmsRegistrationContainer