import React from "react";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";

interface TankDownScreenProps {

}

function TankDownScreen(props: TankDownScreenProps) {

    return (
        <>
            <form onSubmit={() => {}}>
                <TitleDiv>
                    <TitleText>
                        출고서류 다운로드
                    </TitleText>
                </TitleDiv>
            </form>
        </>
    )
}

const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 16px;
`

const TitleText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 500;
`

export default TankDownScreen