import React, {useState} from 'react'
import {Member} from "../../../api/member/Member";
import SimpleModal from "../../../components/modal/SimpleModal";
import moment from "moment";
import {MemberTableButton} from "../list/MemberListTableLinkButton";


function MemberPendingTableButton(props: any, handlePut: (memberType: Member) => void) {

    const [showModal, setShowModal] = useState(false)
    const [isApprove, setIsApprove] = useState(false)

    function handleApprove() {
        setIsApprove(true)
        setShowModal(true)
    }

    function handleDisApprove() {
        setIsApprove(false)
        setShowModal(true)
    }

    function onConfirm() {
        handlePut({
            ...props.data,
            acceptDate: moment().format('YYYY-MM-DD'),
            state: 1
        })
    }

    return (
        <React.Fragment>
            <MemberTableButton onClick={handleApprove} style={{marginRight: "5px"}}>
                승인
            </MemberTableButton>

            <SimpleModal
                headerContentText="승인 대기"
                subText={isApprove ? "해당 요청을 승인하시겠습니까?" : "해당 요청을 거절하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showModal}
                onConfirm={onConfirm}
                onCloseModal={() => setShowModal(false)}
            />
        </React.Fragment>
    )
}

export default MemberPendingTableButton
