export function getLocalStorageItemOrNull(key: string) {
    return localStorage.getItem(key)
}

export function getLocalStorageJsonItemOrNull(key: string) {
    const row = localStorage.getItem(key)
    if (row && row.length > 0) {
        return JSON.parse(row)
    }
    return null
}

export default getLocalStorageItemOrNull