import React, {useEffect, useState} from "react";
import {AgGridReact} from "ag-grid-react";
import {
    ColumnApi,
    GridApi,
    GridOptions,
    GridReadyEvent,
    RowClickedEvent,
} from "ag-grid-community";
import TableNumberColumn from "../../../../components/table/TableNumberColumn";
import {TankCheck} from "../../../../api/tank/check/postTankCheckList";

interface TankCheckRegistrationTableRowData {
    title: string
    idx: number
}

interface TankCheckCreationTableProps {
    rowData?: FileList
    setFileIndex: React.Dispatch<React.SetStateAction<number>>
    editData?: | TankCheck
}

function TankCheckRegistrationTable(props: TankCheckCreationTableProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "No",
                width: 54,
                cellRendererFramework: TableNumberColumn
            },
            {
                headerName: "파일 이름",
                width: 182,
                valueFormatter: params => {
                    return params.data.title
                }
            }
        ],

        onRowClicked(event: RowClickedEvent) {
            props.setFileIndex(event.rowIndex)
        },

        getRowNodeId: data => `${data.idx}`,

        rowSelection: "single",

        rowData: []
    })

    useEffect(() => {
        const rowData: TankCheckRegistrationTableRowData[] = []

        if (props.rowData) {
            for (let i = 0; i < props.rowData.length; i++) {
                rowData.push({
                    title: props.rowData[i].name,
                    idx: i
                })
            }
        } else {
            if (props.editData) {
                const url = props.editData.url

                rowData.push({
                    title: decodeURI(url.substring(url.lastIndexOf("__")+2, url.lastIndexOf(".pdf"))) + ".pdf",
                    idx: 0
                })
            }
        }

        setGridOptions({
            ...gridOptions,
            rowData: rowData
        })
    }, [props.rowData, props.editData])

    useEffect(() => {
        if (gridApi) {
            if (gridOptions.rowData) {
                gridApi.setRowData(gridOptions.rowData)
            }
        }
    }, [gridApi, gridOptions.rowData]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    return (
        <div className="ag-theme-alpine" style={{height: 560, width: 238, marginRight: 30}}>
            <AgGridReact
                scrollbarWidth={4}
                onGridReady={onGridReady}
                gridOptions={gridOptions}
                onRowDataChanged={event => {
                    event.api.getRowNode("0")?.selectThisNode(true)
                }}
            >
            </AgGridReact>
        </div>
    )
}

export default TankCheckRegistrationTable