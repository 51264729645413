import React, {useEffect, useState} from "react";
import {SalesAccount} from "../../../../../api/sales/account/getSalesAccountList";
import {GridOptions} from "ag-grid-community";
import TableNumberColumn from "../../../../../components/table/TableNumberColumn";
import TankCertificateModalButton from "./TankCertificateModalButton";
import Input from "../../../../../components/style/input/Input";
import styled from "styled-components";
import Button from "../../../../../components/style/button/Button";
import {ItemBoxDiv} from "../../../../../components/style/div/ItemBoxDiv";
import ModalHeader from "../../../../../components/modal/Header";
import ErpTableComponent from "../../../../../components/table/ErpTable";
import {FormProvider, useForm} from "react-hook-form";
import BaseModal from "../../../../../components/modal/BaseModal";


interface TankCertificateSearchForSalesAccountModalProps {
    showModal: boolean
    onCloseModal: () => void
    onConfirm: (salesAccountName: string) => void
    salesAccountList?: SalesAccount[]
    getSalesAccountList: (
        memberId: string,
        code: string,
        accountName: string,
        address: string,
        phone: string
    ) => void
    memberId: string
}

function TankCertificateSalesAccountModal(props: TankCertificateSearchForSalesAccountModalProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "No",
                width: 54,
                cellRendererFramework: TableNumberColumn
            },
            {
                headerName: "코드",
                field: "code",
                width: 96
            },
            {
                headerName: "판매처명",
                field: "accountName",
                width: 86
            },
            {
                headerName: "휴대폰번호",
                field: "tel",
                width: 176
            },
            {
                headerName: "주소",
                field: "address",
                width: 300
            },
            {
                headerName: "기능",
                cellRenderer: "modalButton",
                width: 60
            }
        ],

        rowData: [],

        frameworkComponents: {
            modalButton: (params: any) => TankCertificateModalButton(params, onConfirm)
        }
    })

    useEffect(() => {
        props.getSalesAccountList(
            props.memberId,
            methods.watch("code"),
            methods.watch("accountName"),
            methods.watch("address"),
            methods.watch("phone")
        )
    }, [props.memberId])

    useEffect(() => {
        if (props.salesAccountList) {
            setGridOptions({
                ...gridOptions,
                rowData: props.salesAccountList
            })
        }
    }, [props.salesAccountList])

    function onConfirm(data: SalesAccount) {
        props.onConfirm(data.accountName)
        closeModal()
    }

    function closeModal() {
        props.onCloseModal()
    }

    function onSubmit() {
        props.getSalesAccountList(
            props.memberId,
            methods.watch("code"),
            methods.watch("accountName"),
            methods.watch("address"),
            methods.watch("phone")
        )
    }

    return (
        <FormProvider {...methods}>
            <form>
                <BaseModal showModal={props.showModal} onCancel={closeModal}>
                    <ModalDiv>
                        <ModalHeader title={"판매처 검색"} onCancel={closeModal}/>
                        <ContentDiv>
                            <SearchSection>
                                <Row>
                                    <DisplayDiv1>
                                        <ItemDiv>
                                            <ItemBoxDiv>
                                                코드
                                            </ItemBoxDiv>
                                            <ItemInput name={"code"} ref={methods.register}/>
                                        </ItemDiv>
                                        <ItemDiv>
                                            <ItemBoxDiv>
                                                주소
                                            </ItemBoxDiv>
                                            <ItemInput name={"address"} ref={methods.register}/>
                                        </ItemDiv>
                                    </DisplayDiv1>
                                    <DisplayDiv2>
                                        <ItemDiv>
                                            <ItemBoxDiv>
                                                거래처명
                                            </ItemBoxDiv>
                                            <ItemInput name={"accountName"} ref={methods.register}/>
                                        </ItemDiv>
                                        <ItemDiv>
                                            <ItemBoxDiv>
                                                전화번호
                                            </ItemBoxDiv>
                                            <ItemInput name={"phone"} ref={methods.register}/>
                                        </ItemDiv>
                                    </DisplayDiv2>
                                    <SearchButton onClick={onSubmit}>검색</SearchButton>
                                </Row>
                            </SearchSection>
                            <ErpTableComponent tableWidth={640} tableHeight={500} gridOptions={gridOptions}/>
                        </ContentDiv>
                    </ModalDiv>
                </BaseModal>
            </form>
        </FormProvider>
    )
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 832px;
  border-radius: 4px;
  background-color: var(--white);
`

const ContentDiv = styled.div`
  display: flex;
  height: 710px;
  flex-direction: column;
  padding: 22px 32px 32px 32px;
`

const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  width: 300px;
`

const ItemInput = styled(Input)`
  display: flex;
  box-sizing: border-box;
  height: 32px;
  margin-left: 8px;
  width: 180px;
`

const DisplayDiv1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 280px;
`

const DisplayDiv2 = styled(DisplayDiv1)`
  width: 280px;
  margin-left: 16px;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 638px;
  align-items: center;
`

const SearchSection = styled(Row)`
  margin-top: 22px;
  align-items: center;
`

const SearchButton = styled(Button)`
  width: 50px;
  height: 72px;
  border-radius: 4px;
  margin-left: 12px;
  background-color: var(--blue);
  color: var(--white);
`

export default TankCertificateSalesAccountModal