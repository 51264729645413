import React from 'react';
import SignUpScreen from "./SignUpScreen";
import {useAsync} from "../../hooks/useAsync";
import {SignUpRequest, signUp} from "../../api/signup/signUp";
import {useHistory} from "react-router-dom";
import {LINK_SIGN_IN} from "../../resources/LinkConstants";

interface SignUpContainerProps {

}

function SignUpContainer(props: SignUpContainerProps) {
    const history = useHistory()
    const {isLoading: loading, execute: executeSignUp} = useAsync(signUp, null, false)

    function onConfirm(payload: SignUpRequest) {
        executeSignUp(payload).then(r => {
            history.push(LINK_SIGN_IN)
        })
    }

    return (
        <SignUpScreen onConfirm={onConfirm} loading={loading}/>
    );
}

export default SignUpContainer