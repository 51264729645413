import React from "react"
import Button from "../../../components/style/button/Button";
import styled from "styled-components";
import {getLocalStorageJsonItemOrNull} from "../../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_JWT} from "../../../resources/LocalStorageConstants";


function MemberListTableLinkButton(props: any) {

    function handleClick() {
        const erpToken = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_JWT)
        const memberUid = props.data.uid
        const userFrontUrl = process.env.REACT_APP_USER_ERP_FRONT_URL

        const tab = window.open(`${userFrontUrl}/signIn/${erpToken.accessToken}/${memberUid}`, '_blank')

        if (tab) {
            tab.opener = null
        }
    }

    return (
        <>
            <MemberTableButton onClick={handleClick}>
                {props.colDef.headerName}
            </MemberTableButton>
        </>
    )
}

export const MemberTableButton = styled(Button)`
  width: 35px;
  height: 25px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
`

export default MemberListTableLinkButton