import React, {useEffect, useState} from 'react';
import ErpTableComponent from "../../../components/table/ErpTable";
import {GridOptions} from "ag-grid-community";
import {Retest, RetestTank} from "../../../api/tank/retest/getRetestList";
import TankRetestTableLinkText from "./TankRetestTableLinkText";

interface TankRetestTableProps {
    data?: Array<Retest>
}

function TankRetestTable(props: TankRetestTableProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "회원사명",
                field: "companyName",
                width: 205,
            },
            {
                headerName: "신청일",
                field: "requestDate",
                cellRendererFramework: TankRetestTableLinkText,
                width: 205
            },
            {
                headerName: "완료요청일",
                field: "completionRequestDate",
                width: 205
            },
            {
                headerName: "완료일",
                field: "completionDate",
                width: 205
            },
            {
                headerName: "진행도",
                field: "retestTankList",
                width: 204,
                valueFormatter: params => {
                    const retestTankList = params.value
                    let completionCount = 0;
                    let processingCount = 0;

                    retestTankList.forEach((retestTank: RetestTank) => {
                        retestTank.state == 1 ? processingCount++ : completionCount++
                    })

                    return `${completionCount == retestTankList.length ? "완료" : "접수중"} ( ${completionCount}/${retestTankList.length} )`
                }
            }
        ],

        rowData: []
    })

    useEffect(() => {
        if (props.data) {
            setGridOptions({
                ...gridOptions,
                rowData: props.data
            })
        }
    }, [props.data])

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                gridOptions={gridOptions}
                withNumber={true}
            />
        </>

    )
}

export default TankRetestTable
