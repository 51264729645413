import {ActionType, createAsyncAction, createReducer} from "typesafe-actions";
import {AxiosError} from "axios";
import {AsyncState, asyncState, createAsyncReducer, transformToArray} from "../../lib/reducerUtils";
import createAsyncSaga from "../../lib/createAsyncSaga";
import {takeEvery} from "redux-saga/effects";
import {checkDuplicateId} from "../../api/signup/checkDuplicateId";

const CHECK_DUPLICATE_ID = 'checkDuplicateId/CHECK_DUPLICATE_ID'
const CHECK_DUPLICATE_ID_SUCCESS = 'checkDuplicateId/CHECK_DUPLICATE_ID_SUCCESS';
const CHECK_DUPLICATE_ID_ERROR = 'checkDuplicateId/CHECK_DUPLICATE_ID_ERROR';

export const checkDuplicateIdAsync = createAsyncAction(
    CHECK_DUPLICATE_ID,
    CHECK_DUPLICATE_ID_SUCCESS,
    CHECK_DUPLICATE_ID_ERROR
)<string, boolean, AxiosError>();

const asyncAction = checkDuplicateIdAsync

type CheckDuplicateIdState = {
    checkDuplicateIdResponse: AsyncState<boolean>
};

export const checkDuplicateIdReducer = createReducer<CheckDuplicateIdState, ActionType<typeof asyncAction>>({
    checkDuplicateIdResponse: asyncState.initial()
}).handleAction(
    transformToArray(asyncAction),
    createAsyncReducer(asyncAction, "checkDuplicateIdResponse")
)

export function* checkDuplicateIdSaga() {
    yield takeEvery(CHECK_DUPLICATE_ID, createAsyncSaga(asyncAction, checkDuplicateId));
}