import React, {useEffect, useRef, useState} from "react";
import Close from "../../../../../assets/images/signup/ic-48-close@3x.png"
import {RowDiv} from "../../../../../components/style/div/RowDiv";
import {FileInput, FileNameText} from "../../../../../components/style/input/InputFile";
import DefaultImage from "../../../../../components/style/image/DefaultImage";
import styled from "styled-components";
import FixedWeightText from "../../../../../components/style/text/FixedWeightText";
import {TitleButton} from "../../../../../components/style/button/TitleButton";
import {fileToBase64} from "../../../../../lib/fileToBase64";

function sliceFileUrl(url: string): string {
    return sliceFullFileName(
        url.substring(url.lastIndexOf("__") + 2),
        25
    )
}

function sliceFullFileName(fullFileName: string, maxLength: number = 10): string {
    return fullFileName.length >= maxLength
        ? fullFileName.substr(0, maxLength) + "..."
        : fullFileName
}

interface TableFileInputProps {
    props: any,
}

function TableFileInput(tableFileInputProps: TableFileInputProps) {

    const [fileName, setFileName] = useState<string>("")
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (tableFileInputProps.props.value?.url) {
            setFileName(sliceFileUrl(tableFileInputProps.props.value.url))
        } else if (tableFileInputProps.props.value?.fullFileName && tableFileInputProps.props.value?.base64Str) {
            setFileName(sliceFullFileName(tableFileInputProps.props.value.fullFileName, 25))
        }
    }, [])

    async function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {

        const fileList = event.currentTarget.files

        if (fileList && fileList.length !== 0) {

            const file = fileList[0]
            const value = tableFileInputProps.props.value

            await fileToBase64(file).then(base64Str => {
                if (base64Str) {
                    tableFileInputProps.props.setValue({
                        ...value,
                        fullFileName: file.name,
                        base64Str: base64Str.substring(base64Str.indexOf(",") + 1)
                    })
                }
            })
        }
    }

    function handleDrop() {
        tableFileInputProps.props.setValue({
            ...tableFileInputProps.props.value,
            url: undefined,
            fullFileName: undefined,
            base64File: undefined
        })
        setFileName("")
    }

    function handleFileBtn(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (
            hiddenFileInput &&
            hiddenFileInput.current
        ) {
            hiddenFileInput.current.click()
        }
    }

    return (
        <>
            <RowDiv>
                <FileBtn onClick={handleFileBtn} width={"40px"} height={"30px"}>
                    <FixedWeightText>파일</FixedWeightText>
                </FileBtn>
                <FileInput
                    type="file"
                    name="image"
                    multiple={false}
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                />

                {fileName &&
                <>
                    <div>
                        <FileNameText>
                            {fileName}
                        </FileNameText>
                    </div>
                    <DefaultImage
                        src={Close}
                        width={"24px"}
                        height={"24px"}
                        onClick={handleDrop}
                    />
                </>
                }
            </RowDiv>
        </>
    )
}

type FileBtnProps =
    {
        width?: string,
        height?: string,
        margin?: string
    }

export const FileBtn = styled(TitleButton)
    < FileBtnProps > `
    
    width: ${(props) => props.width || "80px"};
    height: ${(props) => props.height || "48px"};
    margin: ${(props) => props.margin || "0 0 0 6px"};
    `

export default TableFileInput