import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";

export default forwardRef((props: any, ref) => {
    const name = props.colDef.field
    const {control, setValue, watch} = useFormContext()
    const [inputValue, setInputValue] = useState(watch(name) ? watch(name) : props.value)

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(name, event.target.value as string)
    }

    useEffect(() => {
        setInputValue(watch(name) ? watch(name) : props.value)
    }, [watch(name), props.value])

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                // return inputValue
                return watch(name)
            }
        }
    })

    return (
        <>
            <Controller
                name={name}
                control={control}
                // to hide the error message
                defaultValue={inputValue}
                render={() => (
                    <input
                        style={{
                            width:`${props.colDef.width}px`,
                            textAlign: "center",
                            paddingLeft: "2px",
                            border: "none",
                            borderRight: "0px",
                            borderTop: "0px",
                            borderLeft: "0px",
                            borderBottom: "0px"
                        }}
                        type={name == "tankReleaseCertificateCount" ? "number" : undefined}
                        className="ag-input-field-input ag-text-field-input"
                        onChange={onChange}
                        value={inputValue ? inputValue : ""}
                    />
                )}
            />
        </>
    )
})
