import React from "react";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import {TankRelease} from "../../../../api/tank/release/getTankReleaseList";
import {ICellRendererParams} from "ag-grid-community";

interface TankReleaseCertificateProps {
    data: ICellRendererParams
}

function TankReleaseCertificateSerialNumber(props: TankReleaseCertificateProps) {
    const tankRelease = props.data.data as TankRelease

    return (
        <Div>
            {tankRelease.adminTankReleaseCertificateDtoList.map((tankReleaseCertificate, key) => {
                return (
                    <Flex1 key={key}>
                        <StyledText
                            color={tankReleaseCertificate.isRegistration ? "var(--black)" : "var(--red)"}
                        >
                            {tankReleaseCertificate.serialNumber}
                        </StyledText>
                    </Flex1>
                )
            })}
        </Div>
    )
}

const Div = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const Flex1 = styled.div`
  display: flex;
  width: 40px;
  justify-content: center;
`

const StyledText = styled(FixedWeightText)`
  font-size: 100%;
  font-weight: 400;
  color: ${(props) => props.color};
`

export default TankReleaseCertificateSerialNumber