import React, {ChangeEvent, useEffect, useState} from "react";
import PostCodeButton from "../../../../components/postcode/PostCodeButton";
import styled from "styled-components";
import ItemBox from "../../../../components/style/div/ItemBox";
import {FormProvider, useForm} from "react-hook-form";
import TopButton from "../../../../components/style/button/TopButton";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import {Member, MemberForm} from "../../../../api/member/Member";
import MemberInfoCMSRadioButton from "./MemberInfoCMSRadioButton";
import {getFormattedContents} from "../../../../components/format/Formatter";
import {ItemBoxDiv} from "../../../../components/style/div/ItemBoxDiv";
import Input from "../../../../components/style/input/Input";
import Select from "../../../../components/style/select/Select";
import {
    RequiredRulesAndValidationTelNumber2,
    RequiredRulesWithoutValidation
} from "../../../../components/form/BaseFormType";
import {TitleDiv} from "../../../../components/style/div/TitleDiv";
import {TitleText} from "../../../../components/style/text/TitleText";
import {TitleButtonDiv} from "../../../../components/style/div/TitleButtonDiv";
import {LINK_MEMBER} from "../../../../resources/LinkConstants";
import CancelButton from "../../../../components/style/button/CancelButton";
import ConfirmButton from "../../../../components/style/button/ConfirmButton";


interface MemberInfoProps {
    handleEdit: (request: Member) => void
    handleDelete: (userId: string) => void
    member?: Member
    form?: MemberForm
}

function MemberInfoScreen(props: MemberInfoProps) {
    const methods = useForm({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const {watch} = methods
    const [address, setAddress] = useState("")
    const [deliveryAddress, setDeliveryAddress] = useState("")
    const [isCms, setIsCms] = useState(true)
    const [isReadOnly, setIsReadOnly] = useState(true)
    const [emailValue, setEmailValue] = useState(0)

    useEffect(() => {
        if (props.member && props.form) {
            const mobile1 = props.member.mobile1
            const mobile2 = props.member.mobile2
            const mobile3 = props.member.mobile3

            setAddress(props.member.address)
            setDeliveryAddress(props.member.deliveryAddress)
            setIsCms(props.member.isCmsUsed)

            methods.setValue("companyAuthNo", props.member.companyAuthNo)
            methods.setValue("companyName", props.member.companyName)
            methods.setValue("userId", props.member.userId)
            methods.setValue("password", "")
            methods.setValue("businessNumber", getFormattedContents(props.member.businessNumber, "bizNo"))
            methods.setValue("addressDetail", props.member.addressDetail)
            methods.setValue("deliveryAddressDetail", props.member.deliveryAddressDetail)
            methods.setValue("mobile1.name", mobile1.name)
            methods.setValue("mobile1.number1", mobile1.number1)
            methods.setValue("mobile1.number2", mobile1.number2)
            methods.setValue("mobile2.name", mobile2?.name)
            methods.setValue("mobile2.number1", mobile2?.number1)
            methods.setValue("mobile2.number2", mobile2?.number2)
            methods.setValue("mobile3.name", mobile3?.name)
            methods.setValue("mobile3.number1", mobile3?.number1)
            methods.setValue("mobile3.number2", mobile3?.number2)
            methods.setValue("fax", getFormattedContents(props.member.fax, "phoneNo"))
            methods.setValue("email.name", props.member.email?.name)
            methods.setValue("email.email", props.member.email?.email)
            methods.setValue("email.domain", props.member.email?.domain)
            methods.setValue("acceptDate", props.member.acceptDate)
            methods.setValue("memo1", props.member.memo1)
            methods.setValue("memo2", props.member.memo2)
        }
    }, [props.member, props.form])

    useEffect(() => {
        if (watch("domain")) {
            const number = watch("domain")
            const domain = props.form?.memberEmailDomainType.filter(it => it.id === number)
            if (domain) {
                if (domain[0].title == "직접입력") {
                    setIsReadOnly(false)
                } else {
                    setIsReadOnly(true)
                    methods.setValue("email.domain", domain[0].title)
                }
            }
        }
    }, [watch("domain")])

    useEffect(() => {
        if (watch("email.domain")) {
            const domain = watch("email.domain")

            props.form?.memberEmailDomainType.forEach(emailType => {
                if (emailType.title == domain) {
                    setEmailValue(emailType.id)
                    return
                }
            })
        }
    }, [watch("email.domain")])

    function handleDomain(event: ChangeEvent<HTMLInputElement>) {
        methods.setValue("email.domain", event.target.value, {
            shouldValidate: true
        })
    }

    function onInactive() {
        if (props.member) {
            props.handleEdit({
                ...props.member,
                state: 2
            })
        }
    }

    function onDelete() {
        props.handleDelete(props.member!!.userId)
    }

    function onEdit() {
        if (props.member && props.form) {
            const data = methods.getValues()

            props.handleEdit({
                ...props.member,
                companyName: data.companyName,
                userId: data.userId,
                password: data.password,
                businessNumber: getFormattedContents(data.businessNumber, "removeHyphen"),
                address: data.address,
                addressDetail: data.addressDetail,
                deliveryAddress: data.deliveryAddress,
                deliveryAddressDetail: data.deliveryAddressDetail,
                mobile1: data.mobile1,
                mobile2: data.mobile2,
                mobile3: data.mobile3,
                fax: data.fax ? getFormattedContents(data.fax, "removeHyphen") : undefined,
                email: data.email,
                isCmsUsed: isCms,
                memo1: data.memo1,
                memo2: data.memo2
            })
        }
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(() => {
            })}>
                <TitleDiv>
                    <TitleText>
                        회사 편집
                    </TitleText>
                    <TitleButtonDiv>
                        <ConfirmButton
                            title="휴면전환"
                            modalTitle={"회사 편집"}
                            modalText="해당 회원을 휴면전환 하시겠습니까?"
                            onSubmit={onInactive}
                            width={84}
                            marginRight={10}
                        />
                        <CancelButton
                            link={LINK_MEMBER}
                            marginRight={10}
                        />
                        <ConfirmButton
                            title="삭제"
                            modalTitle={"회사 편집"}
                            modalText="해당 회원을 삭제하시겠습니까?"
                            onSubmit={onDelete}
                            marginRight={10}
                        />
                        <ConfirmButton
                            title="수정"
                            modalTitle={"회사 편집"}
                            modalText="해당 회원 정보를 수정하시겠습니까?"
                            onSubmit={onEdit}
                        />
                    </TitleButtonDiv>
                </TitleDiv>

                <RowDiv style={{marginTop: "35px"}}>
                    <MarginRightColumnDiv>
                        <ItemBox
                            title={"코드"}
                            name={"companyAuthNo"}
                            readOnly={true}
                            reference={methods.register({
                                required: true,
                            })}
                        />
                        <ItemBox
                            title={"회사명"}
                            name={"companyName"}
                            reference={methods.register({
                                required: true,
                            })}
                        />
                        <ItemBox
                            title={"아이디"}
                            name={"userId"}
                            readOnly={true}
                            reference={methods.register({
                                required: false,
                            })}
                        />
                        <ItemBox
                            title={"비밀번호"}
                            name={"password"}
                            type={"password"}
                            reference={methods.register({
                                required: false,
                            })}
                        />
                        <ItemBox
                            title={"사업자번호"}
                            name={"businessNumber"}
                            reference={methods.register({
                                required: false,
                            })}
                        />
                        <CenterDiv>
                            <ItemBox
                                title={"회사 주소"}
                                readOnly={true}
                                value={address}
                                name={"address"}
                                reference={methods.register({
                                    required: true,
                                })}
                            />
                            <AddressSearchButton handleValue={setAddress}/>
                        </CenterDiv>

                        <ItemBox title={"상세주소"} name={"addressDetail"} reference={methods.register}/>

                        <CenterDiv>
                            <ItemBox
                                title={"택배 배송 주소"}
                                readOnly={true}
                                value={deliveryAddress}
                                name={"deliveryAddress"}
                                reference={methods.register({
                                    required: true,
                                })}
                            />
                            <AddressSearchButton handleValue={setDeliveryAddress}/>
                        </CenterDiv>

                        <ItemBox title={"상세주소"} name={"deliveryAddressDetail"} reference={methods.register}/>
                    </MarginRightColumnDiv>

                    <ColumnDiv>
                        <Mobile>
                            <MobileTitle>
                                <Text>
                                    {"담당자 연락처"}
                                </Text>
                            </MobileTitle>
                            <ColumnDiv>
                                <ItemDiv>
                                    <NameAndBodyInputField
                                        name={"mobile1.name"}
                                        ref={methods.register(RequiredRulesWithoutValidation)}
                                        placeholder={"담당자명"}
                                    />
                                    <MobileSelectBox
                                        menuItems={props.form?.memberPhoneCarrierType}
                                        defaultValue={1}
                                        name={"mobile1.carrier"}
                                        reference={methods.register}
                                    />
                                    <MobileField
                                        name={"mobile1.number1"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                    <MobileField
                                        name={"mobile1.number2"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <NameAndBodyInputField
                                        name={"mobile2.name"}
                                        ref={methods.register(RequiredRulesWithoutValidation)}
                                        placeholder={"담당자명"}
                                    />
                                    <MobileSelectBox
                                        menuItems={props.form?.memberPhoneCarrierType}
                                        defaultValue={1}
                                        name={"mobile2.carrier"}
                                        reference={methods.register}
                                    />
                                    <MobileField
                                        name={"mobile2.number1"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                    <MobileField
                                        name={"mobile2.number2"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                </ItemDiv>
                                <ItemDiv>
                                    <NameAndBodyInputField
                                        name={"mobile3.name"}
                                        ref={methods.register(RequiredRulesWithoutValidation)}
                                        placeholder={"담당자명"}
                                    />
                                    <MobileSelectBox
                                        menuItems={props.form?.memberPhoneCarrierType}
                                        defaultValue={1}
                                        name={"mobile3.carrier"}
                                        reference={methods.register}
                                    />
                                    <MobileField
                                        name={"mobile3.number1"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                    <MobileField
                                        name={"mobile3.number2"}
                                        ref={methods.register(RequiredRulesAndValidationTelNumber2)}
                                    />
                                </ItemDiv>
                            </ColumnDiv>
                        </Mobile>

                        <ItemBox
                            title={"팩스 번호"}
                            name={"fax"}
                            reference={methods.register}
                        />

                        <Email>
                            <EmailTitle>
                                <Text>
                                    {"이메일"}
                                </Text>
                            </EmailTitle>
                            <EmailRowDiv>
                                <MailNameInputField
                                    name={"email.name"}
                                    ref={methods.register}
                                    placeholder={"담당자명"}
                                />
                                <MailField
                                    name={"email.email"}
                                    ref={methods.register(RequiredRulesWithoutValidation)}
                                />
                                <AtDiv>@</AtDiv>
                                <MailField
                                    name={"email.domain"}
                                    ref={methods.register(RequiredRulesWithoutValidation)}
                                    readOnly={isReadOnly}
                                    onChange={handleDomain}
                                />
                                <MailSelectBox
                                    name={"domain"}
                                    reference={methods.register}
                                    menuItems={props.form?.memberEmailDomainType}
                                    value={emailValue}
                                />
                            </EmailRowDiv>
                        </Email>

                        <MemberInfoCMSRadioButton
                            isCms={isCms}
                            setIsCms={setIsCms}
                        />

                        <ItemBox
                            title={"승인 날짜"}
                            name={"acceptDate"}
                            readOnly={true}
                            reference={methods.register}
                        />

                        <BlankDiv/>
                        <BlankDiv/>
                    </ColumnDiv>
                </RowDiv>

                <MarginColumnDiv>
                    <ItemBox title={"비고1"} name={"memo1"} reference={methods.register}/>
                    <ItemBox title={"비고2"} name={"memo2"} reference={methods.register}/>
                </MarginColumnDiv>
            </form>
            <TopButton/>
        </FormProvider>
    )
}

const RowDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`

const CenterDiv = styled(RowDiv)`
  width: 100%;
  align-items: center;
`

const ColumnDiv = styled(RowDiv)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding-left: 10px;
`

const MarginRightColumnDiv = styled(ColumnDiv)`
  margin-right: 16px;
`

const BlankDiv = styled.div`
  flex: 1;
`

const MarginColumnDiv = styled(ColumnDiv)`
  margin-top: 42px;
`

const AddressSearchButton = styled(PostCodeButton)`
  width: 80px;
  height: 48px;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 400;
  color: var(--white);
  background-color: var(--blue);
`

const Mobile = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const MobileTitle = styled(ItemBoxDiv)`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 120px;
  height: 64px;
  padding-left: 16px;
  padding-right: 20px;
  background-color: var(--pale-grey);
  align-items: center;
`

const Text = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
`

const ItemDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 10px;
`

const NameAndBodyInputField = styled(Input)`
  display: flex;
  width: 90px;
  height: 48px;
  margin-right: 8px;
`

const MobileSelectBox = styled(Select).attrs({
    width: "120px",
    height: "48px",
})``

type InputFieldProps = {
    error?: boolean
}

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width: 420px;
  height: 48px;
`

const MobileField = styled(InputField)`
  width: 105px;
  margin-left: 8px;
`

const Email = styled(Mobile)``
const EmailTitle = styled(MobileTitle)``
const EmailRowDiv = styled(RowDiv)`
  margin-left: 10px;
`

const MailNameInputField = styled(Input)`
  display: flex;
  width: 90px;
  height: 48px;
  margin-right: 8px;
`

const MailSelectBox = styled(Select).attrs({
    width: "120px",
    height: "48px",
})``

const MailField = styled(ErrorInputFieldWithGuide)`
  width: 93.5px;
  height: 48px;
  margin-right: 8px;
`

const AtDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

export default MemberInfoScreen