import React, {useState, forwardRef, useImperativeHandle} from "react";
import styled from "styled-components";
import Moment from "moment";
import {SearchHalfDatePicker} from "../../../../components/style/datepicker/SearchHalfDatePicker";


export default forwardRef((props: any, ref) => {
    const [selectedDate, setSelectedDate] = useState<string>(
        props.value == ""
            ? Moment().format('YYYY-MM')
            : props.value
    )

    function handleDateChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        setSelectedDate(Moment(event.currentTarget.value).format('YYYY-MM'))
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null
                if (selectedDate) {
                    dateString = Moment(selectedDate).format('YYYY-MM')
                }
                return dateString
            },
            isCancelAfterEnd: () => {
                return !selectedDate
            }
        }
    })

    return (
        <StyledDatePicker
            name="period"
            value={selectedDate}
            onChange={handleDateChange}
            placeholder={'Enter ' + props.column.colId}
        />
    )
})

const StyledDatePicker = styled(SearchHalfDatePicker)`
  width: 135px;
  height: 42px;
`