import {useCallback, useEffect, useState} from "react";
import {AxiosError} from "axios";
import handleHttpError from "../api/HttpErrorHandler";
import {BaseResponse} from "../api/BaseResponse";

export const useAsync = <T, R>(
    asyncFunction: (payloads: R) => Promise<BaseResponse<T>>,
    payload: R | null = null,
    immediate = false
) => {
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [data, setData] = useState<T>();
    const [error, setError] = useState<AxiosError>();
    const [isLoading, setIsLoading] = useState(false)

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback((payloads: R) => {
        setStatus('loading');
        setIsLoading(true)

        return asyncFunction(payloads)
            .then((response: any) => {
                if (response.code == 200) {
                    setData(response.result);
                    setStatus('success');
                    setIsLoading(false)
                } else {
                    handleHttpError(response.code, response.message)
                    setError(response.code);
                    setStatus('error');
                    setIsLoading(false)
                }

                console.log(response)
                return response.result
            })
            .catch((error: any) => {
                alert("일시적인 오류입니다.")
                setError(error);
                setStatus('error');
                setIsLoading(false)

                console.log(error)
            });
    }, [asyncFunction]);

    // Call execute if we want to fire it right away.
    // Otherwise execute can be called later, such as
    // in an onClick handler.
    useEffect(() => {
        if (immediate) {
            execute(payload!!);
        }
    }, [execute, immediate]);

    return {execute, status, data, error, isLoading};
};