import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {LINK_TANK_REGISTRATION} from "../../resources/LinkConstants";
import {Link} from "react-router-dom";
import TankCertificationContainer from "./certificate/TankCertificationContainer";
import BasicNavButton from "../basic/component/BasicNavButton";
import TankCheckContainer from "./check/TankCheckContainer";
import {RowDiv} from "../../components/style/div/RowDiv";
import {CommonButton} from "./CommonButton";
import TankRetestContainer from "./retest/TankRetestContainer";
import {TitleButtonDiv} from "../../components/style/div/TitleButtonDiv";
import {TitleText} from "../../components/style/text/TitleText";
import {TitleDiv} from "../../components/style/div/TitleDiv";
import TankReleaseContainer from "./release/TankReleaseContainer";


interface TankScreenProps {
    page?: number
}

function TankScreen(props: TankScreenProps) {

    const [select, setSelect] = useState(1);

    useEffect(() => {
        if (props.page) {
            setSelect(props.page)
        }
    }, [props.page])

    return (
        <>
            <TitleDiv>
                <TitleText>
                    탱크관리
                </TitleText>
                <TitleButtonDiv>
                    <Link to={LINK_TANK_REGISTRATION} style={{textDecoration: 'none'}}>
                        <CommonButton>
                            합격증 등록
                        </CommonButton>
                    </Link>
                </TitleButtonDiv>
            </TitleDiv>

            <TabGroupDiv>
                <BasicNavButton width={"78px"} isSelected={select == 1} onClick={() => setSelect(1)}>
                    합격증리스트
                </BasicNavButton>
                <BasicNavButton width={"65px"} isSelected={select == 2} onClick={() => setSelect(2)}>
                    검사
                </BasicNavButton>
                <BasicNavButton width={"65px"} isSelected={select == 3} onClick={() => setSelect(3)}>
                    재검사신청
                </BasicNavButton>
                <BasicNavButton width={"65px"} isSelected={select == 4} onClick={() => setSelect(4)}>
                    출고
                </BasicNavButton>
            </TabGroupDiv>

            {select == 1 && <TankCertificationContainer/>}
            {select == 2 && <TankCheckContainer/>}
            {select == 3 && <TankRetestContainer/>}
            {select == 4 && <TankReleaseContainer/>}
        </>
    );
}

const TabGroupDiv = styled(RowDiv)`
  width: 350px;
  justify-content: space-between;
  margin-bottom: 20px;
`

export default TankScreen