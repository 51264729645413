import React, {useState} from 'react';
import SectionTitle from "../../../../components/style/div/SectionTitle";
import {Pageable} from "../../../../api/pageable";
import {Sms, SmsContainerDto} from "../../../../api/sms/getSmsList";
import {GridOptions, IGetRowsParams} from "ag-grid-community";
import ErpTable from "../../../../components/table/ErpTable";
import {getFormattedContents} from "../../../../components/format/Formatter";
import SmsCustomTooltip from "./SmsCustomTooltip";


interface CustomerRecentlySendListProps {
    getNextGroupList: (offset: number, limit: number) => Promise<Pageable<SmsContainerDto> | undefined>
}

function CustomerRecentlySendList(props: CustomerRecentlySendListProps) {

    const PAGE_LIMIT = 10
    const [gridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "발송 일시",
                field: "smsDtoList.0.sendDateTime",
                valueFormatter: (e) => getFormattedContents(
                    e.value, "YYYY-MM-DD HH:mm:ss"
                ),
                width: 134,
                tooltipField: "smsDtoList",
            },
            {
                headerName: "받는 사람",
                field: "smsDtoList.length",
                valueFormatter: (e) => {
                    return e.value ? `${e.value} 명` : ""
                },
                width: 134,
            },
            {
                headerName: "내용",
                field: "smsDtoList.0.contents",
                width: 134,
            },
            {
                headerName: "Byte",
                field: "smsDtoList.0.size",
                width: 134,
            },
            {
                headerName: "구분",
                field: "smsDtoList.0.scheduleType",
                width: 134,
            },
            {
                headerName: "상태",
                field: "smsDtoList",
                valueFormatter: (e) => {
                    const smsList = e.value

                    if (smsList) {
                        return smsList.find((sms: Sms) => sms.state !== "전송 성공")
                            ? "전송 실패"
                            : "전송 성공"
                    }

                    return ""
                },
                width: 134,
            },
        ],

        tooltipShowDelay: 0,

        rowModelType: "infinite",

        pagination: true,

        // 한 페이지에 10개의 row 보여주기
        paginationPageSize: PAGE_LIMIT,

        // 서버에서 한번에 반환하는 행의 개수
        cacheBlockSize: PAGE_LIMIT,

        datasource:
            {
                async getRows(iGetRowsParams: IGetRowsParams) {

                    const result = await props.getNextGroupList(
                        iGetRowsParams.startRow,
                        PAGE_LIMIT
                    )

                    if (result) {
                        iGetRowsParams.successCallback(result.itemList)
                    }
                },
            },

        defaultColDef: {
            flex: 1,
            tooltipComponent: 'smsCustomTooltip',
        },

        frameworkComponents: {
            smsCustomTooltip: SmsCustomTooltip
        }
    })

    return (
        <div>
            <SectionTitle>
                최근 발송 내역
            </SectionTitle>
            <ErpTable
                tableWidth={806}
                tableHeight={360}
                gridOptions={gridOptions}
            />
        </div>
    );
}

export default CustomerRecentlySendList