import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Datepicker from "../../../../../components/style/datepicker/Datepicker";
import Select from "../../../../../components/style/select/Select";
import Input from "../../../../../components/style/input/Input";
import Button from '../../../../../components/style/button/Button';
import {FormProvider, useForm, useFormContext} from "react-hook-form";
import {useAsync} from "../../../../../hooks/useAsync";
import {postHistory} from "../../../../../api/tank/certification/postHistory";
import Moment from 'moment'
import {ExaminationHistory} from "../../../../../api/tank/certification/postCertificate";
import {TankDetailFormType} from '../../../../../api/tank/certification/getCertificateDetailForm';
import FixedWeightText from "../../../../../components/style/text/FixedWeightText";

interface FileTablexComponentProps {
    files: Array<ExaminationHistory>
    form?: Array<TankDetailFormType>
}

function TankExaminationHistoryTable(props: FileTablexComponentProps) {
    const methods = useForm<ExaminationHistory>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const inputFile = useRef<HTMLInputElement>(null)

    const [selectedFile, setSelectedFile] = useState<FileList>()

    const {execute: post} = useAsync(postHistory)

    function handleClick() {
        if (props.files == undefined) {
            alert("합격증을 선택해주세요")
            return
        }

        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    function setFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            setSelectedFile(event.target.files)
        }
    }

    async function handleClickSave() {

        if (methods.watch("testingOrganization") == "") {
            alert("기관을 입력해주세요")
            return
        }

        const formData = new FormData();

        if (selectedFile && selectedFile[0]) {
            formData.append('file', selectedFile[0])

            await upload("testurl")

        }


    }

    function handleDeleteClick(index: number) {
        props.files.splice(index, 1)
        methods.setValue("testDate", methods.watch("testDate"))
    }

    function upload(url: string) {

        const file: ExaminationHistory = {
            testDate: methods.watch("testDate"),
            testType: methods.watch("testType"),
            testingOrganization: methods.watch("testingOrganization"),
            fileName: selectedFile?.[0].name,
            fileUri: url
        }

        props.files.push(file)

        setSelectedFile(undefined)
        methods.setValue("testDate", Moment().format("YYYY-MM-DD"))
        methods.setValue("testType", 0)
        methods.setValue("testingOrganization", "")
    }

    return (
        <FormProvider {...methods}>
            <form>
                <div>
                    <input
                        type={"file"}
                        name={"file"}
                        accept=".pdf"
                        ref={inputFile}
                        style={{display: 'none'}}
                        onChange={setFile}
                        multiple={false}
                    />

                    <Header>
                        <Column2>
                            검사합격일
                        </Column2>
                        <Column2>
                            검사종류
                        </Column2>
                        <Column2>
                            기관
                        </Column2>
                        <Column1>
                            문서
                        </Column1>
                        <Column1>
                            관리
                        </Column1>
                        <Column1/>
                    </Header>
                    <Add>
                        <Column2>
                            <FileDatePicker
                                reference={methods.register}
                                name={"testDate"}
                            />
                        </Column2>
                        <Column2>
                            <FileSelect
                                menuItems={props.form}
                                reference={methods.register}
                                name={"testType"}
                            />
                        </Column2>
                        <Column2>
                            <Institution
                                ref={methods.register}
                                name={"testingOrganization"}
                            />
                        </Column2>
                        <Column1>
                            {selectedFile && selectedFile[0] ?
                                <div onClick={handleClick}>{selectedFile[0].name}</div>
                                :
                                <UploadButton onClick={handleClick}>업로드</UploadButton>
                            }
                        </Column1>
                        <Column1>
                            <UploadButton onClick={handleClickSave}>저장</UploadButton>
                        </Column1>

                        <Column1/>
                    </Add>

                    {props.files?.map((item, index) =>
                        <Add>
                            <Column2>
                                <DefaultText>{item.testDate}</DefaultText>
                            </Column2>
                            <Column2>
                                <DefaultText>{props.form?.[item.testType - 1].title}</DefaultText>
                            </Column2>
                            <Column2>
                                <DefaultText>{item.testingOrganization}</DefaultText>
                            </Column2>
                            <Column1>
                                <DefaultText>{item.fileName}</DefaultText>
                            </Column1>
                            <Column1>
                                <DeleteButton onClick={() => handleDeleteClick(index)}>삭제</DeleteButton>
                            </Column1>
                        </Add>
                    )}
                </div>
            </form>
        </FormProvider>
    );
}

const Header = styled.div`
  display: flex;
  width: 1080px;
  height: 48px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #dee1e2;
`

const UploadButton = styled(Button)`
  width: 48px;
  height: 28px;
  font-size: 10px;
  border: solid 1px #bec0c2;
  background-color: var(--white);
  color : var(--blue);
`

const DeleteButton = styled(UploadButton)`
  color: var(--red);
`

const Add = styled(Header)`
  background-color: var(--white);
`

const DefaultText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 500;
`

const Column1 = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  width: 64px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 8px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;

`

const Column2 = styled(Column1)`
  width: 200px;
`

const FileDatePicker = styled(Datepicker)`
  width: 192px;
  height: 28px;
`

const FileSelect = styled(Select).attrs({
    width: "192px",
    height: "28px",
})``

const Institution = styled(Input)`
  width: 192px;
  height: 28px;
  font-size: 10px;
`


export default TankExaminationHistoryTable