import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {RowDiv} from "../../components/style/div/RowDiv";
import PriceGraph from "./component/PriceGraph";
import {ItemBoxDiv} from "../../components/style/div/ItemBoxDiv";
import {GasPriceForm, GasPriceTrend} from "../../api/price/Price";
import PriceTable from "./component/PriceTable";
import PriceSelectBox from "./component/PriceSelectBox";
import {TitleDiv} from "../../components/style/div/TitleDiv";
import {TitleText} from "../../components/style/text/TitleText";
import {SearchButton} from "../../components/style/button/SearchButton";
import {TableTitleDiv} from "../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../components/style/text/TableTitleText";
import getNowMonth from "../../lib/getNotMonth";
import getFirstMonthOfTheYear from "../../lib/getFirstMonthOfTheYear";
import {DivRange} from "../tank/release/TankReleaseScreen";
import {SearchHalfDatePicker} from "../../components/style/datepicker/SearchHalfDatePicker";


interface PriceProps {
    form?: GasPriceForm
    tableData?: GasPriceTrend[]
    handleGet: (startDate: string, endDate: string) => void
    handlePost: (payload: GasPriceTrend) => void
    handlePut: (payload: GasPriceTrend) => void
    handleDelete: (uid: string) => void
}

function PriceScreen(props: PriceProps) {

    const [firstRow, setFirstRow] = useState<GasPriceTrend>()
    const [tableData, setTableData] = useState<GasPriceTrend[]>([])
    const [graphData, setGraphData] = useState<any[]>([])
    const methods = useForm({
        mode: "onBlur"
    })

    useEffect(() => {

        if (props.tableData && props.form) {
            setGraphData(getGraphData(props.tableData, methods.watch("companyName"), methods.watch("energyType")))
        }

        if (props.form && !firstRow) {
            setFirstRow({
                uid: undefined,
                yearMonth: "",
                data: props.form.companyType.map(company => {
                    return {
                        companyName: company.value,
                        butane: "",
                        propane: ""
                    }
                })
            })
        }

        if (props.tableData && firstRow) {
            setTableData([
                firstRow,
                ...props.tableData
            ])
        }

    }, [props.tableData, props.form, firstRow])

    function getGraphData(tableData: GasPriceTrend[], companyName: string, energyType: number): any[] {

        const graphData: any[] = []

        tableData.forEach(gasPriceTrend => {

            let graph

            switch (companyName) {
                case "all":
                    graph = Object.assign({}, ...gasPriceTrend.data
                        .map(gasPrice => ({[gasPrice.companyName]: parseInt(gasPrice[energyType])})))
                    break
                default:
                    graph = Object.assign({}, ...gasPriceTrend.data
                        .filter(gasPrice => gasPrice.companyName == companyName)
                        .map(gasPrice => ({[gasPrice.companyName]: parseInt(gasPrice[energyType])})))
                    break
            }

            graphData.push({
                yearMonth: gasPriceTrend.yearMonth,
                ...graph
            })
        })

        return graphData.reverse()
    }

    function onSubmit() {
        props.handleGet(
            methods.watch("startDate"),
            methods.watch("endDate")
        )
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TitleDiv>
                    <TitleText>
                        가격 설정
                    </TitleText>
                </TitleDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        기간
                    </ItemBoxDiv>
                    <SearchHalfDatePicker
                        name={"startDate"}
                        defaultValue={getFirstMonthOfTheYear()}
                        reference={methods.register}
                        type={"month"}
                    />
                    <DivRange>~</DivRange>
                    <SearchHalfDatePicker
                        name={"endDate"}
                        defaultValue={getNowMonth()}
                        reference={methods.register}
                        type={"month"}
                    />
                </RowDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        구분
                    </ItemBoxDiv>
                    <PriceSelectBox selectItems={props.form?.companyType} itemDefaultTitle={"전체"}
                                    reference={methods.register} name={"companyName"}/>
                    <PriceSelectBox selectItems={props.form?.energyType} reference={methods.register}
                                    name={"energyType"}/>
                    <SearchButton type="submit">검색</SearchButton>
                </RowDiv>
            </form>

            <PriceGraph
                graphData={graphData}
                companyType={props.form?.companyType}
            />

            <TableTitleDiv>
                <TableTitleText>가격 리스트</TableTitleText>
            </TableTitleDiv>

            <PriceTable
                form={props.form}
                tableData={tableData}
                handlePost={props.handlePost}
                handlePut={props.handlePut}
                handleDelete={props.handleDelete}
            />
        </FormProvider>
    );
}

export default PriceScreen