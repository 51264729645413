import React from "react";
import {Link} from "react-router-dom";
import {LINK_RETEST_REGISTRATION} from "../../../resources/LinkConstants";


function TankRetestTableLinkText(props: any) {

    return (
        <>
            <Link to={LINK_RETEST_REGISTRATION + `/${props.data.uid}`} style={{textDecoration: 'none', justifyContent: 'center'}}>
                {props.data.requestDate}
            </Link>
        </>
    )
}

export default TankRetestTableLinkText