import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {Member} from "../../member/Member";
import {MenuItem} from "../../../components/style/select/Select";

export async function getTankReleaseForm() {
    const response = await axios.get<BaseResponse<TankReleaseForm>>(`/tank/release/form`)

    return response.data
}

export interface TankReleaseForm {
    memberPhoneCarrierType: Array<MenuItem>
    tankReleaseStateType: Array<MenuItem>
    // tankReleaseMemberType: Array<Member>
    memberList: Array<Member>
    tankReleaseSalesManagerType: Array<MenuItem>
    tankReleaseVolumeType: Array<MenuItem>
    tankReleaseControllerType: Array<MenuItem>
    tankReleaseTongsoriType: Array<MenuItem>
    tankReleasePackageType: Array<MenuItem>
}