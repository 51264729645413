import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import Moment from "moment";
import styled from "styled-components";
import {Controller, useFormContext} from "react-hook-form";
import CompactDatePicker from "../../../../../components/style/datepicker/CompactDatePicker";

export default forwardRef((props: any, ref) => {
    const {control, watch, setValue} = useFormContext()
    const datepicker = props.datepicker
    const [selectedDate, setSelectedDate] = useState<string>(
        props.value == ""
            ? Moment().format(datepicker.format)
            : props.value
    )

    useEffect(() => {
        const releaseDate = watch("releaseDate")

        if (datepicker.name == "arrivalDateTime" && releaseDate && releaseDate !== "") {
            const releaseDateTime = Moment(releaseDate).format(datepicker.format)
            const arrivalDateTime = Moment(releaseDateTime).add(1, 'd').add(9, 'h').format(datepicker.format)

            setSelectedDate(arrivalDateTime)
            setValue(datepicker.name, arrivalDateTime)
        }
    }, [watch("releaseDate")])

    function handleDateChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const date = Moment(event.currentTarget.value).format(datepicker.format)

        setSelectedDate(date)
        setValue(datepicker.name, date)
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null
                if (selectedDate) {
                    dateString = Moment(selectedDate).format(datepicker.format)
                }
                return dateString
            },
            isCancelAfterEnd: () => {
                return !selectedDate
            }
        }
    })

    return (
        <>
            <Controller
                name={datepicker.name}
                // to hide the error message
                defaultValue={selectedDate}
                control={control}
                render={() => (
                    <StyledDatePicker
                        value={selectedDate}
                        name={datepicker.name}
                        type={datepicker.type}
                        width={`${props.colDef.width}px`}
                        height={`${props.colDef.height}px`}
                        onChange={handleDateChange}
                    />
                )}
            />
        </>
    )
})

const StyledDatePicker = styled(CompactDatePicker)`
  height: 36px;
  margin-left: 0;
`