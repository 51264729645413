import React, {useEffect, useState} from "react";
import {GridOptions} from "ag-grid-community";
import TableFileInput from "./component/TableFileInput";
import DefaultTable from "./component/DefaultTable";
import {TankCheckDefault} from "../../../../api/tank/check/getTankCheckDefaultList";
import {TableTitleDiv} from "../../../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../../../components/style/text/TableTitleText";
import {TitleDiv} from "../../../../components/style/div/TitleDiv";
import {TitleText} from "../../../../components/style/text/TitleText";


interface InitValueScreenProps {
    defaultArr: TankCheckDefault[]
    handlePut: (payload: TankCheckDefault[]) => void
}

function DefaultScreen(props: InitValueScreenProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "기종",
                field: "volume",
                width: 300
            },
            {
                headerName: "볼륨 테이블",
                field: "adminTankVolumeTable",
                width: 362,
                cellRendererFramework: (props: any) => TableFileInput({
                    props
                })
            },
            {
                headerName: "도면",
                field: "adminTankDrawing",
                width: 362,
                cellRendererFramework: (props: any) => TableFileInput({
                    props
                })
            },
        ],
        defaultColDef: {
            resizable: true
        }
    })

    useEffect(() => {
        setGridOptions({
            ...gridOptions,
            rowData: props.defaultArr
        })
    }, [props.defaultArr])

    return (
        <>
            <TitleDiv>
                <TitleText>
                    기본값 설정
                </TitleText>
            </TitleDiv>

            <TableTitleDiv>
                <TableTitleText>
                    기본값 리스트
                </TableTitleText>
            </TableTitleDiv>

            <DefaultTable
                tableWidth={1080}
                tableHeight={450}
                gridOptions={gridOptions}
                withNumber={true}
                handlePut={props.handlePut}
            />
        </>
    )
}

export default DefaultScreen