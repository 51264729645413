import React from "react";
import {useAsync} from "../../hooks/useAsync";
import NoticeScreen from "./NoticeScreen";
import {deleteNotice, getNoticeArr, NoticeDto, postNotice, putNotice} from "../../api/notice/Notice";
import {useHistory} from "react-router-dom";
import getLocalStorageItemOrNull from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_ERP_USER_NAME} from "../../resources/LocalStorageConstants";


interface NoticeContainerProps {

}

function NoticeContainer(props: NoticeContainerProps) {
    const history = useHistory()
    const memberId = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME)
    const {data: initResponse} = useAsync(getNoticeArr, null, true)
    const {execute: executePost} = useAsync(postNotice)
    const {execute: executeEdit} = useAsync(putNotice)
    const {execute: executeDelete} = useAsync(deleteNotice)

    function handlePost(payload: NoticeDto) {
        executePost(payload).then(
            () => {
                alert("등록되었습니다.")
                history.go(0)
            }
        )
    }

    function handlePut(payload: NoticeDto) {
        executeEdit(payload).then(
            () => {
                alert("수정되었습니다.")
                history.go(0)
            }
        )
    }

    function handleDelete(uid: string) {
        executeDelete(uid).then(
            () => {
                alert("삭제되었습니다.")
                history.go(0)
            }
        )
    }

    return (
        <>
            <NoticeScreen
                memberId={memberId!!}
                noticeArr={initResponse ? initResponse : []}
                handlePost={handlePost}
                handlePut={handlePut}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default NoticeContainer