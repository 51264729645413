import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {Pageable} from "../../pageable";
import {SmsContainerDto} from "../getSmsList";

export async function getSmsGroupList(
    offset: number = 0,
    limit: number = 10
) {

    const response = await axios.get<BaseResponse<Pageable<SmsContainerDto>>>('/sms/group', {
        params: {
            offset: offset,
            limit: limit,
        }
    })

    return response.data
}