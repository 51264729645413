import React from "react";
import {ICellRendererParams} from "ag-grid-community";
import Trash from "../../../../../assets/images/common/ic-24-trash@3x.png"
import DefaultImage from "../../../../../components/style/image/DefaultImage";
import styled from "styled-components";

interface TankDownDeleteButtonProps {
    cellParams: ICellRendererParams
}

function TankDownDeleteButton(props: TankDownDeleteButtonProps) {

    function onTrashImgClick() {
        props.cellParams.api.applyTransaction({remove: [props.cellParams.node.data]})
    }

    return (
        <>
            <ImageDiv>
                <DefaultImage src={Trash} width={"20px"} height={"24px"} onClick={onTrashImgClick}/>
            </ImageDiv>
        </>
    )
}

const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 258px;
  height: 340px;
  border: solid 1px var(--gray-4);
  justify-content: center;
  align-items: center;
`

export default TankDownDeleteButton