import axios from 'axios';
import {BaseResponse} from "../../BaseResponse";
import {TankAllInOneTestDto, TankPressureTestDto} from "./getTankCheckDefaultList";


export async function getTankCheckList(
    searchType: number = 1,
    searchQuery: string = ""
) {
    const response = await axios.get<BaseResponse<TankCheckList>>('/tank/check', {
        params: {
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}

export interface TankCheckList {
    adminTankPressureTestDtoList: TankPressureTestDto[]
    adminTankAllInOneTestDtoList: TankAllInOneTestDto[]
}

export interface TankPressureTestWithTestType extends TankPressureTestDto {
    testType: string
}

export interface TankAllInOneTestWithTestType extends TankAllInOneTestDto {
    testType: string
}
