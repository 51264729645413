import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getTankCheckDefaultList() {

    const response = await axios.get<BaseResponse<TankCheckDefault[]>>('/tank/check/default')

    return response.data
}

export interface TankVolumeTableDto {
    uid?: string
    parentsId?: string
    url?: string
    fullFileName?: string
    base64Str?: string
}

export interface TankDrawingDto {
    uid?: string
    parentsId?: string
    url?: string
    fullFileName?: string
    base64Str?: string
}

export interface TankPressureTestDto {
    uid?: string
    manufacturerNumber?: string
    countryOfProduction?: string
    url?: string
}

export interface TankAllInOneTestDto {
    uid?: string
    manufacturerNumber?: string
    countryOfProduction?: string
    url?: string
}

export interface TankAllInOneDrawingDto {
    uid?: string
    parentsId?: string
    url?: string
    fullFileName?: string
    base64Str?: string
}

export interface TankCheckDefault {
    uid: string
    volume: string
    adminTankVolumeTable: TankVolumeTableDto
    adminTankDrawing: TankDrawingDto
}