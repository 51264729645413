import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "./style/text/FixedWeightText";


interface ResultTextProps {
    count?: number
    selectedCount?: number
}

function ResultText(props: ResultTextProps) {

    return props.selectedCount !== undefined ? (
        <ResultTextDiv>
            <div>
                <PlainText>검색결과</PlainText>
                <ResultCountText fontSize={"10px"} fontWeight={500}>{props.count}</ResultCountText>
                <PlainText>건</PlainText>
            </div>
            <div>
                <PlainText>선택</PlainText>
                <ResultCountText fontSize={"10px"} fontWeight={500}>{props.selectedCount}</ResultCountText>
                <PlainText>건</PlainText>
            </div>
        </ResultTextDiv>
    ) : (
        <ResultTextDiv>
            <div>
                <PlainText>검색결과</PlainText>
                <ResultCountText fontSize={"10px"} fontWeight={500}>{props.count}</ResultCountText>
                <PlainText>건</PlainText>
            </div>
        </ResultTextDiv>
    )
}

const ResultTextDiv = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 11px;
  margin-bottom: 11px;
`

const PlainText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 500;
  text-align: center;
`

const ResultCountText = styled(PlainText)`
  color: var(--blue);
  margin-left: 3px;
  margin-right: 3px;
`

export default ResultText