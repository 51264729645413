import React from 'react';
import styled from "styled-components";
import Moment from 'moment';
import {BaseFormType} from "../../form/BaseFormType";

type CompactDatepickerProps = BaseFormType & {
    width?: string
    height?: string
    reference?: any
    defaultValue?: string

    onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    placeholder?: string
    disabled?: boolean
    type?: React.InputHTMLAttributes<unknown>['type'];
    value?: string
}

const CompactDatePicker = styled.input.attrs((props: CompactDatepickerProps) => ({
    id: props.name,
    type: props.type ? props.type : "date",
    defaultValue: props.defaultValue ? props.defaultValue : Moment().format('YYYY-MM-DD'),
    InputProps: {disableUnderline: true},
    variant: "outlined",
    name: props.name,
    inputRef: props.reference,

    onChange: props.onChange,
    placeholder: props.placeholder,
    disabled: props.disabled,
    value: props.value
}))<CompactDatepickerProps>`
  box-sizing: border-box;
  width: ${(props: CompactDatepickerProps) => props.width};
  height: ${(props: CompactDatepickerProps) => props.height};
  padding: 0;
  margin-left: 8px;
  font-size: 10px;

  & .MuiOutlinedInput-root {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
  }

  ::-webkit-calendar-picker-indicator {
    margin-left: 0;
  }
`

export default CompactDatePicker