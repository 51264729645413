import React from "react";
import styled from "styled-components";
import {BaseFormType} from "../../../components/form/BaseFormType";

interface SelectItem {
    id: number
    title: string
    value: string
}

type PriceSelectBoxProps = BaseFormType & {
    selectItems: Array<SelectItem> | undefined
    itemDefaultTitle?: string
    reference?: any
}

function PriceSelectBox(props: PriceSelectBoxProps) {

    return (
        <SelectBox
            name={props.name}
            ref={props.reference}
            style={{fontSize: "10px", fontWeight: "normal"}}
        >
            {props.itemDefaultTitle && <option value={"all"} style={{fontSize: "10px"}}>{props.itemDefaultTitle}</option>}

            {
                props.selectItems?.map((selectItem, key) =>
                    <option key={key} value={selectItem.value} style={{fontSize: "10px"}}>{selectItem.title}</option>)
            }
        </SelectBox>
    )
}

export default PriceSelectBox

const SelectBox = styled.select`
  width: 135px;
  height: 32px;
  border: solid 1px var(--gray-4);
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 25px;
`