import React, {useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {GridOptions, IGetRowsParams} from "ag-grid-community";
import {RowDiv} from "../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../components/style/div/ItemBoxDiv";
import ErpTable from "../../components/table/ErpTable";
import Formatter, {getFormattedContents} from "../../components/format/Formatter";
import Moment from "moment";
import {TitleDiv} from "../../components/style/div/TitleDiv";
import {TitleText} from "../../components/style/text/TitleText";
import {TitleButtonDiv} from "../../components/style/div/TitleButtonDiv";
import {CommonButton} from "../tank/CommonButton";
import {SearchHalfDatePicker} from "../../components/style/datepicker/SearchHalfDatePicker";
import {SearchSelect} from "../../components/style/select/SearchSelect";
import {Searchbar} from "../../components/style/input/Searchbar";
import {SearchButton} from "../../components/style/button/SearchButton";
import {TableTitleDiv} from "../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../components/style/text/TableTitleText";
import HorizontalSpace from "../../components/HorizontalSpace";
import {LINK_SMS_REGISTRATION} from "../../resources/LinkConstants";
import {useHistory} from "react-router-dom";
import {Pageable} from "../../api/pageable";
import {Sms, SmsPayload} from "../../api/sms/getSmsList";
import {SmsForm} from "../../api/sms/getSmsForm";


interface FormDataType {
    startDate: string
    endDate: string
    searchType: number
    searchQuery: string
}

interface SmsProps {
    form?: SmsForm
    getNextSmsList: (smsPayload: SmsPayload) => Promise<Pageable<Sms> | undefined>
}

function SmsScreen(props: SmsProps) {

    const PAGE_LIMIT = 10
    const history = useHistory()
    const methods = useForm({
        mode: "onBlur"
    })
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "내용",
                field: "contents",
                width: 200,
            },
            {
                headerName: "수신자",
                field: "receiveUser",
                width: 143,
            },
            {
                headerName: "수신 번호",
                field: "receiveUserMobile",
                valueFormatter: (params) => getFormattedContents(
                    params.value, "phoneNo"
                ),
                width: 158,
            },
            {
                headerName: "발신자",
                field: "sendUser",
                width: 153,
            },
            {
                headerName: "발신번호",
                field: "sendUserMobile",
                valueFormatter: (params) => getFormattedContents(
                    params.value, "phoneNo"
                ),
                width: 157,
            },
            {
                headerName: "발송시간",
                field: "sendDateTime",
                cellRendererFramework: (props: any) => {
                    return (
                        props.data ?
                            <>
                                <Formatter
                                    data={props.data.sendDateTime} format={"YYYY-MM-DD"}
                                    subData={props.data.sendDateTime} subFormat={"HH:mm:ss"}
                                />
                            </> :
                            <>
                            </>
                    )
                },

                width: 116
            },
            {
                headerName: "결과",
                field: "state",
                valueFormatter: (params) => getFormattedContents(
                    params.value, "phoneNo"
                ),
                width: 151,
            },
        ],

        rowHeight: 45,

        // type 인피니트
        rowModelType: "infinite",

        // 가로 스크롤바 없애기
        suppressHorizontalScroll: true,

        // 페이지네이션 적용
        pagination: true,

        // 한 페이지에 10개의 row 보여주기
        paginationPageSize: PAGE_LIMIT,

        // 서버에서 한번에 반환하는 행의 개수
        cacheBlockSize: PAGE_LIMIT,

        datasource:
            {
                async getRows(iGetRowsParams: IGetRowsParams) {

                    const result = await props.getNextSmsList({
                        offset: iGetRowsParams.startRow,
                        limit: PAGE_LIMIT
                    })

                    if (result) {
                        iGetRowsParams.successCallback(result.itemList)
                    }
                },
            },
    })

    function onSubmit(formData: FormDataType) {
        setGridOptions({
            ...gridOptions,
            datasource:
                {
                    async getRows(iGetRowsParams: IGetRowsParams) {
                        const result = await props.getNextSmsList({
                            offset: iGetRowsParams.startRow,
                            limit: PAGE_LIMIT,
                            startDate: formData.startDate,
                            endDate: formData.endDate,
                            searchType: formData.searchType,
                            searchQuery: formData.searchQuery
                        })

                        if (result) {
                            iGetRowsParams.successCallback(result.itemList)
                        }
                    },
                },
        })
    }

    function pushRegistrationPage() {
        history.push(LINK_SMS_REGISTRATION)
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TitleDiv>
                    <TitleText>
                        SMS 전송
                    </TitleText>
                    <TitleButtonDiv>
                        <CommonButton type={"button"} onClick={pushRegistrationPage}>
                            sms 보내기
                        </CommonButton>
                    </TitleButtonDiv>
                </TitleDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        발송 시간
                    </ItemBoxDiv>
                    <SearchHalfDatePicker
                        defaultValue={Moment().format('YYYY-MM-01')}
                        reference={methods.register}
                        name="startDate"
                    />
                    <SearchHalfDatePicker
                        defaultValue={Moment().format('YYYY-MM-DD')}
                        reference={methods.register}
                        name="endDate"
                    />
                </RowDiv>
                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        defaultValue={1}
                        reference={methods.register}
                    />
                    <Searchbar
                        name={"searchQuery"}
                        ref={methods.register}
                    />
                    <SearchButton type="submit">검색</SearchButton>

                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>SMS 목록</TableTitleText>
                </TableTitleDiv>

                <ErpTable
                    tableWidth={1080}
                    tableHeight={525}
                    gridOptions={gridOptions}
                />

            </form>
        </FormProvider>
    )
}

export default SmsScreen