import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {MenuItem} from "../../../components/style/select/Select";

export async function getTankCheckForm() {
    const response = await axios.get<BaseResponse<CheckForm>>("/tank/check/form")
    return response.data
}

export interface CheckForm {
    searchType: Array<MenuItem>
}
