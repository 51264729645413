import React, {Component, ReactNode, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

interface ModalComponentProps {
    children: ReactNode
}

function ModalComponent(props: ModalComponentProps) {

    const modalContainer = () => {
        return (
            <div id="modal-root">
                {props.children}
            </div>
        );
    };

    return (
        ReactDOM.createPortal(
            modalContainer(), document.body, "portal")
    );
}

export default ModalComponent