import React, {useEffect, useRef, useState} from "react"
import styled from "styled-components"
import Button from "../button/Button"
import FixedWeightText from "../text/FixedWeightText"
import DefaultImage from "../image/DefaultImage"
import Close from "../../../assets/images/signup/ic-48-close@3x.png"
import {BaseFormType} from "../../form/BaseFormType";


interface InputFileProps extends BaseFormType {
    width?: string
    height?: string
    margin?: string
    file?: File
    fileList?: FileList
    fileNameMaxLength?: number
    accept?: string
    multiple?: boolean
    className?: string
    dropImgSrc?: string
    handleFile: (file: File) => void
    handleDropBtn: () => void
}

function InputFile(props: InputFileProps) {

    const [fileName, setFileName] = useState("");
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.file) {
            setFileName(sliceFileName(props.file))
        } else if (props.fileList) {
            setFileName(sliceFileName(props.fileList[0]))
        } else {
            setFileName("")
        }
    }, [props.file || props.fileList])

    function handleFileBtn(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (
            hiddenFileInput &&
            hiddenFileInput.current
        ) {
            hiddenFileInput.current.click()
        }
    }

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {

        const fileList = event.currentTarget.files

        if (fileList && fileList.length !== 0) {

            setFileName(sliceFileName(fileList[0]))

            props.handleFile(fileList[0])
        }
    }

    function handleFileDrop() {

        setFileName("")

        props.handleDropBtn()
    }

    function sliceFileName(file: File): string {

        const length: number = props.fileNameMaxLength ? props.fileNameMaxLength : 20

        return file && file.name
            ? file.name.length >= length
                ? file.name.substr(0, length) + "..."
                : file.name
            : "";
    }

    return (
        <React.Fragment>
            <FileBtn onClick={handleFileBtn} width={props.width} height={props.height}>
                파일
            </FileBtn>
            <FileInput
                type="file"
                name={props.name}
                accept={props.accept}
                multiple={props.multiple}
                ref={hiddenFileInput}
                onChange={handleFileChange}
            />

            {fileName &&
            <>
                <div>
                    <FileNameText>
                        {fileName}
                    </FileNameText>
                </div>
                <DefaultImage
                    src={props.dropImgSrc ? props.dropImgSrc : Close}
                    width={"24px"}
                    height={"24px"}
                    onClick={handleFileDrop}
                />
            </>
            }
        </React.Fragment>
    )
}

type FileBtnProps =
    {
        width?: string,
        height?: string,
        margin?: string,
        fontSize?: string
    }


export const FileBtn = styled(Button)
    < FileBtnProps > `
    
    width: ${(props) => props.width || "38px"};
    height: ${(props) => props.height || "28px"};
    margin: ${(props) => props.margin || "0 0 0 8px"};
    
    background-color: var(--blue);
    color: var(--white);
    font-size: 12px
    `

type FileInputProps = {
    accept?: string;
    multiple?: boolean;
    className?: string;
    onChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    ref?: any
}

export const FileInput = styled.input.attrs((props: FileInputProps) => ({
    className: props.className,
    type: "file",
    ref: props.ref,
    accept: props.accept,
    multiple: props.multiple,
    onChange: props.onChange
}))<FileInputProps>`
  display: none;
`

export const FileNameText = styled(FixedWeightText)`
  margin-left: 5px;
  margin-right: 5px;
`

export default InputFile