import React from 'react';


interface BasicScreenComponentProps {

}

function BasicScreen(props: BasicScreenComponentProps) {
    return (
        <div>
            기초데이터 화면
        </div>
    );
}

export default BasicScreen