import React from 'react';
import BasicCheckBox from "./BasicCheckBox";
import FixedWeightText from "../text/FixedWeightText";
import styled from "styled-components";


interface TextWithCheckBoxComponentProps {
    text: string
    checked: boolean
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    trueColor?: string
    trueCheckColor?: string
    falseColor?: string
    falseCheckColor?: string
    name?: string
}

function TextWithCheckBox(props: TextWithCheckBoxComponentProps) {

    const trueColor = props.trueColor ? props.trueColor : "var(--black)"
    const trueCheckColor = props.trueCheckColor ? props.trueCheckColor : "var(--blue)"
    const falseColor = props.falseColor ? props.falseColor : "var(--gray-4)"
    const falseCheckColor = props.falseCheckColor ? props.falseCheckColor : "var(--gray-4)"

    return (
        <TextWithCheckboxDiv>
            <BasicCheckBox
                handleChange={props.handleChange} checked={props.checked} name={props.name}/>
            <CheckBoxText
                fontSize={"14px"}
                fontWeight={500}
                color={props.checked ? trueColor : falseColor}
            >
                {props.text}
            </CheckBoxText>
        </TextWithCheckboxDiv>
    );
}

const TextWithCheckboxDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
`

const CheckBoxText = styled(FixedWeightText)`
  width: fit-content;
  margin-left: 12px;
`
export default TextWithCheckBox