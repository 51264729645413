import React, {useState, useEffect, useRef} from 'react';

// Hook
export const useDebounce = (value: any, delay: any) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

export const useComponentWillMount = (func: () => void) => {
    const willMount = useRef(true);

    if (willMount.current) {
        func();
    }

    willMount.current = false;
};
