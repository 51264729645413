import React from 'react';
import styled from 'styled-components'
import Checkbox from '@material-ui/core/Checkbox';

interface BasicCheckBoxProps {
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    checked: boolean
}

const BasicCheckBox = styled(Checkbox).attrs((props: BasicCheckBoxProps) => ({
    checked: props.checked,
    onChange: props.handleChange
}))<BasicCheckBoxProps>`
  color: var(--gray-4);
  padding: 6px;

  &.Mui-checked {
    color: var(--blue);
  }

,
`

export default BasicCheckBox