import React from "react";
import {
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    ResponsiveContainer,
} from "recharts";
import {GasPriceCompanyType} from "../../../api/price/Price";


interface PriceGraphProps {
    graphData: any[]
    companyType?: GasPriceCompanyType[]
}

function PriceGraph(props: PriceGraphProps) {

    return (
        <div style={{marginTop: "30px"}}>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    margin={{
                        top: 30,
                        right: 5,
                        left: 5,
                        bottom: 30,
                    }}
                    data={props.graphData}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="yearMonth"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    {
                        props.companyType?.map((companyType, index) =>
                            <Line key={index} type={"monotone"} dataKey={companyType.value}
                                  stroke={companyType.color}/>)
                    }
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default PriceGraph