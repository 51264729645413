import React, {useState} from "react";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {TitleDiv} from "../../../components/style/div/TitleDiv";
import {TitleText} from "../../../components/style/text/TitleText";
import {RowDiv} from "../../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import HorizontalSpace from "../../../components/HorizontalSpace";
import {Searchbar} from "../../../components/style/input/Searchbar";
import CustomerAccountList from "./component/CustomerAccountList";
import CustomerReceiverList from "./component/CustomerReceiverList";
import CustomerSmsContent from "./component/CustomerSmsContent";
import CustomerRecentlySendList from "./component/CustomerRecentlySendList";
import CustomerSendBox from "./component/CustomerSendBox";
import {Member, MemberForm} from "../../../api/member/Member";
import {SmsSendForm} from "../../../api/sms/registration/getSmsRegistrationForm";
import {SmsContainerDto, SmsFile} from "../../../api/sms/getSmsList";
import {Pageable} from "../../../api/pageable";
import {SearchSelect} from "../../../components/style/select/SearchSelect";
import {SearchButton} from "../../../components/style/button/SearchButton";


interface SmsSendScreenProps {
    form?: SmsSendForm
    memberForm?: MemberForm
    memberList?: Member[]
    getNextGroupList: (offset: number, limit: number) => Promise<Pageable<SmsContainerDto> | undefined>
    getMemberList: (
        dateType?: number,
        startDate?: string,
        endDate?: string,
        searchType?: number,
        searchQuery?: string) => void
    post: (smsGroup: SmsContainerDto) => void
}

function SmsRegistrationScreen(props: SmsSendScreenProps) {

    const methods = useForm({
        mode: "onBlur",
        criteriaMode: 'all',
    })
    const [selectedMemberList, setSelectedMemberList] = useState<Member[]>([])
    const [smsFile, setSmsFile] = useState<SmsFile>()
    const [textSize, setTextSize] = useState<number>(0)

    function onSubmit() {
        props.getMemberList(
            methods.watch("dateType"),
            methods.watch("startDate"),
            methods.watch("endDate"),
            methods.watch("searchType"),
            methods.watch("searchQuery")
        )
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TitleDiv>
                    <TitleText>
                        고객관리
                    </TitleText>
                </TitleDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        defaultValue={1}
                        reference={methods.register}
                    />
                    <Searchbar
                        name={"searchQuery"}
                        ref={methods.register}
                    />
                    <SearchButton type="submit">검색</SearchButton>
                </RowDiv>

                <HorizontalSpace/>

                <PureRow>
                    <CustomerAccountList
                        memberList={props.memberList}
                        setSelectedMemberList={setSelectedMemberList}
                        form={props.memberForm}
                    />
                    <CustomerReceiverList
                        selectedMemberList={selectedMemberList}
                        form={props.memberForm}
                    />
                    <CustomerSmsContent
                        methods={methods}
                        setSmsFile={setSmsFile}
                        textSize={textSize}
                        setTextSize={setTextSize}
                    />
                </PureRow>

                <HorizontalSpace/>

                <PureRow>
                    <CustomerRecentlySendList
                        getNextGroupList={props.getNextGroupList}
                    />
                    <CustomerSendBox
                        methods={methods}
                        form={props.form}
                        memberForm={props.memberForm}
                        post={props.post}
                        selectedMemberList={selectedMemberList}
                        smsFile={smsFile}
                        textSize={textSize}
                    />
                </PureRow>
            </form>
        </FormProvider>
    )
}

export const PureRow = styled.div`
  display: flex;
  flex-direction: row;
`

export default SmsRegistrationScreen