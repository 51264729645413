import React from "react";
import {ICellRendererParams} from "ag-grid-community";
import {RetestDetailForm} from "../../../../../api/tank/retest/getRetestDetailForm";
import styled from "styled-components";
import {RetestTank} from "../../../../../api/tank/retest/getRetestList";


function TankRetestDetailStateColumn(cellParams: ICellRendererParams, setRowData: React.Dispatch<React.SetStateAction<RetestTank[]>>, form?: RetestDetailForm) {

    const longField = cellParams.colDef.field
    const field = longField?.substring(
        longField?.indexOf(".") + 1,
        longField?.length
    )

    return (
        <>
            <SelectBox
                name={field}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    const state = parseInt(event.target.value)
                    const rowIndex = cellParams.rowIndex

                    cellParams.setValue(state)
                    setRowData(prevState => {
                        return [
                            ...prevState.slice(0, rowIndex),
                            {
                                ...prevState[rowIndex],
                                state: state
                            },
                            ...prevState.slice(rowIndex + 1)
                        ]
                    })
                }}
            >

                <option
                    value={0}
                    disabled
                >
                    선택하세요
                </option>
                {
                    form && field &&
                    form[field + "Type"].map((item, key) =>
                        <option
                            value={item.id}
                            key={key}
                            selected={cellParams.value == item.id}
                        >
                            {item.title}
                        </option>)
                }
            </SelectBox>
        </>
    )
}

const SelectBox = styled.select`
  width: 100px;
  height: 34px;
  border-style: none;

  font-size: 3px;
  font-weight: 300;

  :focus {
    outline: none;
  }
`

export default TankRetestDetailStateColumn