import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {applyMiddleware, createStore} from "redux";
import rootReducer, {rootSaga} from "./redux";
import {Provider} from "react-redux";
import createSagaMiddleware from "redux-saga";
import axios from "axios";
import {getLocalStorageJsonItemOrNull} from "./lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_JWT} from "./resources/LocalStorageConstants";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

const erpToken = getLocalStorageJsonItemOrNull(LOCAL_STORAGE_KEY_JWT)

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] = erpToken && `Bearer ${erpToken.accessToken}`;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
