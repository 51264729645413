import React from 'react'
import styled from 'styled-components'

type TextAreaProps = {
    placeholder?: string
    focusBorderColor?: string
}

const TextArea = styled.textarea<TextAreaProps>`
  display: flex;
  box-sizing: border-box;

  margin-left: 8px;
  padding-top: 8px;
  padding-left: 12px;
  border-radius: 4px;

  border: solid 1px var(--gray-4);
  color: var(--black);
  caret-color: var(--blue);
  resize: none;

  &:focus {
    outline: none !important;
    border-width: 1px;
    border-color: ${(props) => props.focusBorderColor || ("var(--blue)")};
  }

  ::placeholder,
  ::-webkit-input-placeholder {
    color: var(--gray-4);
  }

  :-ms-input-placeholder {
    color: var(--gray-4);
  }
`

export default TextArea
