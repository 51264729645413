import React, {useEffect, useState} from "react";
import {Document, Page} from "react-pdf";
import Pre from "../../../../assets/images/common/ic-24-arrow-left@3x.png";
import Next from "../../../../assets/images/common/ic-24-arrow-right@3x.png";
import DefaultImage from "../../../../components/style/image/DefaultImage";
import Camera from "../../../../assets/images/common/ic-48-camera@3x.png";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import {PDFDocumentProxy} from "pdfjs-dist";


interface TankCheckCreationPdfScreenProps {
    selectedFile: File | undefined
    url?: string
}

function TankCheckRegistrationPdfScreen(props: TankCheckCreationPdfScreenProps) {

    const [pageIndex, setPageIndex] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)

    // useEffect(() => {
    //     setPageSize(props.selectedFile?.size ? props.selectedFile?.size : 0)
    // }, [props.selectedFile])

    function increasePage() {
        if (pageIndex < pageSize - 1) {
            setPageIndex(pageIndex + 1)
        }
    }

    function decreasePage() {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1)
        }
    }

    function onDocumentLoadSuccess(pdf: PDFDocumentProxy) {
        setPageSize(pdf.numPages)
    }

    return (
        <>
            {
                props.selectedFile && props.selectedFile.size > 0 &&
                <div>
                    <PdfDiv>
                        <Document file={props.selectedFile} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageIndex + 1} width={500}/>
                        </Document>
                    </PdfDiv>
                    <SelectPdfButtonDiv>
                        <PreButton src={Pre} onClick={decreasePage}/>
                        <IndexText>{pageIndex + 1} / {pageSize}</IndexText>
                        <NextButton src={Next} onClick={increasePage}/>
                    </SelectPdfButtonDiv>
                </div>
            }
            {
                !props.selectedFile && props.url &&
                <div>
                    <PdfDiv>
                        <Document file={props.url} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageIndex + 1} width={500}/>
                        </Document>
                    </PdfDiv>
                    <SelectPdfButtonDiv>
                        <PreButton src={Pre} onClick={decreasePage}/>
                        <IndexText>{pageIndex + 1} / {pageSize}</IndexText>
                        <NextButton src={Next} onClick={increasePage}/>
                    </SelectPdfButtonDiv>
                </div>
            }
            {(!props.selectedFile || props.selectedFile.size == 0) && !props.url &&
            <DefaultImageDiv>
                <ImageDiv>
                    <DefaultImage src={Camera} width={"48px"} height={"48px"}/>
                </ImageDiv>
            </DefaultImageDiv>
            }
        </>
    );
}

const DefaultImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 560px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--gray-6);
  justify-content: center;
  align-items: center;
  overflow: scroll;
`

const PdfDiv = styled(DefaultImageDiv)`
  height: 430px;
  padding-top: 130px;
  width: 350px;
  padding-left: 50px;
`

const SelectPdfButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: var(--gray-6);
`
const IndexText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 500;
`
const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 258px;
  height: 340px;
  border: solid 1px var(--gray-4);
  justify-content: center;
  align-items: center;
`

const NextButton = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 16px;
`
const PreButton = styled(NextButton)`
  margin-right: 16px;
  margin-left: 0;
`

export default TankCheckRegistrationPdfScreen