import React, {forwardRef, useImperativeHandle, useRef, useState} from "react"

export default forwardRef((props: any, ref) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [value, setValue] = useState('')

    function inputHandler(event: React.ChangeEvent<HTMLInputElement>) {
        setValue(event.target.value)
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value
            },
            afterGuiAttached: () => {
                setValue(props.value);
                if (
                    inputRef &&
                    inputRef.current
                ) {
                    inputRef.current.focus();
                    inputRef.current.select();
                }
            }
        }
    })

    return (
        <input
            style={{textAlign: "center", paddingLeft: "2px", border:"none", borderRight:"0px", borderTop:"0px", borderLeft:"0px", borderBottom:"0px"}}
            type="number"
            className="ag-input-field-input ag-text-field-input"
            ref={inputRef}
            onChange={inputHandler}
            value={value}
        />
    )
})
