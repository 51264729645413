import React, {CSSProperties, useState} from 'react';
import NavButton from "../../style/button/NavButton";
import styled from "styled-components";
import {Link} from "react-router-dom";
import DefaultWrapperDiv from "../../style/div/DefaultWrapperDiv";

export interface NavInfoProps {
    to: string,
    style: CSSProperties,
    text: string
}

interface NavProps {
    navProps: NavInfoProps[],
    activePoint: number,
}

function NavBar({
                    navProps,
                    activePoint
                }: NavProps) {

    const [active, setActive] = useState(activePoint);

    function setMenu(index: number) {
        setActive(index)
    }

    function handleClick(index: number) {
        setMenu(index)
    }

    function makeLinks(navProps: NavInfoProps[]): JSX.Element[] {
        return (
            navProps.map((prop, index) =>
                <Link to={prop.to} style={prop.style} key={index}>
                    <NavButton index={index} isSelected={index === active} click={handleClick} text={prop.text}/>
                </Link>
            )
        )
    }

    return (
        <DivWithStyle>
            <DefaultWrapperDiv direction={"row"} justifyContent={"center"}>
                {makeLinks(navProps)}
            </DefaultWrapperDiv>
        </DivWithStyle>
    );
}

const DivWithStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 64px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-image: linear-gradient(to top, #ffffff, #f5f5f5);
`

export default NavBar;

