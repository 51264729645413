import React, {useState} from 'react';
import styled from "styled-components";
import BaseModal from "./BaseModal";
import FixedWeightText from "../style/text/FixedWeightText";
import Button from "../style/button/Button";


interface SimpleModalProps {
    headerContentText: string
    subText: string
    submitText: string
    showModal: boolean
    onCloseModal: () => void
    buttonColor?: string
    onConfirm?: () => void
}

function SimpleModal(props: SimpleModalProps) {

    const [buttonColor, setButtonColor] = useState(props.buttonColor ? props.buttonColor : "var(--blue)")

    function onConfirm() {
        if (props.onConfirm) {
            props.onConfirm()
        }

        closeModal()
    }

    function closeModal() {
        props.onCloseModal()
    }

    return (
        <BaseModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <ContentDiv>
                    <HeaderContentText>{props.headerContentText}</HeaderContentText>
                    <SubText>{props.subText}</SubText>
                    <ButtonDiv>
                        <CancelButton onClick={closeModal}>
                            <FixedWeightText color={"var(--red)"} fontSize={"14px"}
                                             fontWeight={500}>취소</FixedWeightText>
                        </CancelButton>
                        <SubmitButton color={buttonColor} onClick={onConfirm}>
                            <FixedWeightText color={"var(--white)"} fontSize={"14px"}
                                             fontWeight={500}>{props.submitText}</FixedWeightText>
                        </SubmitButton>
                    </ButtonDiv>
                </ContentDiv>
            </ModalDiv>
        </BaseModal>
    );
}

export const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 200px;
  border-radius: 4px;
  background-color: var(--white);
`

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 22px 32px 32px 32px;
`

export const HeaderContentText = styled(FixedWeightText)`
  width: 120px;
  height: 28px;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
  text-align: center;
`

export const SubText = styled(HeaderContentText)`
  width: 249px;
  font-size: 14px;
  margin-bottom: 32px;
`

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const CancelButton = styled(Button)`
  width: 120px;
  height: 48px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

export const SubmitButton = styled(CancelButton)`
  margin-left: 8px;
  background-color: ${(props) => props.color};
`

export default SimpleModal