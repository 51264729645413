import React, {useEffect, useState} from 'react';
import {GridOptions} from "ag-grid-community";
import MemberListTableLinkButton from "./MemberListTableLinkButton";
import MemberListTableLinkText from "./MemberListTableLinkText";
import CommonMemberScreen from "../CommonMemberScreen";
import {getFormattedContents} from "../../../components/format/Formatter";
import {MemberForm, Member} from "../../../api/member/Member";
import {getPhoneNumber} from "../../../lib/getManagerPhoneNumber";


interface FormDataType {
    dateType: number
    startDate: string
    endDate: string
    searchType: number
    searchQuery: string
}

interface MemberListProps {
    memberArr: Member[]
    handleGet: (
        dateType: number,
        startDate: string,
        endDate: string,
        searchType: number,
        searchQuery: string) => void
    form?: MemberForm
}

function MemberListScreen(props: MemberListProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({})

    useEffect(() => {
        if (props.memberArr && props.form) {
            setGridOptions({
                columnDefs: [
                    {
                        headerName: "접속",
                        field: "userId",
                        cellRendererFramework: MemberListTableLinkButton,
                        width: 60
                    },
                    {
                        headerName: "회사명",
                        field: "companyName",
                        cellRendererFramework: MemberListTableLinkText,
                        width: 100
                    },
                    {
                        headerName: "사업자 번호",
                        field: "businessNumber",
                        valueFormatter: (params) => getFormattedContents(
                            params.value, "bizNo"
                        ),
                        width: 90
                    },
                    {
                        headerName: "대표자명",
                        field: "ownerName",
                        width: 84
                    },
                    {
                        headerName: "주소",
                        field: "address",
                        width: 306
                    },
                    {
                        headerName: "담당자명",
                        field: "mobile1.name",
                        width: 84
                    },
                    {
                        headerName: "담당자 연락처",
                        field: "mobile1",
                        valueFormatter: (params) => {
                            return getPhoneNumber(params.value, props.form?.memberPhoneCarrierType)
                        },
                        width: 100
                    },
                    {
                        headerName: "승인 일자",
                        field: "acceptDate",
                        valueFormatter: (params) => getFormattedContents(
                            params.value, "YYYY-MM-DD"
                        ),
                        width: 100
                    },
                    {
                        headerName: "가입일",
                        field: "createdAt",
                        valueFormatter: (params) => getFormattedContents(
                            params.value, "YYYY-MM-DD"
                        ),
                        width: 100
                    }
                ],

                rowData: props.memberArr
            })
        }
    }, [props.memberArr, props.form])

    function onSubmit(data: FormDataType) {
        props.handleGet(
            2,
            data.startDate,
            data.endDate,
            data.searchType,
            data.searchQuery
        )
    }

    return (
        <>
            <CommonMemberScreen
                form={props.form}
                onSubmit={onSubmit}
                gridOptions={gridOptions}
            />
        </>
    )
}

export default MemberListScreen