import React, {useEffect, useState} from 'react';
import {
    ColumnApi,
    GridApi, GridOptions,
    GridReadyEvent
} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";
import {ColDef, ColGroupDef} from "ag-grid-community/dist/lib/entities/colDef";
import TableNumberColumn from "./TableNumberColumn";


interface ErpTableComponentProps {
    tableWidth: number
    tableHeight: number
    gridOptions: GridOptions
    withNumber?: boolean
}

function ErpTableComponent(props: ErpTableComponentProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    useEffect(() => {
        if (gridApi) {
            if (props.gridOptions.rowData) {
                gridApi.setRowData(props.gridOptions.rowData)
            } else if (props.gridOptions.datasource) {
                gridApi.setDatasource(props.gridOptions.datasource)
            }
        }
    }, [gridApi, props.gridOptions.rowData, props.gridOptions.datasource]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)

        if (props.gridOptions.datasource) {
            event.api.setDatasource(props.gridOptions.datasource)
        }
    }

    function getWithNumberColDef(colDef: (ColDef | ColGroupDef)[]): ColDef[] | ColGroupDef[] {

        if (colDef[0].headerName !== "No") {

            colDef.unshift(
                {
                    headerName: "No",
                    width: 54,
                    cellRendererFramework: TableNumberColumn
                })
        }

        return colDef
    }

    return (
        <div className="ag-theme-alpine" style={{height: props.tableHeight, width: props.tableWidth}}>
            <AgGridReact
                rowSelection={props.gridOptions.rowSelection ? props.gridOptions.rowSelection : 'multiple'}
                defaultColDef={{...props.gridOptions.defaultColDef, lockPosition: true}}

                columnDefs={props.withNumber && props.gridOptions.columnDefs ?
                    getWithNumberColDef(props.gridOptions.columnDefs) :
                    props.gridOptions.columnDefs}

                scrollbarWidth={4}
                onGridReady={onGridReady}
                gridOptions={props.gridOptions}
                onRowClicked={props.gridOptions.onRowClicked}
                onRowSelected={props.gridOptions.onRowSelected}
            >
            </AgGridReact>
        </div>
    );
}

export default ErpTableComponent