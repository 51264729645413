import React, {ReactNode} from 'react';
import styled from "styled-components";

interface TextProps {
    size: string;
    width?: string;
    height?: string;
    textStyle?: string;
    marginTop?: string;
    marginRight?: string;
    marginLeft?: string;
    marginBottom?: string;
    color?: string;
    textAlign?: string
    children: ReactNode;
}

function BaseText(props: TextProps) {
    return (
        <StyledText
            width={props.width}
            height={props.height}
            size={props.size}
            color={props.color}
            textStyle={props.textStyle}
            textAlign={props.textAlign}
            marginTop={props.marginTop}
            marginBottom={props.marginBottom}
            marginLeft={props.marginLeft}
            marginRight={props.marginRight}
        >
            {props.children}
        </StyledText>
    );
}

export default BaseText;

const StyledText = styled.text`
  display: flex;
  width: ${(props: TextProps) => props.width || "fit-content"};
  height: ${(props: TextProps) => props.height || "fit-content"};
  font-size: ${(props: TextProps) => props.size || "24px"};
  font-weight: ${(props: TextProps) => props.textStyle || "500"};
  text-align: center;
  color: ${(props: TextProps) => props.color || "var(--black)"};

  margin-top: ${(props: TextProps) => props.marginTop || "0px"};
  margin-bottom: ${(props: TextProps) => props.marginBottom || "0px"};
  margin-left: ${(props: TextProps) => props.marginLeft || "0px"};
  margin-right: ${(props: TextProps) => props.marginRight || "0px"};
`
