import React, {useEffect, useState} from "react";
import {ColumnApi, GridApi, GridReadyEvent, ICellRendererParams} from "ag-grid-community";
import {TankRelease, TankReleaseCertificate} from "../../../api/tank/release/getTankReleaseList";
import {TankReleaseForm} from "../../../api/tank/release/getTankReleaseForm";
import {AgGridReact} from "ag-grid-react";
import TankReleaseIndex from "./TankReleaseIndex";
import {useForm, FormProvider} from "react-hook-form";
import TankReleaseCertificateSerialNumber from "./component/TankReleaseCertificateSerialNumber";
import TankReleaseModal from "./component/TankReleaseModal";
import ErpTableFilter from "../../../components/table/filter/ErpTableFilter";

interface TankReleaseTableProps {
    form?: TankReleaseForm
    data?: TankRelease[]

    // 탱크 출고
    getList: (startDate?: string, endDate?: string) => void
    postTankRelease: (tankRelease: TankRelease) => void
    putTankRelease: (tankReleaseId: string, tankRelease: TankRelease) => void
    deleteTankRelease: (tankReleaseId: string) => void

    // 탱크 출고 합격증
    postTankReleaseCertificate: (tankReleaseId: string, tankReleaseCertificate: TankReleaseCertificate) => void,
    putTankReleaseCertificate: (tankReleaseId: string, tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) => void,
    deleteTankReleaseCertificate: (tankReleaseId: string, tankReleaseCertificateId: string) => void
}

function TankReleaseTable(props: TankReleaseTableProps) {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [selectedTankReleaseIndex, setSelectedTankReleaseIndex] = useState<number>()
    const [gridApi, setGridApi] = useState<GridApi>()
    const [, setGridColumnApi] = useState<ColumnApi>()
    const methods = useForm({
        mode: "onBlur"
    })

    useEffect(() => {
        if (selectedTankReleaseIndex) {
            setShowModal(true)
        }
    }, [selectedTankReleaseIndex])

    useEffect(() => {
        if (gridApi && props.data) {
            gridApi.setRowData(props.data)
        }
    }, [gridApi, props.data]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    function closeModal() {
        setShowModal(false)
    }

    function createData(count: number): TankRelease[] {
        const result: TankRelease[] = []

        for (let i = 0; i < count; i++) {
            result.push({
                    uid: undefined,
                    memberId: "",
                    state: "",
                    receptionDate: "",
                    releaseDate: "",
                    arrivalDateTime: "",
                    salesManager: "",
                    volume: "",
                    tankReleaseCertificateCount: 0,
                    // 조정기
                    controller: "",
                    hasTongsori: "",
                    hasPackage: "",
                    destination: "",
                    phoneNumber: "",
                    adminTankReleaseCertificateDtoList: [],
                    memo1: "",
                    memo2: ""
                }
            )
        }

        return result;
    }

    return (
        <FormProvider {...methods}>
            <form> {props.form ?
                <>
                    <div className="ag-theme-alpine" style={{height: 625, width: 1080}}>
                        <AgGridReact
                            scrollbarWidth={4}
                            onGridReady={onGridReady}
                            gridOptions={{
                                columnDefs: [
                                    {
                                        headerName: "상태",
                                        field: "state",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleaseStateType,
                                        },
                                        width: 40
                                    },
                                    {
                                        headerName: "접수일",
                                        field: "receptionDate",
                                        cellEditor: "datePicker",
                                        cellEditorParams: {
                                            datepicker: {
                                                name: "receptionDate",
                                                type: "date",
                                                format: "YYYY-MM-DD"
                                            }
                                        },
                                        width: 85
                                    },
                                    {
                                        headerName: "출고일",
                                        field: "releaseDate",
                                        cellEditor: "datePicker",
                                        cellEditorParams: {
                                            datepicker: {
                                                name: "releaseDate",
                                                type: "date",
                                                format: "YYYY-MM-DD"
                                            }
                                        },
                                        width: 85
                                    },
                                    {
                                        headerName: "도착일",
                                        field: "arrivalDateTime",
                                        cellEditor: "datePicker",
                                        cellEditorParams: {
                                            datepicker: {
                                                name: "arrivalDateTime",
                                                type: "datetime-local",
                                                format: "YYYY-MM-DDTHH:mm",
                                                width: "200px",
                                            }
                                        },
                                        width: 140
                                    },
                                    {
                                        headerName: "회원사명",
                                        field: "companyName",
                                        cellEditor: "memberSelectBox",
                                        cellEditorParams: {
                                            memberList: props.form.memberList,
                                            memberPhoneCarrierType: props.form.memberPhoneCarrierType
                                        },
                                        cellStyle: function (params: any) {
                                            switch (params.data.state) {
                                                case "접수":
                                                    return null
                                                case "출고":
                                                    return {backgroundColor: 'var(--sky)'};
                                                case "취소":
                                                    return {backgroundColor: "var(--red)"};
                                                default:
                                                    return null
                                            }
                                        },
                                        width: 100
                                    },
                                    {
                                        headerName: "영업 담당",
                                        field: "salesManager",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleaseSalesManagerType
                                        },
                                        filterFramework: ErpTableFilter,
                                        filterParams: {
                                            checkBoxInfos: props.form.tankReleaseSalesManagerType.map(salesManagerType => {
                                                return {
                                                    name: salesManagerType.title,
                                                    checked: true
                                                }
                                            })
                                        },
                                        width: 60
                                    },
                                    {
                                        headerName: "기종",
                                        field: "volume",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleaseVolumeType
                                        },
                                        filterFramework: ErpTableFilter,
                                        filterParams: {
                                            checkBoxInfos: props.form.tankReleaseVolumeType.map(volumeType => {
                                                return {
                                                    name: volumeType.title,
                                                    checked: true
                                                }
                                            })
                                        },
                                        width: 70
                                    },
                                    {
                                        headerName: "수량",
                                        field: "tankReleaseCertificateCount",
                                        cellEditor: "input",
                                        width: 30
                                    },
                                    {
                                        headerName: "조정기",
                                        field: "controller",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleaseControllerType
                                        },
                                        width: 50
                                    },
                                    {
                                        headerName: "Tong",
                                        field: "hasTongsori",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleaseTongsoriType
                                        },
                                        width: 50
                                    },
                                    {
                                        headerName: "Pack",
                                        field: "hasPackage",
                                        cellEditor: "selectBox",
                                        cellEditorParams: {
                                            menuItems: props.form.tankReleasePackageType
                                        },
                                        width: 50
                                    },
                                    {
                                        headerName: "도착지",
                                        field: "destination",
                                        cellEditor: "input",
                                        width: 200
                                    },
                                    {
                                        headerName: "연락처",
                                        field: "phoneNumber",
                                        cellEditor: "input",
                                        width: 80
                                    },
                                    {
                                        headerName: "Tank No ( 적색 미발송 )",
                                        field: "adminTankReleaseCertificateDtoList",
                                        cellRendererFramework: (data: ICellRendererParams) => TankReleaseCertificateSerialNumber({data: data}),
                                        onCellClicked: (event => {
                                            if (event.rowIndex == 0) {
                                                return
                                            }

                                            // 1. rowIndex 를 통해 선택된 멤버 인덱스를 얻는다.
                                            // 2. rowIndex 를 setIndex 를 통해 바깥으로 전달한다.
                                            // 3. useEffect(() => {setShowModal(true)}, [index]) 를 통해 index 변경이 감지되면 모달창을 띄움
                                            // 3-1. 만약 rowIndex 가 현재 index 와 같으면 index 가 변경되지 않으므로 직접 모달창을 띄움
                                            let prevIndex

                                            setSelectedTankReleaseIndex(prevState => {
                                                prevIndex = prevState
                                                return event.rowIndex
                                            })

                                            if (prevIndex == event.rowIndex) {
                                                setShowModal(true)
                                                return
                                            }
                                        }),
                                        sortable: false,
                                        editable: false,
                                        width: 150
                                    },
                                    {
                                        headerName: "비고1",
                                        field: "memo1",
                                        cellEditor: "input",
                                        width: 80
                                    },
                                    {
                                        headerName: "비고2",
                                        field: "memo2",
                                        cellEditor: "input",
                                        width: 80
                                    },
                                    {
                                        headerName: "기능",
                                        colId: "actions",
                                        cellRenderer: "actionsRenderer",
                                        editable: false,
                                        filter: false,
                                        pinned: 'right',
                                        width: 90
                                    },
                                ],

                                defaultColDef: {
                                    resizable: true,
                                    sortable: true,
                                    editable: true,
                                    suppressKeyboardEvent: params => params.editing
                                },

                                frameworkComponents: {
                                    memberSelectBox: TankReleaseIndex.TankReleaseMemberSelectBoxEditor,
                                    selectBox: TankReleaseIndex.TankReleaseSelectBoxEditor,
                                    datePicker: TankReleaseIndex.TankReleaseDateEditor,
                                    input: TankReleaseIndex.TankReleaseInputEditor,
                                    actionsRenderer: (data: any) => TankReleaseIndex.TankReleaseAction(data, props.postTankRelease, props.putTankRelease, props.deleteTankRelease),
                                },

                                // 한 칸 단위로 수정하는 것이 아니라
                                // 한 줄 단위로 수정 값 적용
                                editType: "fullRow",

                                suppressClickEdit: true,

                                pinnedTopRowData: createData(1),

                                rowData: props.data
                            }}
                        />
                    </div>

                    <TankReleaseModal
                        showModal={showModal}
                        onCloseModal={closeModal}
                        data={props.data && selectedTankReleaseIndex ? props.data[selectedTankReleaseIndex] : undefined}
                        post={props.postTankReleaseCertificate}
                        put={props.putTankReleaseCertificate}
                        delete={props.deleteTankReleaseCertificate}
                    />
                </> : <></>
            }
            </form>
        </FormProvider>
    )
}

export default TankReleaseTable