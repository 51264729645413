import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getSmsRegistrationForm() {

    const response = await axios.get<BaseResponse<SmsSendForm>>('/sms/registration/form')

    return response.data
}

export interface SmsSendForm {
    searchType: Array<SmsRegistrationTypeItem>
    scheduleType: Array<SmsRegistrationTypeItem>
    timeType: Array<SmsRegistrationTypeItemWithValue>
    minuteType: Array<SmsRegistrationTypeItemWithValue>
}

export interface SmsRegistrationTypeItem {
    id: number
    title: string
}

export interface SmsRegistrationTypeItemWithValue extends SmsRegistrationTypeItem{
    value: string
}