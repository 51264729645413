import React, {forwardRef, useImperativeHandle, useState} from "react";
import {SmsContainerDto} from "../../../../api/sms/getSmsList";
import styled from "styled-components";


export default forwardRef((props: any, ref) => {
    const [data] = useState<SmsContainerDto>(
        props.api.getDisplayedRowAtIndex(props.rowIndex).data
    )

    useImperativeHandle(ref, () => {
        return {
            getReactContainerClasses() {
                return ['sms-custom-tooltip']
            },

            getReactContainerStyle() {
                return {
                    position: "absolute",
                    width: "150px",
                    borderRadius: "5px",
                    pointerEvents: "none",
                    transition: "opacity 1s",
                    backgroundColor: "var(--black)",
                    color: "var(--white)",
                };
            }
        }
    })

    return (
        <div className="sms-custom-tooltip">
            {
                data.smsDtoList.map((smsDto, index) => {
                    return (
                        <React.Fragment key={index}>
                            <TooltipP>
                                <span>{smsDto.receiveUser}</span>
                            </TooltipP>
                            <TooltipP>
                                <span>상태: {smsDto.state}</span>
                            </TooltipP>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
})

const TooltipP = styled.p`
  margin: 5px;
  white-space: nowrap;
`
