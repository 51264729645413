import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getRetestForm() {
    const response = await axios.get<BaseResponse<RetestForm>>(`/tank/retest/form`)

    return response.data
}

export interface RetestForm {
    searchType: Array<RetestSearchTypeItem>
}

interface RetestSearchTypeItem {
    id: number
    title: string
    years: Array<RetestDate>
    months: Array<RetestDate>
}

export interface RetestDate {
    id: number
    title: string
    value: string
    isCurrent: boolean
}