import React, {ReactNode} from 'react';
import Header from "./component/Header";
import NavBar, {NavInfoProps} from "./component/NavBar";
import Footer from "./component/Footer";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import DefaultOuterDiv from "../style/div/DefaultOuterDiv";
import {
    LINK_MEMBER,
    LINK_NOTICE, LINK_PRICE, LINK_SMS, LINK_TANK
} from "../../resources/LinkConstants";


interface ComponentProps {
    children: ReactNode;
    width?: number;
    height?: number;
}

function Index(props: ComponentProps) {
    const navProps: NavInfoProps[] = [
        {
            to: LINK_MEMBER,
            style: {textDecoration: 'none'},
            text: "회원 관리"
        },
        {
            to: LINK_TANK,
            style: {textDecoration: 'none'},
            text: "탱크 관리"
        },
        {
            to: LINK_SMS,
            style: {textDecoration: 'none'},
            text: "SMS 전송"
        },
        {
            to: LINK_PRICE,
            style: {textDecoration: 'none'},
            text: "가격 설정"
        },
        {
            to: LINK_NOTICE,
            style: {textDecoration: 'none'},
            text: "공지 팝업"
        },
    ]

    return (
        <div style={{width: props.width, height: props.height}}>
            <Header/>
            <NavBar navProps={navProps} activePoint={-1}/>
            <DefaultOuterDiv>
                <DefaultWrapperDiv>
                    {props.children}
                </DefaultWrapperDiv>
            </DefaultOuterDiv>
            <Footer/>
        </div>
    );
}

export default Index;