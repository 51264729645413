import React, {forwardRef, useEffect, useImperativeHandle, useState,} from 'react'
import {IDoesFilterPassParams} from "ag-grid-community"
import TextWithCheckBox from "../../style/button/TextWithCheckBox"
import styled from "styled-components";

interface CheckboxInfo {
    name: string
    checked: boolean
}

export default forwardRef((props: any, ref) => {
    const [isInit, setIsInit] = useState(true)
    const [isAll, setIsAll] = useState(true)
    // checkBoxInfos 형식의 filterParams를 넣어줘야 함 그렇지 않으면 에러
    const [checkboxInfoArr, setCheckboxInfoArr] = useState<CheckboxInfo[]>(props.checkBoxInfos)

    useEffect(() => {
        if (isInit) {
            setIsInit(false)
            return
        }

        props.filterChangedCallback()
    }, [checkboxInfoArr])

    // expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => {
        return {
            doesFilterPass(params: IDoesFilterPassParams) {
                // 필터링 여부를 확인할 현재 cell의 값을 가져옴
                const target = params.data[props.colDef.field]

                // 켜져있는 필터들의 값을 가져옴
                const names = checkboxInfoArr.filter(info => info.checked).map(info => info.name)

                // names의 값들 중 target의 값과 겹치는 게 있다면 해당 값을 보여주고
                // 그렇지 않다면 보여주지 않음.
                return names.some(name => target.includes(name))
            },

            isFilterActive() {
                // 전체 선택은 필터링을 할 필요가 없음
                return checkboxInfoArr.some(info => !info.checked)
            },

            getModel() {
            },

            setModel() {
            },
        }
    })

    function handleAll(event: React.ChangeEvent<HTMLInputElement>) {
        const checked = event.target.checked

        setCheckboxInfoArr(prevState => prevState.map(prev => {
            return {
                name: prev.name,
                checked: checked
            }
        }))

        setIsAll(checked)
    }

    function handleItem(event: React.ChangeEvent<HTMLInputElement>) {

        const checked = event.target.checked
        const index = checkboxInfoArr.findIndex(info => info.name == event.target.name)

        const beforeArr = checkboxInfoArr.slice(0, index)
        const afterArr = checkboxInfoArr.slice(index + 1)

        const isAllChecked = (
            beforeArr.every(info => info.checked) &&
            afterArr.every(info => info.checked) &&
            checked
        )

        setCheckboxInfoArr(prevState => [
            ...beforeArr,
            {...prevState[index], checked: checked},
            ...afterArr
        ])

        setIsAll(isAllChecked)
    }

    return (
        <ColumnDiv>
            <TextWithCheckBox text={"ALL"} checked={isAll} handleChange={handleAll} name={"ALL"}/>
            {
                checkboxInfoArr.map((info, index) =>
                    <TextWithCheckBox text={info.name} checked={info.checked} handleChange={handleItem}
                                      name={info.name} key={index}/>
                )
            }
        </ColumnDiv>
    )
})

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
`
