import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function postTankCheckOCR(data: FormData) {
    const response = await axios.post<BaseResponse<TankCheckOCR[]>>("tank/check/ocr", data, {
        headers: {"Content-Type": "multipart/form-data"}
    })

    return response.data
}

export interface TankCheckOCR {
    manufacturerNumber?: string
    volume?: string
    url?: string
}