import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getTankCheckDetailForm() {

    const response = await axios.get<BaseResponse<TankCheckDetailForm>>('/tank/check/detail/form')

    return response.data
}

export interface TankCheckDetailForm {
    testType: TankCheckDetailFormType[]
    volumeType: TankCheckDetailFormType[]

    [key: string] : TankCheckDetailFormType[]
}

interface TankCheckDetailFormType {
    id: number
    title: string
}