import React, {useState} from 'react';
import styled from 'styled-components';
import {Wrap} from "./CustomerReceiverList";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import DatePickers from "../../../../components/style/datepicker/Datepicker";
import Select from "../../../../components/style/select/Select";
import Button from "../../../../components/style/button/Button";
import {ItemBoxDiv} from "../../../../components/style/div/ItemBoxDiv";
import SectionTitle from "../../../../components/style/div/SectionTitle";
import {SmsSendForm} from "../../../../api/sms/registration/getSmsRegistrationForm";
import {UseFormMethods} from "react-hook-form";
import getNowDate from "../../../../lib/getNowDate";
import {Sms, SmsContainerDto, SmsFile} from "../../../../api/sms/getSmsList";
import {Member, MemberForm} from "../../../../api/member/Member";
import {getPhoneNumber} from "../../../../lib/getManagerPhoneNumber";
import getLocalStorageItemOrNull from "../../../../lib/getLocalStorageItemOrNull";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_ERP_USER_UID
} from "../../../../resources/LocalStorageConstants";
import {SMS_SEND_USER_MOBILE} from "../../../../redux/GlobalResources";


interface CustomerSendBoxProps {
    methods: UseFormMethods<Record<string, any>>
    form?: SmsSendForm
    memberForm?: MemberForm
    post: (smsGroup: SmsContainerDto) => void
    selectedMemberList: Member[]
    smsFile?: SmsFile
    textSize: number
}

function CustomerSendBox(props: CustomerSendBoxProps) {

    const [scheduleType, setScheduleType] = useState<number>(2)
    const adminUid = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_UID)
    const adminId = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME)

    function onSubmit() {
        if (adminId && adminUid && props.form) {
            const smsList: Sms[] = []
            const data = props.methods.getValues()

            if (props.selectedMemberList.length == 0) {
                alert("받는 사람을 선택해 주세요.")
                return
            }

            if (props.textSize == 0) {
                alert("메세지 내용을 입력해 주세요.")
                return
            }

            if (props.textSize > 2000) {
                alert("2,000 Byte 이상은 메세지 전송이 불가합니다.")
                return
            }

            for (let i = 0; i < props.selectedMemberList.length; i++) {
                const member = props.selectedMemberList[i]

                smsList.push({
                    uid: undefined,
                    memberId: adminUid,
                    contents: data.contents,
                    receiveUser: member.companyName,
                    receiveUserMobile: getPhoneNumber(member.mobile1, props.memberForm?.memberPhoneCarrierType),
                    sendUser: adminId,
                    sendUserMobile: SMS_SEND_USER_MOBILE,
                    sendDateTime: undefined,
                    scheduledSendDateTime: getScheduleSendDateTime(props.form, data.date, data.time, data.minute),
                    size: props.textSize,
                    scheduleType: data.scheduleType,
                    sendType: undefined,
                    state: undefined
                })
            }

            props.post({
                uid: undefined,
                file: props.smsFile,
                smsDtoList: smsList
            })
        }
    }

    function getScheduleSendDateTime(form: SmsSendForm, date: string, time: string, minute: string): string | undefined {
        const HH = form.timeType.find(type => type.id == parseInt(time))?.value
        const MM = form.minuteType.find(type => type.id == parseInt(minute))?.value

        return `${date}T${HH}:${MM}:00.000`
    }

    return (
        <Wrap>
            <SectionTitle>
                발송
            </SectionTitle>
            <ItemDiv>
                <ItemBoxDiv>
                    상태
                </ItemBoxDiv>
                {
                    props.form?.scheduleType?.map((type, index) => {
                        return (
                            <React.Fragment key={index}>
                                <SmsRadioButton
                                    name={`scheduleType`}
                                    ref={props.methods.register}
                                    type={"radio"}
                                    value={type.id}
                                    checked={scheduleType == type.id}
                                    onChange={event => {
                                        setScheduleType(parseInt(event.currentTarget.value))
                                    }}
                                />
                                <RadioText>{type.title}</RadioText>
                            </React.Fragment>
                        )
                    })
                }
            </ItemDiv>
            <ItemDiv>
                <ItemBoxDiv>
                    시간
                </ItemBoxDiv>
                <SmsDatePicker
                    name={"date"}
                    defaultValue={getNowDate()}
                    reference={props.methods.register}
                />
            </ItemDiv>
            <ItemDiv>
                <ItemBoxDiv/>
                <SmsSelect
                    menuItems={props.form?.timeType}
                    name={"time"}
                    defaultValue={1}
                    reference={props.methods.register}
                />
            </ItemDiv>
            <ItemDiv>
                <ItemBoxDiv/>
                <SmsSelect
                    menuItems={props.form?.minuteType}
                    name={"minute"}
                    defaultValue={1}
                    reference={props.methods.register}
                />
            </ItemDiv>
            <SendButton onClick={onSubmit}>
                발송
            </SendButton>
        </Wrap>
    );
}

const RadioButton = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #bec0c2;
  background-color: blue;
  padding: 0;
  margin: 0;
  align-self: center;
  justify-self: center;
`

const SmsRadioButton = styled(RadioButton)`
  margin-left: 8px;
`

const RadioText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 500;
  margin: 8px;
`

const SmsDatePicker = styled(DatePickers)`
  width: 170px;
  height: 32px;
  margin-left: 8px;
`

const ItemDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const SmsSelect = styled(Select).attrs({
    width: "170px",
    height: "32px",
    marginLeft: "8px"
})``

const SendButton = styled(Button)`
  width: 258px;
  height: 32px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  margin-top: 8px;
  background-color: #4191ff;
  color: var(--white);
`

export default CustomerSendBox