import React from 'react';
import styled from 'styled-components';
import UpArrow from "../../../assets/images/purchase/common-36-upward@3x.png";
import Button from "./Button";

const TopButton = styled(Button).attrs((props) => ({
    children: <img src={UpArrow} width={"36px"}/>,
    onClick: () => window.scrollTo(0, 0)
}))`
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border-width: 0;
  margin-top: 80px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24);
  background-color: var(--blue);
  justify-self: flex-end;
  align-self: flex-end;
`

export default TopButton