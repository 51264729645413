import styled from "styled-components";

export const CommonButton = styled.button`
  width: 70px;
  height: 32px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--gray-3);
  color : var(--white);
  font-size : 10px;
`