import React from 'react';
import styled from 'styled-components';

interface DefaultImageComponentProps {
    src: string
    width: string
    height: string
    marginLeft?: string
    marginRight?: string
    marginTop?: string
    marginBottom?: string
    onClick?: () => void
}

function DefaultImage(props: DefaultImageComponentProps) {
    return (
        <Image
            src={props.src}
            width={props.width}
            height={props.height}
            marginLeft={props.marginLeft}
            marginRight={props.marginRight}
            marginTop={props.marginTop}
            marginBottom={props.marginBottom}
            onClick={props.onClick}
        />
    );
}

const Image = styled.img`
  width: ${(props: DefaultImageComponentProps) => props.width};
  height: ${(props: DefaultImageComponentProps) => props.height};
  margin-left: ${(props: DefaultImageComponentProps) => props.marginLeft};
  margin-right: ${(props: DefaultImageComponentProps) => props.marginRight}
  margin-top: ${(props: DefaultImageComponentProps) => props.marginTop}
  margin-bottom: ${(props: DefaultImageComponentProps) => props.marginBottom}
`
export default DefaultImage