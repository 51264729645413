import React, {ReactNode} from "react";
import styled from "styled-components";

interface DivProps {
    direction?: string;
    justifyContent?: string;
    alignItems?: string;
    color?: string;
    paddingLeft?: string;
    paddingRight?: string;
    children: ReactNode;
}

function DefaultWrapperDiv(props: DivProps) {
    return (
        <StyledDiv
            direction={props.direction}
            color={props.color}
            justifyContent={props.justifyContent}
            alignItems={props.alignItems}
            paddingLeft={props.paddingLeft}
            paddingRight={props.paddingRight}
        >
            {props.children}
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
  display: flex;
  width: 1080px;
  padding-left: ${(props: DivProps) => props.paddingLeft || "80px"};
  padding-right: ${(props: DivProps) => props.paddingRight || "80px"};
  background-color: ${(props: DivProps) => props.color};
  justify-content: ${(props: DivProps) => props.justifyContent};
  align-items: ${(props: DivProps) => props.justifyContent || "stretch"};
  flex-direction: ${(props: DivProps) => props.direction || "column"};
`;

export default DefaultWrapperDiv;
