import React from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {RowDiv} from "../../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import HorizontalSpace from "../../../components/HorizontalSpace";
import ResultText from "../../../components/ResultText";
import {TableTitleDiv} from "../../../components/style/div/TableTitleDiv";
import {Retest} from "../../../api/tank/retest/getRetestList";
import {RetestForm} from "../../../api/tank/retest/getRetestForm";
import {SearchSelect} from "../../../components/style/select/SearchSelect";
import {TableTitleText} from "../../../components/style/text/TableTitleText";
import {SearchButton} from "../../../components/style/button/SearchButton";


interface TankRetestProps {
    form?: RetestForm
    data?: Array<Retest>
    get: (searchType: number, searchDate: string) => void
}

function TankRetestScreen(props: TankRetestProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    function onSubmit() {
        const year = props.form?.searchType[methods.watch("searchType") - 1].years?.find(year => year.id == methods.watch("year"))?.value
        const month = props.form?.searchType[methods.watch("searchType") - 1].months?.find(months => months.id == methods.watch("month"))?.value
        const date = `${year}-${month}-01`

        if (year && month) {
            props.get(methods.watch("searchType"), date)
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <RowDiv>
                    <ItemBoxDiv>
                        조회기간
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        reference={methods.register}
                        defaultValue={1}
                    />
                    {
                        props.form?.searchType[methods.watch("searchType") - 1]?.years.find(retestDate => retestDate.isCurrent)?.id &&
                        props.form?.searchType[methods.watch("searchType") - 1]?.months.find(retestDate => retestDate.isCurrent)?.id &&
                        <>
                            <SearchSelect
                                menuItems={props.form?.searchType[methods.watch("searchType") - 1]?.years}
                                name={"year"}
                                reference={methods.register}
                                defaultValue={props.form?.searchType[methods.watch("searchType") - 1]?.years.find(retestDate => retestDate.isCurrent)?.id}
                            />
                            <SearchSelect
                                menuItems={props.form?.searchType[methods.watch("searchType") - 1]?.months}
                                name={"month"}
                                reference={methods.register}
                                defaultValue={props.form?.searchType[methods.watch("searchType") - 1]?.months.find(retestDate => retestDate.isCurrent)?.id}
                            />
                            <SearchButton type={"submit"}>검색</SearchButton>
                        </>
                    }
                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>재검사 리스트</TableTitleText>
                </TableTitleDiv>

                <ResultText count={props.data?.length}/>
            </form>
        </FormProvider>
    )
}

export default TankRetestScreen