import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {RowDiv} from "../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../components/style/div/ItemBoxDiv";
import ErpTable from "../../components/table/ErpTable";
import {GridOptions} from "ag-grid-community";
import Moment from "moment";
import {MenuItem} from "../../components/style/select/Select";
import {Searchbar} from "../../components/style/input/Searchbar";
import {TableTitleText} from "../../components/style/text/TableTitleText";
import ResultText from "../../components/ResultText";
import {SearchSelect} from "../../components/style/select/SearchSelect";
import {SearchHalfDatePicker} from "../../components/style/datepicker/SearchHalfDatePicker";
import {SearchButton} from "../../components/style/button/SearchButton";
import HorizontalSpace from "../../components/HorizontalSpace";
import {TableTitleDiv} from "../../components/style/div/TableTitleDiv";
import {MemberForm} from "../../api/member/Member";


interface CommonMemberScreenProps {
    form?: MemberForm
    onSubmit: (data: any) => void
    gridOptions: GridOptions
    isPending?: boolean
}

function CommonMemberScreen(props: CommonMemberScreenProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(props.onSubmit)}>
                <RowDiv>
                    <ItemBoxDiv>
                        {props.isPending ? "신청 일자" : "승인 일자"}
                    </ItemBoxDiv>
                    <SearchHalfDatePicker
                        defaultValue={Moment().format('YYYY-MM-01')}
                        reference={methods.register}
                        name="startDate"
                    />
                    <SearchHalfDatePicker
                        defaultValue={Moment().format('YYYY-MM-DD')}
                        reference={methods.register}
                        name="endDate"
                    />
                </RowDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <SearchSelect menuItems={props.form?.memberSearchType}
                                  name={"searchType"}
                                  defaultValue={1}
                                  reference={methods.register}/>
                    <Searchbar
                        placeholder={"검색어를 입력해주세요."}
                        name={"searchQuery"}
                        ref={methods.register}/>
                    <SearchButton type="submit">검색</SearchButton>
                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>회사 목록</TableTitleText>
                </TableTitleDiv>

                <ResultText count={props.gridOptions.rowData?.length}/>

                <ErpTable
                    tableWidth={1080}
                    tableHeight={450}
                    gridOptions={props.gridOptions}
                    withNumber={true}
                />
            </form>
        </FormProvider>
    )
}

export default CommonMemberScreen