import React from "react";
import styled from "styled-components";
import Button from "../../../../../components/style/button/Button";


function TankCertificateModalButton(props: any, onConfirm: (data: any) => void) {

    function handleClick() {
        onConfirm(props.data)
    }

    return (
        <>
            <StyledButton onClick={handleClick}>
                선택
            </StyledButton>
        </>
    )
}

const StyledButton = styled(Button)`
  background-color: var(--blue);
  color: var(--white);
`

export default TankCertificateModalButton