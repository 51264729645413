import {ActionType, createAction, createReducer} from "typesafe-actions";

const LOG_OUT = 'logOut/LOG_OUT'

export const logOut = createAction(LOG_OUT)()

type LogOutAction = ActionType<typeof logOut>

type LogOustState = {
    isLogOut: boolean
};

const initialState: LogOustState = {
    isLogOut: false
};

export const logOutReducer = createReducer<LogOustState, LogOutAction>(initialState)
    .handleAction(logOut, state => ({isLogOut: true}))