import React, {useState} from 'react'
import styled from "styled-components";
import Button from "../../../components/style/button/Button";
import {Member, putMember} from "../../../api/member/Member";
import SimpleModal from "../../../components/modal/SimpleModal";
import {MemberTableButton} from "../list/MemberListTableLinkButton";


function InactiveTableBtn(props: any, handlePut: (memberType: Member) => void) {

    const [showModal, setShowModal] = useState(false)

    function handleRestore() {
        setShowModal(true)
    }

    function onConfirm() {
        handlePut({
            ...props.data,
            state: 1
        })
    }

    return (
        <React.Fragment>
            <MemberTableButton onClick={handleRestore} style={{marginRight: "5px"}}>
                복원
            </MemberTableButton>

            <SimpleModal
                headerContentText="휴면 회원"
                subText={"해당 회원을 복원하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showModal}
                onConfirm={onConfirm}
                onCloseModal={() => setShowModal(false)}
            />
        </React.Fragment>
    )
}

export default InactiveTableBtn
