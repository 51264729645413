import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getSalesAccountList(
    memberId: string = "",
    code: string = "",
    accountName: string = "",
    address: string = "",
    phone: string = ""
) {
    const response = await axios.get<BaseResponse<SalesAccount[]>>(`/sales/account`, {
        params: {
            memberId: memberId,
            code: code,
            accountName: accountName,
            address: address,
            phone: phone
        }
    })

    return response.data
}

interface VolumePriceValue {
    title: string
    value: number
    factor: number
}

export interface SalesAccount {
    memberId: string,
    code: string,
    accountName: string,
    ownerName?: string,
    tel1?: string,
    tel2?: string,
    tel3?: string,
    phone1?: string,
    phone2?: string,
    phone3?: string,
    address: string,
    addressDetail?: string,
    longitude: number,
    latitude: number,
    employeeId?: string,
    statusType: string,
    sellType: string,
    salesType: string,
    collectMoneyType: string,
    salesAccountType: string,
    groupId?: number,
    volumePrice?: VolumePriceValue,
    adjustmentValue?: number,
    unitPrice?: number,
    gaugeInitDate?: string,
    gaugeInitValue?: number,
    tankInitDate?: string,
    tankInitValue?: number,
    tankWeight?: number,
    isBillNeeded?: boolean,
    tongsoriSyncId?: number,
    contractNo?: string,
    contractStartDate?: string,
    contractEndDate?: string,
    contractorName?: string,
    contractorSocialNo?: string,
    tankOwnerType?: string,
    facilityOwnerType?: string,
    regulatorType?: number,
    regulatorValue?: string,
    vaporizerValue?: number,
    meterValue?: number,
    fuseCockCount?: number,
    valveValue?: number,
    valveCount?: number,
    supplyPipe1Value?: number,
    supplyPipe1Count?: number,
    supplyPipe2Value?: number,
    supplyPipe2Count?: number,
    isFacilityGood?: boolean,
    isPipeInstalled?: boolean,
    salesAccountElectronicBillId?: number
}