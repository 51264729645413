import React, {ReactNode} from 'react';
import styled from 'styled-components';

interface ComponentProps {
    isSelected?: boolean
    children?: ReactNode
    onClick?: () => void
    width?: string
}

function BasicNavButton(props: ComponentProps) {
    return (
        <>
            {
                props.isSelected ?
                    (<SelectedButton width={props.width} onClick={props.onClick}>
                        {props.children}
                    </SelectedButton>)
                    :
                    (<UnSelectedButton width={props.width} onClick={props.onClick}>
                        {props.children}
                    </UnSelectedButton>)
            }
        </>
    );
}

const SelectedButton = styled.button`
  box-sizing: border-box;
  padding: 0px;
  width: ${(props: ComponentProps) => props.width || "80px"};
  height: 48px;
  border-radius: 0px;
  border-color: transparent;
  background-color: transparent;
  border-bottom-width: 2px;
  border-bottom-color: var(--blue);
  font-size: 13px;
  font-weight: 400;
`

const UnSelectedButton = styled(SelectedButton)`
  color: var(--gray-3);
  border-color: transparent;
  background-color: transparent;
`
export default BasicNavButton