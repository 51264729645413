import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../../../../components/style/text/FixedWeightText";
interface RedDotComponentProps {
}

function RedDot (props : RedDotComponentProps){
    return (
        <Dot>*</Dot>
    );
}

const Dot = styled(FixedWeightText)`
  color : var(--red);
  margin-left: -6px;
`

export default RedDot