import React, {useEffect, useState} from 'react'
import CancelButton from "../../../../components/style/button/CancelButton"
import ConfirmButton from "../../../../components/style/button/ConfirmButton"
import {LINK_TANK} from "../../../../resources/LinkConstants"
import {FormProvider, useForm} from "react-hook-form";
import {TitleDiv} from "../../../../components/style/div/TitleDiv";
import {TitleButtonDiv} from "../../../../components/style/div/TitleButtonDiv";
import {TitleText} from "../../../../components/style/text/TitleText";
import {Retest, RetestTank} from "../../../../api/tank/retest/getRetestList";
import {
    GridApi,
    GridReadyEvent,
    ICellRendererParams
} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";
import TableNumberColumn from "../../../../components/table/TableNumberColumn";
import {RetestDetailForm, RetestDetailStateItem} from "../../../../api/tank/retest/getRetestDetailForm";
import TankRetestDetailStateColumn from "./component/TankRetestDetailStateColumn";
import {RowDiv} from "../../../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../../../components/style/div/ItemBoxDiv";
import {SearchHalfDatePicker} from "../../../../components/style/datepicker/SearchHalfDatePicker";
import {SearchSelect} from "../../../../components/style/select/SearchSelect";
import HorizontalSpace from "../../../../components/HorizontalSpace";
import {TableTitleDiv} from "../../../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../../../components/style/text/TableTitleText";
import ResultText from "../../../../components/ResultText";
import {IsColumnFuncParams} from "ag-grid-community/dist/lib/entities/colDef";


interface TankRetestDetailScreenProps {
    form?: RetestDetailForm
    editData?: Retest
    put: (retest: Retest) => void
}

function TankRetestDetailScreen(props: TankRetestDetailScreenProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [rowData, setRowData] = useState<RetestTank[]>([])
    const [isAllCompleted, setIsAllCompleted] = useState<boolean>(false)
    const methods = useForm({
        mode: "onBlur"
    })

    useEffect(() => {
        if (gridApi) {
            if (props.editData) {
                setRowData(props.editData.retestTankList)
            }

            if (props.form && gridApi.getColumnDefs().length == 0) {
                gridApi.setColumnDefs([
                    {
                        headerName: "No",
                        width: 54,
                        cellRendererFramework: TableNumberColumn
                    },
                    {
                        headerName: "상태",
                        field: "state",
                        cellRendererFramework: (cellParams: ICellRendererParams) => TankRetestDetailStateColumn(cellParams, setRowData, props.form),
                        width: 100
                    },
                    {
                        headerName: "검사예정년도",
                        field: "nextExaminationDate",
                        width: 110
                    },
                    {
                        headerName: "제조번호",
                        field: "manufacturerNumber",
                        width: 110
                    },
                    {
                        headerName: "제조사",
                        field: "manufacturer",
                        width: 90
                    },
                    {
                        headerName: "용량",
                        field: "volume",
                        width: 90
                    },
                    {
                        headerName: "자가여부",
                        field: "ownerType",
                        width: 90
                    },
                    {
                        headerName: "* 판매거래처명",
                        field: "companySalesPlaceName",
                        editable: (params: IsColumnFuncParams) => params.data.state !== getCompleteType()?.id,
                        width: 150
                    },
                    {
                        headerName: "* 주소",
                        field: "address",
                        editable: (params: IsColumnFuncParams) => params.data.state !== getCompleteType()?.id,
                        width: 284
                    }
                ])
            }

            if (props.editData && props.form) {
                methods.setValue("requestDate", props.editData.requestDate)
                methods.setValue("completionRequestDate", props.editData.completionRequestDate)
            }
        }
    }, [gridApi, props.editData, props.form])

    useEffect(() => {
        const selectedType = methods.watch("stateType")

        if (selectedType !== 0 && gridApi) {
            setRowData(prevState =>
                prevState.map(prev => {
                    return {
                        ...prev,
                        state: selectedType
                    }
                })
            )
        }
    }, [methods.watch("stateType")])

    useEffect(() => {
        if (rowData.length > 0) {
            const retestTanks: RetestTank[] = []

            gridApi?.forEachNode(node => {
                retestTanks.push(node.data)
                node.setData(rowData[node.rowIndex])
            })

            setIsAllCompleted(retestTanks.find(retestTank => retestTank.state !== getCompleteType()?.id) == undefined)
        }
    }, [rowData])

    function getCompleteType(): RetestDetailStateItem | undefined {
        return props.form?.stateType.find(state => state.title == "완료")
    }

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
    }

    function onEdit() {
        if (gridApi && props.editData) {

            const retestTanks: RetestTank[] = []

            gridApi.forEachNode(node => retestTanks.push(node.data))

            props.put({
                uid: props.editData.uid,
                memberId: props.editData.memberId,
                companyName: props.editData.companyName,
                requestDate: props.editData.requestDate,
                completionRequestDate: methods.watch("completionRequestDate"),
                completionDate: props.editData.completionDate,
                retestTankList: retestTanks
            })
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(() => {
            })}>
                <TitleDiv>
                    <TitleText>
                        재검사신청 수정
                    </TitleText>
                    <TitleButtonDiv>
                        <CancelButton
                            link={LINK_TANK}
                            marginRight={10}
                        />
                        <ConfirmButton
                            title={"확인"}
                            modalTitle={"재검사 수정"}
                            modalText={"재검사를 수정하시겠습니까?"}
                            onSubmit={onEdit}
                        />
                    </TitleButtonDiv>
                </TitleDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        신청일
                    </ItemBoxDiv>
                    <SearchHalfDatePicker
                        name={"requestDate"}
                        reference={methods.register}
                        value={methods.watch("requestDate")}
                        disabled={true}
                    />
                    <ItemBoxDiv>
                        완료요청일
                    </ItemBoxDiv>
                    <SearchHalfDatePicker
                        name={"completionRequestDate"}
                        value={methods.watch("completionRequestDate")}
                        reference={methods.register}
                        disabled={isAllCompleted}
                    />
                </RowDiv>

                <RowDiv>
                    <ItemBoxDiv>
                        상태
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.stateType}
                        name={"stateType"}
                        reference={methods.register}
                        defaultValue={0}
                    />
                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>
                        재검사 리스트
                    </TableTitleText>
                </TableTitleDiv>

                <ResultText count={props.editData?.retestTankList.length}/>

                <div className="ag-theme-alpine" style={{width: 1080, height: 450}}>
                    <AgGridReact
                        scrollbarWidth={4}
                        onGridReady={onGridReady}
                        rowData={rowData}
                    />
                </div>
            </form>
        </FormProvider>
    )
}

export default TankRetestDetailScreen