import React from 'react';
import TankRetestDetailScreen from "./TankRetestDetailScreen";
import {useHistory, useParams} from "react-router-dom";
import {MatchType} from "../../../../api/MatchType";
import {useAsync2} from "../../../../hooks/useAsync2";
import {getRetest} from "../../../../api/tank/retest/getRetest";
import {putRetest} from "../../../../api/tank/retest/putRetest";
import {Retest} from "../../../../api/tank/retest/getRetestList";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";
import {getRetestDetailForm} from "../../../../api/tank/retest/getRetestDetailForm";
import {LINK_TANK} from "../../../../resources/LinkConstants";


interface TankRetestDetailContainerProps {

}

function TankRetestDetailContainer(props: TankRetestDetailContainerProps) {

    const {uid} = useParams<MatchType>()
    const history = useHistory()

    const {data: editData, status: getStatus} = useAsync2(getRetest, true, [uid])
    const {execute: update, status: updateStatus} = useAsync2(putRetest, false)
    const {data: form} = useAsync2(getRetestDetailForm, true)

    function handleUpdate(retest: Retest) {
        update(retest).then(() => {
            history.push(LINK_TANK)
        })
    }

    return (
        <>
            {(getStatus == 'loading' || updateStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankRetestDetailScreen
                form={form}
                editData={editData}
                put={handleUpdate}
            />
        </>
    )
}

export default TankRetestDetailContainer