import axios from 'axios';
import {BaseResponse} from "../BaseResponse";
import getFirstDateOfTheMonth from "../../lib/getFirstDateOfTheMonth";
import getNowDate from "../../lib/getNowDate";
import {Pageable} from "../pageable";


export async function getSmsList(smsPayload: SmsPayload) {

    const response = await axios.get<BaseResponse<Pageable<Sms>>>('/sms', {
        params: {
            offset: smsPayload.offset,
            limit: smsPayload.limit,
            startDate: smsPayload.startDate ? smsPayload.startDate : getFirstDateOfTheMonth(),
            endDate: smsPayload.endDate ? smsPayload.endDate : getNowDate(),
            searchType: smsPayload.searchType ? smsPayload.searchType : 1,
            searchQuery: smsPayload.searchQuery ? smsPayload.searchQuery : ""
        }
    })

    return response.data
}

export interface SmsPayload {
    offset: number
    limit: number
    startDate?: string
    endDate?: string
    searchType?: number
    searchQuery?: string
}

export interface SmsFile {
    fullName: string
    base64: string
}

export interface Sms {
    uid?: string
    memberId: string
    contents: string
    receiveUser: string
    receiveUserMobile: string
    sendUser: string
    sendUserMobile: string
    sendDateTime?: string
    scheduledSendDateTime?: string
    size: number
    scheduleType: string
    sendType?: string
    state?: string
}

export interface SmsContainerDto {
    uid?: string
    file?: SmsFile
    smsDtoList: Array<Sms>
}
