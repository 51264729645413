import {BrowserRouter, Route, Switch} from "react-router-dom";
import React from "react";
import HomePage from "../pages/home/HomeScreen";
import Index from "../components/layout";
import LoginContainer from "../pages/login/LoginContainer";
import SignUp from "../pages/signup/SignUpContainer";
import MemberContainer from "../pages/member/MemberContainer";
import BasicScreen from "../pages/basic/BasicScreen";
import {
    LINK_BASIC,
    LINK_HOME,
    LINK_SIGN_IN,
    LINK_SIGN_UP,
    LINK_SMS,
    LINK_PRICE,
    LINK_MEMBER,
    LINK_TANK,
    LINK_RETEST_MODIFICATION,
    LINK_TANK_MODIFICATION,
    LINK_TANK_REGISTRATION,
    LINK_NOTICE,
    LINK_TANK_CHECK_DEFAULT,
    LINK_TANK_DOWN, LINK_TANK_CHECK_REGISTRATION, LINK_TANK_CHECK_MODIFICATION, LINK_SMS_REGISTRATION
} from "../resources/LinkConstants";
import PriceContainer from "../pages/price/PriceContainer";
import SmsContainer from "../pages/sms/SmsContainer";
import NoticeContainer from "../pages/notice/NoticeContainer";
import MemberInfoContainer from "../pages/member/list/info/MemberInfoContainer";
import TankContainer from "../pages/tank/TankContainer";
import TankCertificateRegistrationContainer
    from "../pages/tank/regist/certificate/TankCertificateRegistrationContainer";
import AuthRoute from "../pages/main/AuthRoute";
import TankRetestDetailContainer from "../pages/tank/retest/detail/TankRetestDetailContainer";
import DefaultContainer from "../pages/tank/check/default/DefaultContainer";
import TankCheckRegistrationContainer from "../pages/tank/check/registration/TankCheckRegistrationContainer";
import TankDownContainer from "../pages/tank/certificate/down/TankDownContainer";
import SmsRegistrationContainer from "../pages/sms/registration/SmsRegistrationContainer";


interface RootComponentProps {

}

function Root(props: RootComponentProps) {
    return (
        <BrowserRouter>
            <Index>
                <Switch>
                    <Route exact path={LINK_BASIC} component={BasicScreen}/>
                    <Route exact path={LINK_HOME} component={HomePage}/>
                    <Route exact path={LINK_SIGN_IN} component={LoginContainer}/>
                    <Route exact path={LINK_SIGN_UP} component={SignUp}/>

                    {/* 회원 관리 */}
                    <AuthRoute exact path={LINK_MEMBER} component={MemberContainer}/>
                    <AuthRoute exact path={LINK_MEMBER + "/:userid"} component={MemberInfoContainer}/>

                    {/* 탱크 관리 */}
                    {/* 탱크 관리 > 합격증 리스트 */}
                    <AuthRoute exact path={LINK_TANK} component={TankContainer}/>
                    <AuthRoute exact path={LINK_TANK_DOWN} component={TankDownContainer}/>
                    <AuthRoute exact path={LINK_TANK_MODIFICATION} component={TankCertificateRegistrationContainer}/>
                    <AuthRoute exact path={LINK_TANK_REGISTRATION} component={TankCertificateRegistrationContainer}/>
                    {/* 탱크관리 > 검사 */}
                    <AuthRoute exact path={LINK_TANK_CHECK_DEFAULT} component={DefaultContainer}/>
                    <AuthRoute exact path={LINK_TANK_CHECK_REGISTRATION} component={TankCheckRegistrationContainer}/>
                    <AuthRoute exact path={LINK_TANK_CHECK_MODIFICATION} component={TankCheckRegistrationContainer}/>
                    {/* 탱크관리 > 재검사 */}
                    <AuthRoute exact path={LINK_RETEST_MODIFICATION} component={TankRetestDetailContainer}/>

                    {/* SMS 전송 */}
                    <AuthRoute exact path={LINK_SMS} component={SmsContainer}/>
                    <AuthRoute exact path={LINK_SMS_REGISTRATION} component={SmsRegistrationContainer}/>
                    {/* 가격 설정 */}
                    <AuthRoute exact path={LINK_PRICE} component={PriceContainer}/>
                    {/* 공지 팝업 */}
                    <AuthRoute exact path={LINK_NOTICE} component={NoticeContainer}/>
                </Switch>
            </Index>
        </BrowserRouter>
    );
}

export default Root;