import React, {useCallback, useEffect, useState} from "react";
import {Tank} from "../../../../api/tank/certification/getTankList";
import styled from "styled-components";
import {CommonButton} from "../../CommonButton";
import {useHistory} from "react-router-dom";
import {LINK_TANK} from "../../../../resources/LinkConstants";
import {RowDiv} from "../../../../components/style/div/RowDiv";
import {GridApi, GridReadyEvent, ICellRendererParams, RowDragEvent, ValueGetterParams} from "ag-grid-community";
import {ColDef, ColGroupDef, ValueFormatterParams, ValueSetterParams} from "ag-grid-community/dist/lib/entities/colDef";
import {AgGridReact} from "ag-grid-react";
import {FormProvider, useForm} from "react-hook-form";
import {ItemBoxDiv} from "../../../../components/style/div/ItemBoxDiv";
import HorizontalSpace from "../../../../components/HorizontalSpace";
import Input from "../../../../components/style/input/Input";
import Button from "../../../../components/style/button/Button";
import Select from "../../../../components/style/select/Select";
import {TankForm} from "../../../../api/tank/certification/getTankForm";
import TankDownDeleteButton from "./component/TankDownDeleteButton";


interface TankDownTableProps {
    data?: Tank[]
    form?: TankForm
    getList: (searchType?: number, searchQuery?: string) => void
    onPrint: (tankArr: Tank[]) => Promise<void>
    onDown: (tankArr: Tank[]) => void
}

function TankDownTable(props: TankDownTableProps) {

    const history = useHistory()
    const [leftApi, setLeftApi] = useState<GridApi>();
    const [rightApi, setRightApi] = useState<GridApi>();
    const [rawData, setRawData] = useState<Tank[]>([]);
    const [leftRowData, setLeftRowData] = useState<Tank[]>([]);
    const [rightRowData] = useState<Tank[]>([]);
    const methods = useForm({
        mode: "onBlur"
    })

    const leftColumns: (ColDef | ColGroupDef)[] = [
        {
            rowDrag: true,
            maxWidth: 50,
            rowDragText: () => "출고서류",
        },
        {
            colId: 'checkbox',
            maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true
        },
        {
            headerName: "제조번호",
            valueFormatter: ((params: ValueFormatterParams) => {
                const cNumber = params.data.certificateDto?.manufacturerNumber
                const pNumber = params.data.pressureTestDto?.manufacturerNumber
                const aNumber = params.data.allInOneTestDto?.manufacturerNumber

                return cNumber ? cNumber : pNumber ? pNumber : aNumber
            }),
            comparator: comparator,
            width: 500
        },
        {
            headerName: "용량",
            valueSetter: ((params: ValueSetterParams) => {
                const cVolume = params.data.certificateDto?.volume
                const pVolume = params.data.pressureTestDto?.volume
                const aVolume = params.data.allInOneTestDto?.volume

                return cVolume ? cVolume : pVolume ? pVolume : aVolume ? aVolume : ""
            }),
            valueGetter: ((params: ValueGetterParams) => {
                const cVolume = params.data.certificateDto?.volume
                const pVolume = params.data.pressureTestDto?.volume
                const aVolume = params.data.allInOneTestDto?.volume

                return cVolume ? cVolume : pVolume ? pVolume : aVolume ? aVolume : ""
            }),
            comparator: comparator,
            sortable: true,
            width: 300
        }
    ]

    const rightColumns: (ColDef | ColGroupDef)[] = [
        ...leftColumns.slice(0, 1),
        ...leftColumns.slice(2),
        {
            maxWidth: 50,
            cellRenderer: "tankDownDeleteButton",
        },
    ]

    const rightFrameworkComponents = {
        tankDownDeleteButton: (props: ICellRendererParams) => TankDownDeleteButton({cellParams: props})
    }

    const onDragStop = useCallback((params: RowDragEvent) => {
        const nodes = params.nodes

        leftApi?.applyTransaction({
            remove: nodes.map(function (node) {
                return node.data
            })
        })
    }, [leftApi])

    const onDragStop2 = useCallback((params: RowDragEvent) => {
        const nodes = params.nodes

        rightApi?.applyTransaction({
            remove: nodes.map(function (node) {
                return node.data
            })
        })
    }, [rightApi])

    useEffect(() => {
        if (props.data) {
            setRawData(props.data)
        }
    }, [props.data])

    useEffect(() => {
        setLeftRowData(rawData)
        // setRightRowData([])
    }, [rawData])

    useEffect(() => {
        if (!leftApi || !rightApi) {
            return;
        }
        const dropZoneParams = rightApi.getRowDropZoneParams({onDragStop: onDragStop});

        leftApi.removeRowDropZone(dropZoneParams)
        leftApi.addRowDropZone(dropZoneParams)

        const dropZoneParams2 = leftApi.getRowDropZoneParams({onDragStop: onDragStop2});

        rightApi.removeRowDropZone(dropZoneParams2)
        rightApi.addRowDropZone(dropZoneParams2)
    }, [leftApi, rightApi, onDragStop])

    function goBack() {
        history.push(LINK_TANK)
    }

    async function onPrint() {
        if (rightApi) {
            const rowData: Tank[] = []
            rightApi.forEachNode(node => {
                rowData.push(node.data)
            })
            await props.onPrint(rowData)
        }
    }

    function onDown() {
        if (rightApi) {
            const rowData: Tank[] = []
            rightApi.forEachNode(node => {
                rowData.push(node.data)
            })
            props.onDown(rowData)
        }
    }

    function comparator(): number {
        return 1
    }

    function onGridReady(params: GridReadyEvent, side: number) {
        if (side === 0) {
            setLeftApi(params.api)
        }

        if (side === 1) {
            setRightApi(params.api)
        }
    }

    function getGridWrapper(id: number) {
        return (
            <div className="ag-theme-alpine" style={{height: 450, width: 520}}>
                <AgGridReact
                    rowSelection='multiple'
                    defaultColDef={{
                        flex: 1,
                        sortable: true
                    }}
                    getRowNodeId={data => data.coverDto.uid}
                    rowDragManaged={true}
                    animateRows={true}
                    immutableData={true}
                    rowMultiSelectWithClick={true}
                    enableMultiRowDragging={true}
                    suppressMoveWhenRowDragging={true}
                    rowData={id === 0 ? leftRowData : rightRowData}
                    columnDefs={id === 0 ? leftColumns : rightColumns}
                    onGridReady={(params) => onGridReady(params, id)}
                    frameworkComponents={id === 0 ? undefined : rightFrameworkComponents}
                    onCellFocused={e => {
                        e.api.setSuppressRowClickSelection(e.column && e.column.getColDef().cellRenderer == "tankDownDeleteButton")
                    }}
                />
            </div>
        )
    }

    function onSubmit() {
        props.getList(
            methods.watch("searchType"),
            methods.watch("searchQuery")
        )
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <RowDiv>
                        <ItemBoxDiv>
                            검색
                        </ItemBoxDiv>
                        <TankDownSelect
                            menuItems={props.form?.searchType}
                            name={"searchType"}
                            reference={methods.register}
                            defaultValue={1}
                        />
                        <Searchbar placeholder={"검색어를 입력하세요"} name={"searchQuery"} ref={methods.register}/>
                        <SearchButton type="submit">검색</SearchButton>
                    </RowDiv>

                    <HorizontalSpace/>
                </form>
            </FormProvider>

            <SpaceBetween>
                {getGridWrapper(0)}
                {getGridWrapper(1)}
            </SpaceBetween>

            <FlexEndButtonParents>
                <DownTableButton onClick={goBack}>취소</DownTableButton>
                <DownTableButton onClick={onPrint}>인쇄</DownTableButton>
                <DownTableButton onClick={onDown}>다운로드</DownTableButton>
            </FlexEndButtonParents>
        </>
    )
}

export default TankDownTable

const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 402px;
  height: 32px;
  padding-left: 12px;
  margin-left: 8px;
  font-size: 10px;
`

const SearchButton = styled(Button)`
  width: 43px;
  height: 32px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
`

const TankDownSelect = styled(Select).attrs({
    width: "120px",
    height: "32px",
    marginLeft: "8px"
})``

const SpaceBetween = styled(RowDiv)`
  justify-content: space-between;
  margin-top: 11px;
  margin-bottom: 11px;
`

const FlexEndButtonParents = styled(RowDiv)`
  height: 48px;
  justify-content: flex-end;
`

const DownTableButton = styled(CommonButton)`
  width: 91px;
  margin-left: 10px;
`