import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function postTankCheckList(tankCheckRegistrations: TankCheck[]) {

    const response = await axios.post<BaseResponse<string>>('/tank/check', tankCheckRegistrations)

    return response.data
}

export interface TankCheck {
    uid?: string
    manufacturerNumber: string
    testType: number
    volume: number
    url: string
}
