import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";
import Input from "./Input";

type PlaceholderInput = {
    name?: string
    type?: string
    reference?: any
    errorMessage?: string
    placeholder?: string
    value?: string | number
}

export const PlaceholderInput = styled.div.attrs((props: PlaceholderInput) => ({
    children:
        <>
            <StyledInput ref={props.reference} name={props.name} value={props.value}/>
            <WrapPlaceholder>
                <PlaceholderText>
                    {props.placeholder}
                </PlaceholderText>
            </WrapPlaceholder>
        </>
}))<PlaceholderInput>`
  display: flex;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  justify-content: flex-end;

  &:focus-within {
    outline: none !important;
    border: 1px solid var(--blue);
  }
`
const StyledInput = styled(Input)`
  display: flex;
  width: 80%;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 12px;
  border-radius: 4px;
  font-size: 14px;
  border: transparent;
  outline: none;
  text-align: right;

  &:focus {
    outline: none !important;
    border: none;
  }
`
const WrapPlaceholder = styled.div`
  display: flex;
  margin-right: 12px;
  justify-content: center;
  align-items: center;
`

const PlaceholderText = styled(FixedWeightText).attrs({
    fontSize: "14px",
    fontWeight: 500
})`
  justify-content: center;
  color: var(--gray-4);
`