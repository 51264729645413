import React from 'react';
import styled from "styled-components";

interface HorizontalSpaceProps {
    height?: string
}

function HorizontalSpace(props: HorizontalSpaceProps) {
    return (
        <StyledDiv/>
    );
}

export default HorizontalSpace

const StyledDiv = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  background-color: var(--gray-5);
`