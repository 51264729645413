import React from 'react';
import FixedWeightText from "../style/text/FixedWeightText";
import ConfirmModal from "./ConfirmModal";
import {ButtonDiv, CancelButton, ContentDiv, HeaderContentText, ModalDiv, SubmitButton, SubText} from "./SimpleModal";


interface SimpleConfirmModalProps {
    headerContentText: string
    subText: string
    submitText: string
    showModal: boolean
    onCloseModal: () => void
    buttonColor?: string
    onConfirm?: () => void
}

function SimpleConfirmModal(props: SimpleConfirmModalProps) {

    const buttonColor = props.buttonColor ? props.buttonColor : "var(--blue)"

    function onConfirm() {
        if (props.onConfirm) {
            props.onConfirm()
        }

        closeModal()
    }

    function closeModal() {
        props.onCloseModal()
    }

    return (
        <ConfirmModal showModal={props.showModal} onCancel={closeModal}>
            <ModalDiv>
                <ContentDiv>
                    <HeaderContentText>{props.headerContentText}</HeaderContentText>
                    <SubText>{props.subText}</SubText>
                    <ButtonDiv>
                        <CancelButton onClick={closeModal}>
                            <FixedWeightText color={"var(--red)"} fontSize={"14px"}
                                             fontWeight={500}>취소</FixedWeightText>
                        </CancelButton>
                        <SubmitButton color={buttonColor} onClick={onConfirm}>
                            <FixedWeightText color={"var(--white)"} fontSize={"14px"}
                                             fontWeight={500}>{props.submitText}</FixedWeightText>
                        </SubmitButton>
                    </ButtonDiv>
                </ContentDiv>
            </ModalDiv>
        </ConfirmModal>
    );
}

export default SimpleConfirmModal