import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import logo from "../../assets/images/header/img_logo@3x.png";
import login_picture from "../../assets/images/header/ic_24_profile@3x.png";
import DefaultWrapperDiv from "../style/div/DefaultWrapperDiv";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import getLocalStorageItemOrNull from "../../lib/getLocalStorageItemOrNull";
import {
    LOCAL_STORAGE_KEY_ERP_USER_NAME,
    LOCAL_STORAGE_KEY_ERP_USER_UID,
    LOCAL_STORAGE_KEY_JWT,
} from "../../resources/LocalStorageConstants";
import {LINK_HOME, LINK_SIGN_IN} from "../../resources/LinkConstants";
import axios from "axios";

interface TitleComponentProps {

}


function Title(props: TitleComponentProps) {
    const history = useHistory()
    const [userName, setUserName] = useState<string | null>(getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_ERP_USER_NAME))
    const {data: erpUser} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)
    const {
        data: refreshToken,
        error: refreshTokenError
    } = useSelector((state: RootState) => state.refreshTokenReducer.refreshTokenResponse)
    const {isLogOut} = useSelector((state: RootState) => state.accountReducers.logOutReducer)

    function onClickLogOutLink() {
        localStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_UID)
        localStorage.removeItem(LOCAL_STORAGE_KEY_ERP_USER_NAME)
        localStorage.removeItem(LOCAL_STORAGE_KEY_JWT)
        setUserName(null)
        history.push(LINK_SIGN_IN)
        history.go(0)
    }

    useEffect(() => {
        if (erpUser != null) {
            setUserName(erpUser.adminId)
            axios.defaults.headers.common['Authorization'] = erpUser.token && `Bearer ${erpUser.token.accessToken}`
            localStorage.setItem(LOCAL_STORAGE_KEY_JWT, JSON.stringify(erpUser.token))
            localStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_NAME, erpUser.adminId)
            localStorage.setItem(LOCAL_STORAGE_KEY_ERP_USER_UID, erpUser.uid)
        }

        if (refreshToken != null) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken.accessToken}`
            localStorage.setItem(LOCAL_STORAGE_KEY_JWT, JSON.stringify(refreshToken))
            window.location.reload();
        }

        if (refreshTokenError) {
            onClickLogOutLink()
        }

        if (isLogOut) {
            onClickLogOutLink()
        }
    }, [erpUser, refreshToken, refreshTokenError, isLogOut])

    return (
        <DefaultWrapperDiv direction={"row"} justifyContent={"space-between"}>
            <Link to={LINK_HOME}
                  style={{textDecoration: 'none', justifyContent: 'center'}}>
                <CenterDiv>
                    <LogoImage src={logo}/>
                    <TitleText>통소리 ERP</TitleText>
                </CenterDiv>
            </Link>
            {!userName ?
                <CenterDiv>
                    <Link to="/signIn" style={{textDecoration: 'none'}}>
                        <NameText>로그인</NameText>
                    </Link>
                    <Link to="/signUp" style={{textDecoration: 'none'}}>
                        <NameText>회원가입</NameText>
                    </Link>
                </CenterDiv> :
                <CenterDiv>
                    <PictureImage src={login_picture}/>
                    <Link to="/logout" style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameText>{userName && `${userName}님`}</NameText>
                    </Link>
                    <Link to={LINK_HOME}
                          onClick={onClickLogOutLink}
                          style={{textDecoration: 'none', justifyContent: 'center'}}>
                        <NameText>로그아웃</NameText>
                    </Link>
                </CenterDiv>
            }
        </DefaultWrapperDiv>
    )
}

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
`
const LogoImage = styled.img`
  width: 32px;
  height: 32px;
`

const PictureImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const TitleText = styled.h1`
  width: fit-content;
  height: fit-content;
  font-size: 18px;
  font-weight: 400;
  color: white;
  margin-left: 16px
`

const NameText = styled.button`
  width: fit-content;
  min-width: 80px;
  height: fit-content;
  border-width: 0;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  align-items: center;
  background: transparent;
  color: white;
`
export default Title;