import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {CellClickedEvent, GridOptions} from "ag-grid-community";
import {RowDiv} from "../../components/style/div/RowDiv";
import styled from "styled-components";
import Button from "../../components/style/button/Button";
import Input from "../../components/style/input/Input";
import TextArea from "../../components/style/input/TextArea";
import VisibleToggle from "./component/VisibleToggle";
import InputFile from "../../components/style/input/InputFile";
import ErpTable from "../../components/table/ErpTable";
import {ItemBoxDiv} from "../../components/style/div/ItemBoxDiv";
import {NoticeDto} from "../../api/notice/Notice";
import SimpleModal from "../../components/modal/SimpleModal";
import {getFormattedContents} from "../../components/format/Formatter";
import {createFile} from "./createFileFromUrl";
import {fileToBase64} from "../../lib/fileToBase64";
import DropImg from "../../assets/images/signup/ic-48-close-black@3x.png"
import {TitleDiv} from "../../components/style/div/TitleDiv";
import {TitleText} from "../../components/style/text/TitleText";
import {TableTitleDiv} from "../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../components/style/text/TableTitleText";
import ResultText from "../../components/ResultText";


interface NoticeProps {
    memberId: string
    noticeArr: NoticeDto[]

    handlePost: (payload: NoticeDto) => void
    handlePut: (payload: NoticeDto) => void
    handleDelete: (uid: string) => void
}

function NoticeScreen(props: NoticeProps) {
    const [noticeArr, setNoticeArr] = useState<NoticeDto[]>([])
    const [showSave, setShowSave] = useState<boolean>(false)
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const [selectedIndex, setSelectedIndex] = useState<number>(-1)
    const [file, setFile] = useState<File>()
    const methods = useForm({
        mode: "onBlur"
    })
    const requiredValue = methods.watch(["title", "contents"])
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "제목",
                field: "title",
                width: 600,
            },
            {
                headerName: "등록일시",
                field: "updatedAt",
                valueFormatter: (params) => getFormattedContents(
                    params.value, "YYYY-MM-DD"
                ),
                width: 300
            },
            {
                headerName: "노출",
                field: "isRelease",
                valueFormatter: (params) => params.value ? "노출 중 ..." : "비활성화",
                width: 178,
            }
        ],

        onCellClicked(event: CellClickedEvent) {
            const url = event.data.imageURL

            if (url && url !== "") {
                createFile(url, url.substring(url.indexOf("__") + 2)).then(file => {
                    setFile(file)
                })
            } else {
                setFile(undefined)
            }
            methods.setValue("title", event.data.title)
            methods.setValue("contents", event.data.contents)
            methods.setValue("linkURL", event.data.linkURL)
            methods.setValue("isRelease", event.data.isRelease)
            setSelectedIndex(event.rowIndex)
        }
    })

    useEffect(() => {
        setNoticeArr(props.noticeArr)
    }, [props.noticeArr])

    useEffect(() => {
        setGridOptions({
            ...gridOptions,
            rowData: noticeArr
        })
    }, [noticeArr])

    function handleFile(file: File) {
        setFile(file)

        if (selectedIndex > -1) {
            setNoticeArr([
                ...noticeArr.slice(0, selectedIndex),
                {
                    ...noticeArr[selectedIndex],
                    imageURL: undefined
                },
                ...noticeArr.slice(selectedIndex + 1)
            ])
        }
    }

    function handleDropBtn() {
        setFile(undefined)

        if (selectedIndex > -1) {
            setNoticeArr([
                ...noticeArr.slice(0, selectedIndex),
                {
                    ...noticeArr[selectedIndex],
                    imageURL: undefined
                },
                ...noticeArr.slice(selectedIndex + 1)
            ])
        }
    }

    async function onSave() {
        const data = methods.getValues()
        const memberId = props.memberId

        await fileToBase64(file).then(base64Str => {
            if (selectedIndex === -1 || !noticeArr[selectedIndex].updatedAt) {
                props.handlePost({
                    updatedAt: undefined,
                    uid: undefined,
                    fileFullName: file?.name,
                    fileBase64: base64Str ? base64Str.substring(base64Str.indexOf(",") + 1) : undefined,
                    memberId: memberId,
                    title: data.title,
                    imageURL: undefined,
                    linkURL: data.linkURL,
                    contents: data.contents,
                    isRelease: data.isRelease
                })
            } else {
                props.handlePut({
                    updatedAt: noticeArr[selectedIndex].updatedAt,
                    uid: noticeArr[selectedIndex].uid,
                    fileFullName: file?.name,
                    fileBase64: base64Str ? base64Str.substring(base64Str.indexOf(",") + 1) : undefined,
                    memberId: memberId,
                    title: data.title,
                    imageURL: noticeArr[selectedIndex].imageURL,
                    linkURL: data.linkURL,
                    contents: data.contents,
                    isRelease: data.isRelease
                })
            }
        })
    }

    function onDelete() {
        if (selectedIndex >= 0) {
            props.handleDelete(noticeArr[selectedIndex].uid!!)
        }
    }

    function onCancel() {
        methods.setValue("title", "")
        methods.setValue("contents", "")
        methods.setValue("linkURL", "")
        methods.setValue("isRelease", "")
        setFile(undefined)

        setSelectedIndex(-1)
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(() => {
                })}>
                    <TitleDiv>
                        <TitleText>
                            공지 팝업
                        </TitleText>
                    </TitleDiv>

                    <RowDiv>
                        <ItemBoxDiv>
                            제목
                        </ItemBoxDiv>
                        <Searchbar
                            name={"title"}
                            placeholder={"제목 (필수 입력)"}
                            ref={methods.register({
                                required: true,
                            })}
                        />
                    </RowDiv>
                    <RowDiv>
                        <ItemBoxDiv>
                            이미지
                        </ItemBoxDiv>
                        <InputFile
                            name={"imageURL"}
                            handleFile={handleFile}
                            handleDropBtn={handleDropBtn}
                            dropImgSrc={DropImg}
                            file={file}
                        />
                    </RowDiv>
                    <RowDiv>
                        <ItemBoxDiv style={{height: "120px"}}>
                            내용
                        </ItemBoxDiv>
                        <ContentsTextArea
                            name={"contents"}
                            placeholder={"내용 (필수 입력)"}
                            ref={methods.register({
                                required: true,
                            })}
                        />
                    </RowDiv>
                    <RowDiv>
                        <ItemBoxDiv>
                            연결 URL
                        </ItemBoxDiv>
                        <Searchbar
                            name={"linkURL"}
                            placeholder={"검색어를 입력하세요"}
                            ref={methods.register}
                        />
                    </RowDiv>

                    <RowDiv>
                        <ItemBoxDiv>
                            노출
                        </ItemBoxDiv>
                        <VisibleToggle
                            name={"isRelease"}
                            reference={methods.register}
                        />
                    </RowDiv>

                    <SearchRow>
                        <NoticeButton onClick={onCancel} type={"submit"}
                                      disabled={selectedIndex < 0}>
                            취소
                        </NoticeButton>
                        <NoticeButton onClick={() => setShowDelete(true)} type={"submit"}
                                      disabled={requiredValue.title === "" || requiredValue.contents === "" || selectedIndex < 0}>
                            삭제
                        </NoticeButton>
                        <NoticeButton onClick={() => setShowSave(true)} type={"submit"}
                                      disabled={requiredValue.title === "" || requiredValue.contents === ""}>
                            저장
                        </NoticeButton>
                    </SearchRow>

                </form>
            </FormProvider>

            <TableTitleDiv>
                <TableTitleText>
                    공지 리스트
                </TableTitleText>
            </TableTitleDiv>

            <ResultText count={noticeArr.length} />

            <ErpTable
                tableWidth={1080}
                tableHeight={450}
                gridOptions={gridOptions}
            />

            <SimpleModal
                headerContentText="공지 팝업"
                subText="해당 공지를 저장하시겠습니까?"
                submitText="저장"
                buttonColor="var(--blue)"
                showModal={showSave}
                onConfirm={onSave}
                onCloseModal={() => setShowSave(false)}
            />
            <SimpleModal
                headerContentText="공지 팝업"
                subText="해당 공지를 삭제하시겠습니까?"
                submitText="삭제"
                buttonColor="var(--blue)"
                showModal={showDelete}
                onConfirm={onDelete}
                onCloseModal={() => setShowDelete(false)}
            />
        </>
    );
}

const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 992px;
  height: 32px;
  padding-left: 12px;
  margin-left: 8px;
  font-size: 10px;
`

const ContentsTextArea = styled(TextArea)`
  width: 992px;
  height: 110px;
  font-size: 10px;
`

const SearchRow = styled(RowDiv)`
  justify-content: flex-end;
`

const NoticeButton = styled(Button)`
  width: 43px;
  height: 32px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
  align-items: center;
`

export default NoticeScreen