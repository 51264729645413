import React from 'react';
import styled from "styled-components";
import FixedWeightText from "../text/FixedWeightText";
import {BaseFormType} from "../../form/BaseFormType";
import Input from "../input/Input";
import {PlaceholderInput} from "../input/PlaceholderInput";
import RedDot from "../../../pages/tank/regist/certificate/component/RedDot";

type ItemBoxComponentProps = BaseFormType & {
    width?: string
    height?: string
    title: string
    subTitle?: string
    placeholder?: string
    readOnly?: boolean
    value?: string
    reference?: any
    redDot?: boolean
    type?: string
}

function ItemBox(props: ItemBoxComponentProps) {
    return (
        <IBDiv>
            <Box>
                {
                    props.redDot == true ?
                        <RedDot /> : <></>
                }
                {props.title.length > 12 ?
                    (
                        <>
                            <Text>{props.title.substring(0, 12)}</Text>
                            <Text>{props.title.substring(12, props.title.length)}</Text>
                        </>
                    ) :
                    <Text>{props.title}</Text>
                }
                {props.subTitle && <SubText>{props.subTitle}</SubText>}
            </Box>
            {props.placeholder ?
                (<StyledPlaceholderInput placeholder={props.placeholder} ref={props.reference}/>)
                :
                (<StyledInput {...props} ref={props.reference}/>)
            }
        </IBDiv>
    );
}

const IBDiv = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
  justify-content: center;
  align-items: center;
`

const Text = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
`

const SubText = styled(Text)`
  font-size: 2px;
  margin-top: 3px;
`

const StyledInput = styled(Input)`
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

const StyledPlaceholderInput = styled(PlaceholderInput)`
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  margin-left: 8px;
`

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 120px;
  height: 64px;
  padding-left: 16px;
  padding-right: 20px;
  background-color: var(--pale-grey);
  align-items: center;
`

export default ItemBox;