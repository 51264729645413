import React from "react";


function TableNumberColumn(props: any) {

    return (
        <>
            {props.rowIndex + 1}
        </>
    )
}

export default TableNumberColumn