import React, {useState} from 'react'
import styled from 'styled-components'
import AddressWithButton from "./component/AddressWithButton"
import SignupModal from "./component/SignupModal"
import {FormProvider, useForm} from "react-hook-form"
import {
    NonRequiredRulesAndValidationEmail,
    NonRequiredRulesAndValidationTelNumber,
    RequiredRulesAndValidationPhoneNumber,
    RequiredRulesAndValidationTelNumber,
    RequiredRulesWithoutValidation
} from "../../components/form/BaseFormType"
import IdCheck from "./component/IdCheck"
import {ErrorInput} from "../../components/style/input/ErrorInput";
import FixedWeightText from "../../components/style/text/FixedWeightText";
import Input from "../../components/style/input/Input";
import Button from "../../components/style/button/Button";
import {SignUpRequest} from "../../api/signup/signUp";


interface SignupScreenProps {
    onConfirm: (payload: SignUpRequest) => void
    loading: boolean
}

type SignUpScreenData = SignUpRequest & {
    passwordConfirm?: string
}

function SignUpScreen(props: SignupScreenProps) {
    const methods = useForm<SignUpScreenData>({
        mode: "onBlur",
        criteriaMode: 'all',
    })

    const {watch} = methods
    const {password} = watch()
    const {isValid} = methods.formState

    const [request, setRequest] = useState<SignUpScreenData>()
    const [idChecked, setIdChecked] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const onSubmit = (data: SignUpScreenData) => {
        setRequest(data)
    };

    function handleIdDuplicateCheck(isDuplicate: boolean) {
        alert(isDuplicate ? "이미 사용중인 아이디 입니다." : "사용이 가능한 아이디 입니다.")
        setIdChecked(!isDuplicate)
    }

    function handleSubmit() {
        if (request != null) {
            props.onConfirm(request)
        }
    }


    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CenterDiv>
                    <HeaderText>
                        가스경영프로그램
                    </HeaderText>

                    <AccountInfoDiv className={"somename"}>
                        <FixedWeightText fontSize={"24px"} fontWeight={500}>계정 정보 입력</FixedWeightText>

                        <IdCheck onIdCheckComplete={handleIdDuplicateCheck}/>
                        <GuideText>
                            3~10자 영문, 숫자를 조합해 만들어 주세요.
                        </GuideText>

                        <InputTitle>비밀번호</InputTitle>

                        <ErrorInputField
                            name="password"
                            reference={methods.register({
                                required: true,
                                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{4,16}$/
                            })}
                            type={"password"}
                            error={methods.errors.password?.type === "pattern"}
                            errorMessage={"비밀번호를 확인해주세요."}
                            placeholder={"비밀번호(4~16자리)"}
                        />

                        <GuideText>
                            4~16자 이하의 영문, 숫자, 특수문자를 조합해 만들어주세요.
                        </GuideText>

                        <InputTitle>비밀번호 확인</InputTitle>

                        <ErrorInputField
                            name="passwordConfirm"
                            reference={methods.register({
                                required: true,
                                validate: value => value === password
                            })}
                            type={"password"}
                            placeholder={"비밀번호 재입력"}
                            error={methods.errors.passwordConfirm?.type == "validate"}
                            errorMessage={"비밀번호가 일치하지 않습니다."}
                        />

                    </AccountInfoDiv>

                    <BasicInfoDiv>
                        <FixedWeightText fontSize={"24px"} fontWeight={500}>기본정보</FixedWeightText>

                        <InputTitle>회사번호</InputTitle>

                        <InputField
                            name="tel"
                            ref={methods.register(RequiredRulesAndValidationTelNumber)}
                            error={methods.errors.tel?.types?.minLength === true || methods.errors.tel?.types?.maxLength === true}
                        />

                        <GuideText error={methods.errors.tel?.types?.pattern === true}>
                            (-)을 제외하고 입력해주세요.
                        </GuideText>

                        <InputTitle>핸드폰</InputTitle>

                        <Mobile>
                            <NameAndBodyInputField
                                name="mobile[0].name"
                                ref={methods.register(RequiredRulesWithoutValidation)}
                                placeholder={"담당자명"}

                            />
                            <NameAndBodyInputField2
                                name="mobile[0].body"
                                ref={methods.register(RequiredRulesAndValidationPhoneNumber)}
                                error={
                                    methods.errors.mobile && methods.errors.mobile[0]?.body?.types?.minLength === true ||
                                    methods.errors.mobile && methods.errors.mobile[0]?.body?.types?.maxLength === true}
                            />
                        </Mobile>

                        <GuideText
                            error={methods.errors.mobile && methods.errors.mobile[0]?.body?.types?.pattern === true}>
                            (-)을 제외하고 입력해주세요.
                        </GuideText>

                        <InputTitle>팩스번호</InputTitle>

                        <InputField
                            name="fax"
                            ref={methods.register(NonRequiredRulesAndValidationTelNumber)}
                            error={methods.errors.fax?.types?.minLength === true || methods.errors.fax?.types?.maxLength === true}
                        />

                        <GuideText error={methods.errors.fax?.types?.pattern === true}>
                            (-)을 제외하고 입력해주세요.
                        </GuideText>

                        <InputTitle>이메일</InputTitle>

                        <Email>
                            <NameAndBodyInputField
                                name="email.name"
                                ref={methods.register}
                                placeholder={"담당자명"}
                            />
                            <NameAndBodyInputField2
                                name="email.value"
                                ref={methods.register(NonRequiredRulesAndValidationEmail)}
                                error={methods.errors.email?.value?.types?.pattern === true}
                            />
                        </Email>

                        <InputTitle>회사주소</InputTitle>

                        <AddressWithButton
                            name={"address"}
                        />

                        <AddressDetailInput
                            name={"addressDetail"}
                            ref={methods.register}
                            placeholder={"상세주소"}
                        />

                        <NextButton
                            onClick={() => setShowModal(true)}
                            type="submit"
                        >
                            <FixedWeightText fontSize={"14px"} color={"var(--white)"}>다음</FixedWeightText>
                        </NextButton>
                    </BasicInfoDiv>
                    <SignupModal showModal={showModal}
                                 onCloseModal={() => setShowModal(false)}
                                 onConfirm={handleSubmit}
                    />

                </CenterDiv>
            </form>
        </FormProvider>
    )
}

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const AccountInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: auto;
  border-radius: 4px;
  padding: 32px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 52px;
`

const BasicInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 484px;
  height: 1278px;
  padding: 32px;
  border-radius: 4px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
  margin-top: 48px;
  margin-bottom: 200px;
`

const HeaderText = styled(FixedWeightText).attrs({
    fontWeight: 600
})`
  font-size: 36px;
  margin-top: 64px;
`

const InputTitle = styled(FixedWeightText).attrs({
    fontSize: "14px",
    fontWeight: 500
})`
  margin-top: 24px;
  margin-bottom: 8px;
  height: 20px;
`

type GuideTextProps = {
    error?: boolean
}

const GuideText = styled(FixedWeightText).attrs({
    fontSize: "12px",
    fontWeight: "normal"
})<GuideTextProps>`
  margin-top: 8px;
  margin-left: 2px;
  height: 18px;
  color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const Mobile = styled.div`
  display: flex;
  justify-content: space-between;
`

const Email = styled(Mobile)``

type InputFieldProps = {
    error?: boolean
}

const ErrorInputFieldWithGuide = styled(Input).attrs((props: InputFieldProps) => ({
    focusBorderColor: (props.error && "var(--red)") || "var(--blue)"
}))<InputFieldProps>`
  border-color: ${(props) => (props.error && "var(--red)") || "var(--gray-4)"};
`

const InputField = styled(ErrorInputFieldWithGuide)`
  width: 420px;
  height: 48px;
`

const ErrorInputField = styled(ErrorInput)`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 420px;
  height: 48px;
`

const NameAndBodyInputField = styled(Input)`
  display: flex;
  width: 80px;
  height: 48px;
`

const NameAndBodyInputField2 = styled(ErrorInputFieldWithGuide)`
  display: flex;
  width: 332px;
  height: 48px;
`

const AddressDetailInput = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 420px;
  height: 48px;
  margin-top: 8px;
`

const NextButton = styled(Button)`
  width: 420px;
  height: 64px;
  margin-top: 24px;
  color: var(--white);
`
export default SignUpScreen