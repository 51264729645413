import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import {RowDiv} from "../../../components/style/div/RowDiv";
import {LINK_TANK_CHECK_DEFAULT, LINK_TANK_CHECK_REGISTRATION} from "../../../resources/LinkConstants";
import {CommonButton} from "../CommonButton";
import HorizontalSpace from "../../../components/HorizontalSpace";
import {CheckForm} from "../../../api/tank/check/getTankCheckForm";
import {TableTitleText} from "../../../components/style/text/TableTitleText";
import ResultText from "../../../components/ResultText";
import {SearchSelect} from "../../../components/style/select/SearchSelect";
import {Searchbar} from "../../../components/style/input/Searchbar";
import {SearchButton} from "../../../components/style/button/SearchButton";
import {TableTitleDiv} from "../../../components/style/div/TableTitleDiv";
import styled from "styled-components";


interface FormDataType {
    searchType: number
    searchQuery: string
}

interface CheckScreenProps {
    form?: CheckForm
    resultLength?: number
    getList: (...payload1: any[]) => void
    selectCount?: number
    download: () => void
}

function TankCheckScreen(props: CheckScreenProps) {

    const history = useHistory()
    const methods = useForm({
        mode: "onBlur"
    })

    function onSubmit(formData: FormDataType) {
        props.getList(formData.searchType, formData.searchQuery)
    }

    function goTankCheckDefaultPage() {
        history.push(LINK_TANK_CHECK_DEFAULT)
    }

    function goTankCheckRegistrationPage() {
        history.push(LINK_TANK_CHECK_REGISTRATION)
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        reference={methods.register}
                        defaultValue={1}
                    />
                    <Searchbar placeholder={"검색어를 입력하세요"} name={"searchQuery"} ref={methods.register}/>
                    <SearchButton type="submit">검색</SearchButton>
                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>검사 리스트</TableTitleText>
                    <RowDiv>
                        <TankCheckButton onClick={props.download}>
                            검사 다운로드
                        </TankCheckButton>
                        <TankCheckButton onClick={goTankCheckDefaultPage}>
                            기본값 설정
                        </TankCheckButton>
                        <TankCheckButton onClick={goTankCheckRegistrationPage}>
                            검사 등록
                        </TankCheckButton>
                    </RowDiv>
                </TableTitleDiv>

                <ResultText count={props.resultLength} selectedCount={props.selectCount}/>
            </form>
        </FormProvider>
    )
}

const TankCheckButton = styled(CommonButton)`
  margin-left: 10px;
  width: 91px;
`

export default TankCheckScreen