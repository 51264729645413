import axios from "axios";
import {BaseResponse} from "../BaseResponse";

export async function getNoticeArr() {
    const response = await axios.get<BaseResponse<NoticeDto[]>>(`/notice`)

    return response.data
}

export async function postNotice(noticeDto: NoticeDto) {
    const response = await axios.post<BaseResponse<string>>(`/notice`, noticeDto)

    return response.data
}

export async function putNotice(noticeDto: NoticeDto) {
    const response = await axios.put<BaseResponse<string>>(`/notice`, noticeDto)

    return response.data
}

export async function deleteNotice(uid: string) {
    const response = await axios.delete<BaseResponse<string>>(`/notice/${uid}`)

    return response.data
}

export interface NoticeDto {
    updatedAt: string | undefined | null,
    uid: string | undefined | null,
    fileFullName: string | undefined | null,
    fileBase64: string | undefined | null,
    memberId: string,
    title: string,
    imageURL: string | undefined | null,
    linkURL: string,
    contents: string,
    isRelease: boolean
}