import styled from "styled-components";
import Input from "./Input";

export const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: ${(props) => props.width || "402px"};
  //width: 402px;
  height: 32px;
  padding-left: 12px;
  margin-left: 8px;
  font-size: 10px;
`