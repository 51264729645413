import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {RowDiv} from "../../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import HorizontalSpace from "../../../components/HorizontalSpace";
import {TableTitleDiv} from "../../../components/style/div/TableTitleDiv";
import {TableTitleText} from "../../../components/style/text/TableTitleText";
import ResultText from "../../../components/ResultText";
import {SearchHalfDatePicker} from "../../../components/style/datepicker/SearchHalfDatePicker";
import Moment from "moment";
import {SearchButton} from "../../../components/style/button/SearchButton";
import styled from "styled-components";
import {SearchDateRange} from "./TankReleaseContainer";
import moment from "moment";

interface TankReleaseScreenProps {
    result?: number
    searchDateRanges: SearchDateRange[]
    getList: (startDate?: string, endDate?: string) => void
}

function TankReleaseScreen(props: TankReleaseScreenProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    function onYearSearchClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        // To determine if a type can be cast from "event.currentTarget.name" to "SearchYearName"
        const name = event.currentTarget.name
        const endDate = moment(methods.watch("endDate"), 'YYYY-MM-DD')

        switch (name) {
            case "threeMonths":
                methods.setValue("startDate", endDate.subtract(3, "months").format('YYYY-MM-DD'))
                break
            case "halfYear":
                methods.setValue("startDate", endDate.subtract(6, "months").format('YYYY-MM-DD'))
                break
            case "thisYear":
                methods.setValue("startDate", endDate.startOf('year').format('YYYY-MM-DD'))
                break
            case "lastYear":
                methods.setValue("startDate", endDate.subtract(1, "years").format('YYYY-MM-DD'))
                break
            default:
                methods.setValue("startDate", endDate.subtract(3, "months").format('YYYY-MM-DD'))
                break
        }
    }

    function onSubmit() {
        props.getList(methods.watch("startDate"), methods.watch("endDate"))
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <RowDiv>
                        <ItemBoxDiv>
                            접수일
                        </ItemBoxDiv>
                        <SearchHalfDatePicker
                            defaultValue={Moment().format('YYYY-MM-01')}
                            reference={methods.register}
                            name="startDate"
                        />
                        <DivRange>~</DivRange>
                        <SearchHalfDatePicker
                            defaultValue={Moment().format('YYYY-MM-DD')}
                            reference={methods.register}
                            name="endDate"
                        />

                        {
                            props.searchDateRanges.map((info, index) =>
                                <YearSearchButton name={info.name} onClick={onYearSearchClick} key={index}>
                                    {info.contents}
                                </YearSearchButton>)
                        }

                        <SearchButton type="submit">검색</SearchButton>
                    </RowDiv>

                    <HorizontalSpace/>

                    <TableTitleDiv>
                        <TableTitleText>출고 신청</TableTitleText>
                    </TableTitleDiv>

                    <ResultText count={props.result}/>
                </form>
            </FormProvider>
        </>
    )
}

export const DivRange = styled.div`
  font-size: 30px;
  margin-left: 8px;
`

const YearSearchButton = styled(SearchButton)`
  width: 60px;
  background-color: var(--white);
  color: var(--black);
  border: 1px solid var(--black);
  font-size: 10px;
`

export default TankReleaseScreen