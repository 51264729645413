import React, {useEffect} from 'react';
import LoginScreen from "./LoginScreen";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from "../../redux";
import {signInAsync} from "../../redux/account/signInModule";
import {LINK_MEMBER} from "../../resources/LinkConstants";
import {SignInRequest} from "../../api/signIn";


interface LoginComponentProps {

}

function LoginContainer(props: LoginComponentProps) {

    const {data, loading} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse);
    const dispatch = useDispatch();
    const history = useHistory();

    function onConfirm(request: SignInRequest) {
        dispatch(signInAsync.request(request));
    }

    useEffect(() => {
        if (data != null) {
            history.push(LINK_MEMBER)
        }
    })

    return (
        <>
            <LoginScreen onConfirmClick={onConfirm} loading={loading}/>
        </>
    )
}

export default LoginContainer