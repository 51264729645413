import styled from "styled-components";

const getFontWeight = (style: string | number | undefined) => {
    if (typeof style == "number") {
        return style - 100
    }

    switch (style) {
        default:
        case 'normal':
            return 300
        case 'regular':
            return 400
        case 'bold':
            return 500
    }
}

type FixedWeightTextProps = {
    textColor?: string
}

const FixedWeightText = styled.text<FixedWeightTextProps>`
  font-weight: ${(props) => getFontWeight(props.fontWeight)};
  font-size: ${(props) => props.fontSize || "14px"};
  color: ${(props) => props.textColor || props.color || "var(--black)"};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`

export default FixedWeightText