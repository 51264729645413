import React from "react";
import DefaultScreen from "./DefaultScreen";
import {useHistory} from "react-router-dom";
import {useAsync} from "../../../../hooks/useAsync";
import {LINK_TANK} from "../../../../resources/LinkConstants";
import {getTankCheckDefaultList, TankCheckDefault} from "../../../../api/tank/check/getTankCheckDefaultList";
import {putTankCheckDefaultList} from "../../../../api/tank/check/putTankCheckDefaultList";


interface InitValueContainerProps {

}

function DefaultContainer(props: InitValueContainerProps) {

    const {data: initResponse} = useAsync(getTankCheckDefaultList, undefined, true)
    const {execute: executePost} = useAsync(putTankCheckDefaultList)
    const history = useHistory();

    function handlePut(payload: TankCheckDefault[]) {
        executePost(payload).then(
            r => {
                history.push(LINK_TANK)
                alert("수정되었습니다.")
            }
        )
    }

    return (
        <>
            <DefaultScreen
                defaultArr={initResponse ? initResponse : []}
                handlePut={handlePut}
            />
        </>
    )
}

export default DefaultContainer