import React, {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {TankCheckDetailForm} from "../../../../api/tank/check/getTankCheckDetailForm";
import {TankCheckOCR} from "../../../../api/tank/check/postTankCheckOCR";
import RedDot from "../../regist/certificate/component/RedDot";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Input from "../../../../components/style/input/Input";
import Select from "../../../../components/style/select/Select";
import {TankCheck} from "../../../../api/tank/check/postTankCheckList";


interface TankCheckContentsScreenProps {
    form?: TankCheckDetailForm
    data?: TankCheckOCR | TankCheck
    index: number
    hidden: boolean
}

function TankCheckRegistrationPdfContentsScreen(props: TankCheckContentsScreenProps) {

    const {setValue, register} = useFormContext()

    useEffect(() => {
        setValue("items[" + props.index + "].manufacturerNumber", props.data?.manufacturerNumber)
        setValue("items[" + props.index + "].url", props.data?.url)
        setValue("items[" + props.index + "].volume", props.data?.volume)
    }, [props.data])

    return (
        <div hidden={props.hidden} style={{width: "400px", marginLeft: "10px"}} >
            <input name={`items[${props.index}].url`} ref={register} hidden={true}/>

            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>제조번호
                    </ItemBoxText>
                </ItemBoxDiv>
                <ListInput
                    name={"items[" + props.index + "].manufacturerNumber"}
                    ref={register}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>검사 종류
                    </ItemBoxText>
                </ItemBoxDiv>
                <ContentSelect
                    name={"items[" + props.index + "].testType"}
                    reference={register}
                    menuItems={props.form?.testType}
                    defaultValue={1}
                />
            </ListDiv>
            <ListDiv>
                <ItemBoxDiv>
                    <ItemBoxText>
                        <RedDot/>용량
                    </ItemBoxText>
                </ItemBoxDiv>
                <ContentSelect
                    name={"items[" + props.index + "].volume"}
                    reference={register}
                    menuItems={props.form?.volumeType}
                />
            </ListDiv>
        </div>
    )
}

export default TankCheckRegistrationPdfContentsScreen


const ListDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 395px;
`

const ItemBoxDiv = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 80px;
  height: 40px;
  padding-left: 12px;
  align-items: center;
  font-size: 10px;
  background-color: var(--gray-6);
`

const ItemBoxText = styled(FixedWeightText)`
  width: 65px;
  height: 20px;
  font-size: 10px;
  font-weight: 500;
  color: var(--black);
`

const ListInput = styled(Input)`
  display: flex;
  flex: 1;
  height: 32px;
  font-size: 10px;
  margin-left: 8px;
`

const ContentSelect = styled(Select).attrs({
    width: "307px",
    height: "32px",
})``
