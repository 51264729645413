import React from "react";
import TankCheckRegistrationScreen from "./TankCheckRegistrationScreen";
import {useAsync2} from "../../../../hooks/useAsync2";
import {postTankCheckList, TankCheck} from "../../../../api/tank/check/postTankCheckList";
import {useHistory, useParams} from "react-router-dom";
import {postTankCheckOCR} from "../../../../api/tank/check/postTankCheckOCR";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";
import {getTankCheckDetailForm} from "../../../../api/tank/check/getTankCheckDetailForm";
import {LINK_TANK} from "../../../../resources/LinkConstants";
import {putTankCheckList} from "../../../../api/tank/check/putTankCheckList";
import {getTankCheck} from "../../../../api/tank/check/getTankCheck";
import {MatchType} from "../../../../api/MatchType";
import {deleteTankCheck} from "../../../../api/tank/check/deleteTankCheck";


interface EditContainerProps {
    period: string
}

function TankCheckRegistrationContainer(props: EditContainerProps) {

    const {uid} = useParams<MatchType>()
    const history = useHistory()

    const {execute: post, status: postStatus} = useAsync2(postTankCheckList)

    const {data: form} = useAsync2(getTankCheckDetailForm, true)
    const {data: ocrData, execute: postOcr, status: ocrStatus} = useAsync2(postTankCheckOCR)
    const {execute: update, status: updateStatus} = useAsync2(putTankCheckList)
    const {data: editData} = useAsync2(getTankCheck, uid != undefined, [uid])
    const {execute: executeDelete} = useAsync2(deleteTankCheck, false)

    function handlePost(tankCheckList: TankCheck[]) {
        post(tankCheckList).then((r) => {
            if (r) {
                history.push(LINK_TANK)
            }
        })
    }

    function handleUpdate(tankCheckList: TankCheck[]) {
        update(tankCheckList).then(() => {
            history.push(LINK_TANK)
        })
    }

    function handleDelete(uid: string) {
        executeDelete(uid).then(() => {
            history.push(LINK_TANK)
        })
    }

    return (
        <>
            {(ocrStatus == 'loading' || postStatus == 'loading' || updateStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankCheckRegistrationScreen
                form={form}
                post={handlePost}
                put={handleUpdate}
                delete={handleDelete}
                postOcr={postOcr}
                tankCheckOcrDataList={ocrData}
                editData={editData}
            />
        </>
    )
}

export default TankCheckRegistrationContainer