import React, {useEffect, useState} from "react";
import {TankReleaseCertificate} from "../../../../../api/tank/release/getTankReleaseList";
import {useFormContext} from "react-hook-form";
import {GridApi} from "ag-grid-community";
import {useComponentWillMount} from "../../../../price/component/utiles";
import SimpleModal from "../../../../../components/modal/SimpleModal";
import styled from "styled-components";
import Button from "../../../../../components/style/button/Button";

function TankReleaseCertificateAction(props: any,
                                      handlePost: (tankReleaseCertificate: TankReleaseCertificate) => void,
                                      handlePut: (tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) => void,
                                      handleDelete: (tankReleaseCertificateId: string) => void) {
    const {getValues} = useFormContext()
    const gridApi: GridApi = props.api
    const [showPostModal, setShowPostModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // custom hook
    useComponentWillMount(() => {
        let editingCells = gridApi.getEditingCells()
        if (editingCells.length !== 0) {
            setDisabled(true)
        }
    })

    useEffect(() => {
        gridApi.addEventListener('rowEditingStarted', onRowEditingStarted)
        gridApi.addEventListener('rowEditingStopped', onRowEditingStopped)

        return () => {
            gridApi.removeEventListener('rowEditingStarted', onRowEditingStarted)
            gridApi.removeEventListener('rowEditingStopped', onRowEditingStopped)
        }
    }, [])

    function onRowEditingStarted(params: any) {
        if (props.node === params.node) {
            setEditing(true)
        } else {
            setDisabled(true)
        }
    }

    function onRowEditingStopped(params: any) {
        if (props.node === params.node) {
            if (isEmptyRow(params.data)) {
                setEditing(false)
            } else {
                setEditing(false)
            }
        } else {
            setDisabled(false)
        }
    }

    function startEditing() {
        gridApi.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId
        })
    }

    function deleteRow(force = false) {
        if (force) {
            props.api.applyTransactionAsync({remove: [props.data]});
            props.api.refreshCells({force: true});
        } else {
            setShowDeleteModal(true)
        }
    }

    function onConfirm(type: "post" | "edit" | "delete") {
        props.api.stopEditing(false)

        const tankReleaseCertificate: TankReleaseCertificate = props.data

        if (tankReleaseCertificate.serialNumber == undefined) {
            alert("시리얼 번호를 입력해 주세요.")
            return
        }

        switch (type) {
            case "post":
                handlePost(tankReleaseCertificate)
                break
            case "edit":
                if (tankReleaseCertificate.uid) {
                    handlePut(tankReleaseCertificate.uid, tankReleaseCertificate)
                }
                break
            case "delete":
                if (tankReleaseCertificate.uid) {
                    handleDelete(tankReleaseCertificate.uid)
                }
                break
            default:
                break
        }
    }

    function isEmptyRow(data: any) {
        let dataCopy = {...data}
        delete dataCopy.id
        return !Object.values(dataCopy).some(value => value)
    }

    return (
        <>
            {editing ?
                <>
                    <SearchButton
                        onClick={() => props.rowIndex == 0 ? setShowPostModal(true) : setShowEditModal(true)}
                        disabled={disabled}
                        style={{marginRight: "5px"}}
                    >
                        확인
                    </SearchButton>
                    <SearchButton
                        onClick={() => props.api.stopEditing(true)}
                        disabled={disabled}
                    >
                        취소
                    </SearchButton>
                </> :
                <>
                    <SearchButton
                        onClick={startEditing}
                        disabled={disabled}
                        style={{marginRight: "5px"}}
                    >
                        {props.rowIndex == 0 ? "작성" : "수정"}
                    </SearchButton>
                    {
                        props.rowIndex !== 0 &&
                        <SearchButton
                            onClick={() => deleteRow()}
                            disabled={disabled}
                        >
                            삭제
                        </SearchButton>
                    }
                </>
            }

            <SimpleModal
                headerContentText="합격증 설정"
                subText={"입력한 값을 저장하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showPostModal}
                onConfirm={() => onConfirm("post")}
                onCloseModal={() => setShowPostModal(false)}
            />
            <SimpleModal
                headerContentText="합격증 설정"
                subText={"입력한 값으로 수정하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showEditModal}
                onConfirm={() => onConfirm("edit")}
                onCloseModal={() => setShowEditModal(false)}
            />
            <SimpleModal
                headerContentText="합격증 설정"
                subText={"해당 값을 삭제하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showDeleteModal}
                onConfirm={() => onConfirm("delete")}
                onCloseModal={() => setShowDeleteModal(false)}
            />
        </>
    )
}

const SearchButton = styled(Button)`
  background-color: var(--blue);
  color: var(--white);
`

export default TankReleaseCertificateAction