import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {
    TankAllInOneDrawingDto,
    TankAllInOneTestDto,
    TankDrawingDto,
    TankPressureTestDto, TankVolumeTableDto
} from "../check/getTankCheckDefaultList";
import {TankCertificate} from "./postCertificate";

export async function getTankList(
    searchType: number = 1,
    searchQuery: string = ""
) {
    const response = await axios.get<BaseResponse<Array<Tank>>>(`/tank`, {
        params: {
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}

export interface TankCoverDto {
    uid: string
    parentsId: string
    url: string
}

export interface Tank {
    coverDto: TankCoverDto,
    volumeTableDto: TankVolumeTableDto,
    drawingDto: TankDrawingDto,
    certificateDto?: TankCertificate,
    pressureTestDto?: TankPressureTestDto,
    allInOneTestDto?: TankAllInOneTestDto,
    allInOneDrawingDto?: TankAllInOneDrawingDto

    [key: string]: any
}