import axios from 'axios';
import {BaseResponse} from "../BaseResponse";


export async function getMemberList(
    dateType: number = 0,
    startDate: string = "1990-01-01",
    endDate: string = "1990-01-01",
    searchType: number = 0,
    searchQuery: string = ""
) {

    const response = await axios.get<BaseResponse<Member[]>>(`/members`, {
        params: {
            dateType: dateType,
            startDate: startDate,
            endDate: endDate,
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}

export async function getMember(userId: string) {
    const response = await axios.get<BaseResponse<Member>>(`/members/${userId}`)

    return response.data
}

export async function getMemberForm() {
    const response = await axios.get<BaseResponse<MemberForm>>(`/members/form`)

    return response.data
}

export async function putMember(memberType: Member) {
    const response = await axios.put<BaseResponse<boolean>>("/members", memberType)

    return response.data
}

export async function deleteMember(userId: string) {
    const response = await axios.delete<BaseResponse<string>>(`/members/${userId}`)

    return response.data
}

export interface MemberFormType {
    id: number
    title: string
}

export interface MemberForm {
    memberDateType: MemberFormType[]
    memberSearchType: MemberFormType[]
    memberEmailDomainType: MemberFormType[]
    memberTelLocalType: MemberFormType[]
    memberPhoneCarrierType: MemberFormType[]
}

export interface MemberTel {
    localNumber: number
    number1: string
    number2: string
}

export interface MemberPhoneNumber {
    name: string
    carrier: number
    number1: string
    number2: string
}

export interface MemberEmail {
    name: string
    email: string
    domain: string
}

export interface Member {
    uid?: string,
    createdAt: string,
    userId: string,
    password?: string,
    companyName: string,
    businessNumber: string,
    ownerName: string,
    tel: MemberTel,
    mobile1: MemberPhoneNumber,
    mobile2?: MemberPhoneNumber,
    mobile3?: MemberPhoneNumber,
    fax?: string,
    email?: MemberEmail,
    address: string,
    addressDetail?: string,
    deliveryAddress: string,
    deliveryAddressDetail?: string,
    companyAuthNo: string,
    acceptDate?: string
    isCmsUsed: boolean,
    memo1?: string,
    memo2?: string,
    state: number

    [key: string]: any
}