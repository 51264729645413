import React from "react";
import {
    getMemberForm,
    getMemberList,
    Member,
    putMember
} from "../../../api/member/Member";
import MemberPendingScreen from "./MemberPendingScreen";
import {useHistory} from "react-router-dom";
import {useAsync2} from "../../../hooks/useAsync2";


interface PendingContainerProps {

}

function MemberPendingContainer(props: PendingContainerProps) {

    const history = useHistory()
    const {data: form} = useAsync2(getMemberForm, true)
    const {execute: executePut} = useAsync2(putMember)
    const {data: memberList, execute: executeSearch} = useAsync2(getMemberList, true)

    function handlePut(memberType: Member) {
        executePut(memberType).then(
            () => {
                alert("수정되었습니다.")
                history.go(0)
            }
        )
    }

    function getOnlyPendingMember(memberArr: Member[] | undefined): Member[] {
        if (!memberArr) return []

        return memberArr.filter(member => member.state == 0)
    }

    return (
        <MemberPendingScreen
            memberArr={getOnlyPendingMember(memberList)}
            handleGet={executeSearch}
            handlePut={handlePut}
            form={form}
        />
    )
}

export default MemberPendingContainer