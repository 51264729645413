import React from "react";
import styled from 'styled-components';
import Title from "../../title/Title";
import {LINK_TANK} from "../../../resources/LinkConstants";
import {Link} from "react-router-dom";

interface ComponentProps {

}

function Header(props: ComponentProps) {
    return (
        <Div>
            <Link to={LINK_TANK} style={{textDecoration: 'none'}}>
                <Title/>
            </Link>
        </Div>
    );
}

const Div = styled.div`
  display: flex;
  background-image: linear-gradient(to top, #1450a5, #14469b);
  height: 64px;
  align-content: center;
  align-items: center;
  justify-content: center;
`
export default Header;