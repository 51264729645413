export function fileToBase64(file: File | undefined) {
    return new Promise((resolve: (value: string | null) => void, reject) => {
        if (!file) {
            resolve(null)
        } else {
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => {
                const result = reader.result

                if (result !== null && typeof result == "object") {
                    resolve(new TextDecoder().decode(result))
                } else {
                    resolve(result)
                }
            }

            reader.onerror = (err) => {
                reject(err)
            }
        }
    })
}