import React from 'react';
import MemberInfoScreen from "./MemberInfoScreen";
import {
    deleteMember,
    getMember,
    getMemberForm, Member,
    putMember
} from "../../../../api/member/Member";
import {useHistory} from "react-router-dom";
import {LINK_MEMBER} from "../../../../resources/LinkConstants";
import {useAsync2} from "../../../../hooks/useAsync2";


interface MemberInfoContainerProps {
}

function MemberInfoContainer(props: MemberInfoContainerProps) {
    const history = useHistory()
    const pathName = window.location.pathname
    const userId = pathName.substring(
        pathName.lastIndexOf("/") + 1
    )

    const {data: form} = useAsync2(getMemberForm, true)
    const {data: member} = useAsync2(getMember, userId != undefined, [userId])
    const {execute: executePut} = useAsync2(putMember)
    const {execute: executeDelete} = useAsync2(deleteMember)

    function handleEdit(member: Member) {
        executePut(member).then(r => {
            if (r) {
                alert("수정되었습니다.")
                history.push(LINK_MEMBER)
            }
        })
    }

    function handleDelete(userId: string) {
        executeDelete(userId).then(
            () => {
                history.push(LINK_MEMBER)
                alert("삭제되었습니다.")
            }
        )
    }

    return (
        <MemberInfoScreen
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            member={member}
            form={form}
        />
    );
}

export default MemberInfoContainer