import axios from "axios";
import Moment from 'moment';
import {BaseResponse} from "../../BaseResponse";
import {TankCertificate} from "./postCertificate";

export async function getCertificateList(
    dateType: number = 1,
    startDate: string = Moment().format("2026-01-01"),
    searchType: number = 1,
    searchQuery: string = "") {

    const response = await axios.get<BaseResponse<Array<TankCertificate>>>(`/tank/certificate`, {
        params: {
            dateType: dateType,
            startDate: startDate,
            searchType: searchType,
            searchQuery: searchQuery
        }
    })

    return response.data
}
