import React, {useEffect, useState} from "react";
import TankCheckScreen from "./TankCheckScreen";
import {
    getTankCheckList,
    TankAllInOneTestWithTestType,
    TankPressureTestWithTestType
} from "../../../api/tank/check/getTankCheckList";
import TankCheckListTable from "./TankCheckListTable";
import {useAsync2} from "../../../hooks/useAsync2";
import {getTankCheckForm} from "../../../api/tank/check/getTankCheckForm";
import {fileDownload, SingleFileDownload, TankInfo} from "../../../lib/fileDownload";


type TankCheckTableData = (TankPressureTestWithTestType | TankAllInOneTestWithTestType)[] | undefined

interface CheckContainerProps {

}

function TankCheckContainer(props: CheckContainerProps) {

    const [tankInfo, setTankInfo] = useState<Array<TankInfo>>([])
    const [tableData, setTableData] = useState<TankCheckTableData>([])
    const [resultLength, setResultLength] = useState<number>(0)

    const {data: data, execute: getList} = useAsync2(getTankCheckList, true)
    const {data: form} = useAsync2(getTankCheckForm, true)

    useEffect(() => {
        if (data) {
            setResultLength(data.adminTankPressureTestDtoList.length + data.adminTankAllInOneTestDtoList.length)

            const temp: TankCheckTableData = []

            for (let i = 0; i < data.adminTankAllInOneTestDtoList.length; i++) {
                temp.push({
                    ...data.adminTankAllInOneTestDtoList[i],
                    testType: "일체형검사"
                })
            }

            for (let i = 0; i < data.adminTankPressureTestDtoList.length; i++) {
                temp.push({
                    ...data.adminTankPressureTestDtoList[i],
                    testType: "수압검사"
                })
            }

            setTableData(temp)
        }
    }, [data])

    function download() {
        if (tankInfo.length == 0) {
            return;
        }
        if (tankInfo.length == 1) {
            SingleFileDownload(tankInfo[0])
        } else {
            fileDownload("검사_합격증.zip", tankInfo)
        }

        setTankInfo([])
    }

    return (
        <>
            <TankCheckScreen
                form={form}
                resultLength={resultLength}
                getList={getList}
                selectCount={tankInfo?.length}
                download={download}
            />
            <TankCheckListTable
                data={tableData}
                setTankInfo={setTankInfo}
            />
        </>
    )
}

export default TankCheckContainer