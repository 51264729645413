import axios from "axios";
import {BaseResponse} from "../BaseResponse";

export async function getSmsForm() {
    const response = await axios.get<BaseResponse<SmsForm>>("/sms/form")

    return response.data
}

export interface SmsForm {
    searchType: Array<SmsTypeItem>
}

interface SmsTypeItem {
    id: number
    title: string
}
