import styled from "styled-components";
import Button from "./Button";

export const SearchButton = styled(Button)`
  width: 43px;
  height: 32px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
`