import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function deleteTankCheck(uid: string) {
    const response = await axios.delete<BaseResponse<string>>(`tank/check`, {
        params: {
            uid: uid
        }
    })

    return response.data
}