export const LINK_HOME = "/"
export const LINK_BASIC = "/basic"

/* 회원가입 + 로그인 */
export const LINK_SIGN_UP = "/signUp"
export const LINK_SIGN_IN = "/signIn"

/* 회원 관리*/
export const LINK_MEMBER = "/member"

/* 탱크관리 */
/* 탱크관리 > 합격증 리스트 */
export const LINK_TANK = "/tank"
export const LINK_TANK_DOWN = "/tank/download"
export const LINK_TANK_REGISTRATION = "/tank_registration"
export const LINK_TANK_MODIFICATION = "/tank_registration/:uid"
/* 탱크관리 > 검사 */
export const LINK_TANK_CHECK = "/tank/check"
export const LINK_TANK_CHECK_DEFAULT = "/tank/check/default"
export const LINK_TANK_CHECK_REGISTRATION = "/tank/check/registration"
export const LINK_TANK_CHECK_MODIFICATION = "/tank/check/registration/:uid"
/* 탱크관리 > 재검사 */
export const LINK_RETEST = "/tank/retest"
export const LINK_RETEST_REGISTRATION = "/tank/retest/registration"
export const LINK_RETEST_MODIFICATION = "/tank/retest/registration/:uid"

/* SMS 전송 */
export const LINK_SMS = "/sms"
export const LINK_SMS_REGISTRATION = "/sms/registration"
/* 가격 설정 */
export const LINK_PRICE = "/price"
/* 공지 팝업 */
export const LINK_NOTICE = "/notice"
