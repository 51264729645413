import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect} from "react";
import {FormControl, MenuItem, SvgIcon, SvgIconProps} from "@material-ui/core";
import MaterialSelect from "@material-ui/core/Select"
import {Controller, useFormContext} from "react-hook-form";
import {BaseFormType} from "../../form/BaseFormType";

export const useStringItemSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            minWidth: '120px',
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'transparent',
                '& fieldset': {
                    borderColor: 'var(--gray-4)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--blue)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--blue)',
                },
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingRight: '42px'
            },
            "& .MuiMenu-paper": {
                minWidth: '120px'
            }
        },
        iconStyle: {
            position: 'absolute',
            top: '0.5',
            right: '0',
            display: 'inline-block',
            padding: '12px',
            pointerEvents: 'none'
        },
        autoComplete: {
            '& .MuiAutocomplete-inputRoot': {
                padding: "6px 0 6px 12px",
                height: "36px",
                fontSize: "10px",
                fontWeight: "normal"
            }
        }
    }),
);

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox={"0 0 24 24"} {...props}>
            <rect width="24" height="24" fill="#4191FF" rx="2"/>
            <path fill="#fff" fillRule="evenodd"
                  d="M6.293 10.707l1.414-1.414L12 13.586l4.293-4.293 1.414 1.414L12 16.414l-5.707-5.707z"
                  clipRule="evenodd"/>
        </SvgIcon>
    );
}

export type StringMenuItem = {
    title: string,
    value: string,
}

type SelectProps = BaseFormType & {
    menuItems?: Array<StringMenuItem>
    width?: string,
    height?: string,
    marginLeft?: string,
    initialMenuKey?: number
    reference?: any
    value?: string
    defaultValue?: string
    onChange?: () => void
    defaultText?: string
}

export default function StringItemSelect(props: SelectProps) {
    const {control} = useFormContext()
    const classes = useStringItemSelectStyles();

    useEffect(() => {
        if (props.name != null && props.value != null) {
            control.setValue(props.name, props.value)
        }
    }, [props.value, props.menuItems])

    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}>
            <Controller
                as={
                    <MaterialSelect
                        style={{
                            boxSizing: "border-box",
                            width: props.width || "fit-contents",
                            height: props.height || "fit-contents",
                            marginLeft: props.marginLeft,
                            fontSize: "10px",
                            fontWeight: "normal"
                        }}
                        displayEmpty
                        onChange={props.onChange}
                    >
                        <MenuItem value={0} disabled>{props.defaultText ? props.defaultText : "선택하세요"}</MenuItem>
                        {props.menuItems &&
                        props.menuItems.map((item, key) => <MenuItem key={key}
                                                                     value={item.value}>{item.title}</MenuItem>)}
                    </MaterialSelect>
                }
                name={props.name || "stringItemSelect"}
                rules={props.rules}
                control={control}
                defaultValue={0}
            />
        </FormControl>
    );
}