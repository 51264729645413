import React from 'react'
import MemberListScreen from "./MemberListScreen";
import {
    getMemberList,
    getMemberForm,
    Member
} from "../../../api/member/Member";
import {useAsync2} from "../../../hooks/useAsync2";


interface MemberListContainerProps {

}

function MemberListContainer(props: MemberListContainerProps) {

    const {data: memberList, execute: executeSearch} = useAsync2(getMemberList, true)
    const {data: form} = useAsync2(getMemberForm, true)

    function getOnlyAcceptedMemberArr(memberArr: Member[] | undefined): Member[] {

        if (!memberArr) return []

        return memberArr.filter(member => member.state == 1)
    }

    return (
        <>
            <MemberListScreen
                memberArr={getOnlyAcceptedMemberArr(memberList)}
                handleGet={executeSearch}
                form={form}
            />
        </>
    )
}

export default MemberListContainer