import React, {forwardRef, useImperativeHandle} from "react";
import {useFormContext} from "react-hook-form";
import styled from "styled-components";
import Select, {MenuItem} from "../../../../../components/style/select/Select";

export default forwardRef((props: any, ref) => {
    const {watch, register} = useFormContext()

    const fieldName: string = props.colDef.field
    const menuItems: Array<MenuItem> = props.menuItems
    const defaultItem = menuItems.find(item => item.title == props.value)

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                const item = menuItems.find(item => item.id == watch(fieldName))

                return item ? item.title : ""
            }
        }
    })

    return (
        <>
            <TableReleaseSelect
                menuItems={menuItems}
                name={fieldName}
                defaultValue={defaultItem ? defaultItem.id : 1}
                reference={register}
                width={`${props.colDef.width}px`}
                innerPaddingRight={"0"}
                innerPaddingLeft={"2px"}
                iconRight={"0"}
            />
        </>
    )
})

const TableReleaseSelect = styled(Select).attrs({
    height: "36px",
})``