import React, {useEffect, useState} from 'react';
import SectionTitle from "../../../../components/style/div/SectionTitle";
import {Member, MemberForm} from "../../../../api/member/Member";
import ErpTableComponent from "../../../../components/table/ErpTable";
import {GridOptions} from "ag-grid-community";
import {getPhoneNumber} from "../../../../lib/getManagerPhoneNumber";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";


interface CustomerAccountListProps {
    memberList?: Member[]
    setSelectedMemberList: React.Dispatch<React.SetStateAction<Member[]>>
    form?: MemberForm
}

function CustomerAccountList(props: CustomerAccountListProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        rowData: [],
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true
    })

    useEffect(() => {
        if (props.memberList && props.form) {

            setGridOptions({
                ...gridOptions,
                columnDefs: [
                    {
                        headerName: "회원사명",
                        field: "companyName",
                        width: 156,
                    },
                    {
                        headerName: "담당자명",
                        field: "mobile1.name",
                        width: 156,
                    },
                    {
                        headerName: "담당자 연락처",
                        field: "mobile1",
                        valueFormatter: (params) => {
                            return getPhoneNumber(params.value, props.form?.memberPhoneCarrierType)
                        },
                        width: 156,
                    },
                    {
                        colId: 'checkbox',
                        checkboxSelection: true,
                        headerCheckboxSelection: true,
                        width: 62,
                    }
                ],

                onRowSelected(event: RowSelectedEvent) {
                    const selectedMember = event.node.data
                    const isSelected = event.node.isSelected()

                    props.setSelectedMemberList(prevState => {
                        return isSelected ?
                            [...prevState, {...selectedMember}] :
                            prevState.filter(member => member.uid !== selectedMember.uid)
                    })
                },

                rowData: props.memberList,
            })
        }
    }, [props.memberList, props.form])

    return (
        <div>
            <SectionTitle>
                회원사 목록
            </SectionTitle>

            <ErpTableComponent
                tableWidth={532}
                tableHeight={396}
                gridOptions={gridOptions}
            />
        </div>
    )
}

export default CustomerAccountList