import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {LINK_TANK} from "../../../../resources/LinkConstants";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import Button from "../../../../components/style/button/Button";
import {TankCheck} from "../../../../api/tank/check/postTankCheckList";
import {TankCheckDetailForm} from "../../../../api/tank/check/getTankCheckDetailForm";
import CancelButton from "../../../../components/style/button/CancelButton";
import HorizontalSpace from "../../../../components/HorizontalSpace";
import SimpleModal from "../../../../components/modal/SimpleModal";
import TankCheckRegistrationPdfContentsScreen from "./TankCheckRegistrationPdfContentsScreen";
import {TankCheckOCR} from "../../../../api/tank/check/postTankCheckOCR";
import TankCheckRegistrationPdfScreen from "./TankCheckRegistrationPdfScreen";
import TankCheckRegistrationTable from "./TankCheckRegistrationTable";


interface EditScreenProps {
    form?: TankCheckDetailForm
    post: (tankCheckRegistrations: TankCheck[]) => void
    put: (tankCheckList: TankCheck[]) => void
    delete: (uid: string) => void
    postOcr: (files: FormData) => void
    tankCheckOcrDataList?: TankCheckOCR[]
    editData?: TankCheck
}

export interface TankCheckForm {
    items: Array<TankCheck>
}

function TankCheckRegistrationScreen(props: EditScreenProps) {

    const [typeString, setTypeString] = useState("")
    const [afterOcr, setAfterOcr] = useState<boolean>(false)
    const [showSave, setShowSave] = useState<boolean>(false)
    const [showDelete, setShowDelete] = useState<boolean>(false)
    const methods = useForm({
        mode: "onBlur",
    })

    // 업로드한 파일 전부
    const [fileList, setFileList] = useState<FileList>()

    // 선택된 파일의 인덱스 ( 무슨파일을 보고있는지 )
    const [fileIndex, setFileIndex] = useState(-1)

    // 파일 인풋을 custom 버튼으로 만들기 위한 ref
    const inputFile = useRef<HTMLInputElement>(null)

    // editData 가 있으면 ocr 을 안해도 ocr 데이터 보여주기
    useEffect(() => {
        setAfterOcr(!!props.editData)
        setTypeString(props.editData ? "수정" : "등록")
    }, [props.editData])

    function onSubmit(tankCheckForm: TankCheckForm) {
        if (afterOcr && tankCheckForm && tankCheckForm.items.length > 0 && props.form) {

            const tankCheckRegistrations: TankCheck[] = []

            tankCheckForm.items.forEach(item => {
                tankCheckRegistrations.push({
                    uid: props.editData?.uid,
                    manufacturerNumber: item.manufacturerNumber,
                    testType: item.testType,
                    volume: item.volume,
                    url: item.url
                })
            })

            props.editData ?
                props.put(tankCheckRegistrations) :
                props.post(tankCheckRegistrations)
        }
    }

    // file button click
    function handleClick() {
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    function setFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {

            if (event.target.files.length > 30) {
                alert("30개이하 파일만 선택됩니다.")
                return
            }

            setFileList(event.target.files)
            setFileIndex(0)
            setAfterOcr(false)
        }
    }

    // 검사 OCR 보내기
    function handleClickUpload() {
        if (fileList) {
            const formData = new FormData();
            Array.from(fileList).forEach(file => {
                formData.append('files', file, file.name);
            })
            setAfterOcr(true)
            props.postOcr?.(formData)
        }
    }

    function getSelectedFile(fileList: FileList | undefined, fileIndex: number): File | undefined {

        const fileListLength = fileList?.length

        if (fileListLength && fileIndex > -1 && fileListLength >= fileIndex) {
            return fileList!![fileIndex]
        }

        return undefined
    }

    function handleDeleteClick() {
        if (props.delete && props.editData?.uid) {
            props.delete(props.editData.uid)
        }
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <input
                    type={"file"}
                    name={"file"}
                    accept=".pdf"
                    ref={inputFile}
                    style={{display: 'none'}}
                    onChange={setFile}
                    multiple={!props.editData}
                />

                <HeaderDiv>
                    <FixedWeightText fontSize={"14px"} fontWeight={500}>
                        검사 {typeString}
                    </FixedWeightText>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <CancelButton marginRight={8} link={LINK_TANK}/>
                        {props.editData && <DeleteButton onClick={() => setShowDelete(true)}>삭제</DeleteButton>}
                        <ConfirmButton
                            onClick={() => setShowSave(true)}>{typeString}</ConfirmButton>
                    </div>
                </HeaderDiv>

                <SelectFileDiv>
                    <SelectFileButton onClick={handleClick}>파일선택</SelectFileButton>
                    <StyledButton onClick={handleClickUpload}>
                        <FixedWeightText color={"var(--white)"} fontSize={"10px"}
                                         fontWeight={500}>OCR</FixedWeightText>
                    </StyledButton>
                </SelectFileDiv>

                {!props.editData &&
                <WarningText>한번에 30개 까지만 업로드 가능합니다. OCR 변환 시, 1개에 4~5초 정도 시간이 소요됩니다.</WarningText>}

                <HorizontalDiv>
                    <HorizontalSpace height={"4px"}/>
                </HorizontalDiv>

                <ContentDiv>
                    <TankCheckRegistrationTable
                        rowData={fileList}
                        setFileIndex={setFileIndex}
                        editData={props.editData}
                    />
                    <TankCheckRegistrationPdfScreen
                        selectedFile={getSelectedFile(fileList, fileIndex)}
                        url={props.editData?.url}
                    />

                    {
                        afterOcr && !props.editData &&
                        props.tankCheckOcrDataList?.map((tankCheckOCR, idx) =>
                            <TankCheckRegistrationPdfContentsScreen
                                form={props.form}
                                data={tankCheckOCR}
                                hidden={idx !== fileIndex}
                                index={idx}
                                key={idx}
                            />)
                    }

                    {
                        afterOcr && props.editData &&
                        <TankCheckRegistrationPdfContentsScreen
                            form={props.form}
                            data={props.tankCheckOcrDataList ? props.tankCheckOcrDataList[0] : props.editData}
                            hidden={false}
                            index={0}
                        />
                    }
                </ContentDiv>

                <HorizontalDiv>
                    <HorizontalSpace height={"4px"}/>
                </HorizontalDiv>

                <SimpleModal
                    headerContentText={`검사 ${typeString}`}
                    subText={`검사를 ${typeString} 하시겠습니까?`}
                    submitText={typeString}
                    buttonColor="var(--blue)"
                    showModal={showSave}
                    onConfirm={methods.handleSubmit(onSubmit)}
                    onCloseModal={() => setShowSave(false)}
                />

                <SimpleModal
                    headerContentText={`검사 삭제`}
                    subText={`검사를 삭제 하시겠습니까?`}
                    submitText={"삭제"}
                    buttonColor="var(--blue)"
                    showModal={showDelete}
                    onConfirm={handleDeleteClick}
                    onCloseModal={() => setShowDelete(false)}
                />
            </form>
        </FormProvider>
    )
}

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 16px;
`

const SelectFileButton = styled(Button)`
  width: 120px;
  height: 32px;
  border: solid 2px rgba(0, 0, 0, 0.12);
  background-color: var(--pale-grey);
  font-size: 10px;
`

const SelectFileDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: flex-end;
`

const HorizontalDiv = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 26px;
`

const WarningText = styled(FixedWeightText)`
  font-size: 12px;
  text-align: left;
  color: var(--red);
`

const DeleteButton = styled(Button)`
  width: 43px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--gray-3);
  color: var(--white);
  margin-right: 8px;
`

export const StyledButton = styled(Button)`
  width: 61px;
  height: 32px;
  margin-left: 8px;
`

const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
`

const ConfirmButton = styled(Button)`
  width: 43px;
  color: var(--white);
`

export default TankCheckRegistrationScreen