import React, {useEffect, useState} from 'react';
import TankCertificateRegistrationScreen from "./TankCertificateRegistrationScreen";
import {postCertificate, TankCertificate} from "../../../../api/tank/certification/postCertificate";
import {getCertificateDetailForm} from "../../../../api/tank/certification/getCertificateDetailForm";
import {getCertificate} from "../../../../api/tank/certification/getCertificate";
import {useHistory, useParams} from "react-router-dom";
import {MatchType} from "../../../../api/MatchType";
import {putCertificate} from "../../../../api/tank/certification/putCertificate";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";
import {postOCR} from "../../../../api/tank/certification/postOCR";
import {useAsync2} from "../../../../hooks/useAsync2";
import {deleteCertificate} from "../../../../api/tank/certification/deleteCertificate";
import {getMemberList} from "../../../../api/member/Member";
import {getSalesAccountList} from "../../../../api/sales/account/getSalesAccountList";
import {LINK_TANK} from "../../../../resources/LinkConstants";

interface TankCertificationContainerComponentProps {
}


function TankCertificateRegistrationContainer(props: TankCertificationContainerComponentProps) {

    const history = useHistory();
    const {uid} = useParams<MatchType>()

    const {execute: post, status: postStatus} = useAsync2(postCertificate)
    const {data: form} = useAsync2(getCertificateDetailForm, true)
    const {data, execute: ocr, status: ocrStatus} = useAsync2(postOCR)
    const {execute: put, status: putStatus} = useAsync2(putCertificate)
    const {execute: deleteTank, status: deleteStatus} = useAsync2(deleteCertificate)
    const {data: getCertificateData} = useAsync2(getCertificate, uid != undefined, [uid])
    const {data: memberList, execute: executeMemberList} = useAsync2(getMemberList, true)
    const {data: salesAccountList, execute: executeSalesAccountList} = useAsync2(getSalesAccountList, false)

    const [ocrData, setOcrData] = useState<Array<TankCertificate>>()

    useEffect(() => {
        setOcrData(data)
    }, [data])

    function register(data: Array<TankCertificate>) {
        handleBack(post(data))
    }

    function update(data: TankCertificate, uid: string) {
        handleBack(put(data, uid))
    }

    function deleteCertification(uid: string) {
        handleBack(deleteTank(uid))
    }

    function handleBack(promise: Promise<any>) {
        promise.then(r => {
            if (r) {
                history.push(LINK_TANK)
            }
        })
    }

    return (
        <>
            {(ocrStatus == 'loading' || postStatus == 'loading' || putStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankCertificateRegistrationScreen
                post={register}
                put={update}
                delete={deleteCertification}
                form={form}
                postOcr={ocr}
                tankCertificateData={ocrData}
                updateData={getCertificateData}
                loading={postStatus == 'success' || putStatus == 'success' || deleteStatus == 'success'}
                memberList={memberList}
                getMemberList={executeMemberList}
                salesAccountList={salesAccountList}
                getSalesAccountList={executeSalesAccountList}
            />
        </>
    )
}

export default TankCertificateRegistrationContainer