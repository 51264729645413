import React from 'react';
import styled from 'styled-components';
import Button from './Button'

interface ComponentProps {
    index: number
    isSelected: boolean;
    text?: string;
    isActive?: boolean;
    click: (index: number) => void;
}

interface ButtonProps {
    isSelected: boolean
}

function NavButton(props: ComponentProps) {

    return (
        <NavButtonWithStyle onClick={() => props.click(props.index)}>
            <StyledButton isSelected={props.isSelected}>
                {props.text}
            </StyledButton>
        </NavButtonWithStyle>
    );
}

const NavButtonWithStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 64px;
  border: none;
  background-image: linear-gradient(to top, #ffffff, #f5f5f5);
`

const StyledButton = styled(Button)`
  width: 90px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${(props: ButtonProps) => props.isSelected ? "white" : "black"};
  background-color: ${(props: ButtonProps) => props.isSelected ? "#1450a5" : "transparent"};
  pointer-events: none;
`

export default NavButton;