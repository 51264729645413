import React, {useEffect, useState} from "react";
import {ColumnApi, GridApi, GridReadyEvent} from "ag-grid-community";
import {getFormattedContents} from "../../../components/format/Formatter";
import GridComponents from "../index";
import {GasPriceForm, GasPriceTrend} from "../../../api/price/Price";
import {AgGridReact} from "ag-grid-react";


interface PriceTableProps {
    form?: GasPriceForm
    tableData: GasPriceTrend[]
    handlePost: (payload: GasPriceTrend) => void
    handlePut: (payload: GasPriceTrend) => void
    handleDelete: (uid: string) => void
}

function PriceTable(props: PriceTableProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    useEffect(() => {
        if (gridApi && props.tableData) {
            gridApi.setRowData(props.tableData)
        }
    }, [gridApi, props.tableData]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    return (
        props.form ? (
            <div className="ag-theme-alpine" style={{height: 450, width: 1080}}>
                <AgGridReact
                    scrollbarWidth={4}
                    onGridReady={onGridReady}
                    gridOptions={
                        {
                            columnDefs: [
                                {
                                    headerName: "일시",
                                    field: "yearMonth",
                                    cellEditor: "dateEditor",
                                    valueGetter: (params) => getFormattedContents(params.data.yearMonth, "YYYY-MM"),
                                    width: 137
                                },
                                ...props.form.companyType.map((companyType, outer) => ({
                                    headerName: companyType.title,
                                    children: [
                                        ...props.form!!.energyType.map((energyType) => ({
                                            headerName: energyType.title,
                                            field: `data.${outer}.${energyType.value}`,
                                            cellEditor: "inputEditor",
                                            width: 70
                                        }))
                                    ]
                                })),
                                {
                                    headerName: "관리",
                                    colId: "actions",
                                    cellRenderer: "actionsRenderer",
                                    editable: false,
                                    filter: false,
                                    width: 100
                                }
                            ],

                            defaultColDef: {
                                editable: true,
                                suppressKeyboardEvent: params => params.editing
                            },

                            frameworkComponents: {
                                dateEditor: GridComponents.DateEditor,
                                inputEditor: GridComponents.SimpleEditor,
                                actionsRenderer: (data: any) => GridComponents.ActionsRenderer(data, props.handlePost, props.handlePut, props.handleDelete),
                            },

                            // 한 칸 단위로 수정하는 것이 아니라
                            // 한 줄 단위로 수정 값 적용
                            editType: "fullRow",

                            // 가로 스크롤바 없애기
                            suppressHorizontalScroll: true,

                            suppressClickEdit: true,

                            rowData: props.tableData,
                        }
                    }
                />
            </div>
        ) : (
            <></>
        )
    )
}

export default PriceTable