import React from 'react';
import SmsScreen from "./SmsScreen";
import {getSmsList, Sms, SmsPayload} from "../../api/sms/getSmsList";
import {Pageable} from "../../api/pageable";
import {useAsync2} from "../../hooks/useAsync2";
import {getSmsForm} from "../../api/sms/getSmsForm";


interface SmsContainerProps {

}

function SmsContainer(props: SmsContainerProps) {

    const {execute: executeSmsList} = useAsync2(getSmsList, false)
    const {data: form} = useAsync2(getSmsForm, true)

    function getNextSmsList(smsPayload: SmsPayload): Promise<Pageable<Sms> | undefined> {
        return executeSmsList(smsPayload).then(
            r => {
                return r
            }
        )
    }

    return (
        <>
            <SmsScreen
                form={form}
                getNextSmsList={getNextSmsList}
            />
        </>
    )
}

export default SmsContainer