import React, {useEffect, useState} from "react";
import {TankRelease, TankReleaseCertificate} from "../../../../api/tank/release/getTankReleaseList";
import {ColumnApi, GridApi, GridReadyEvent, ICellRendererParams} from "ag-grid-community";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import ModalHeader from "../../../../components/modal/Header";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import styled from "styled-components";
import {RowDiv} from "../../../../components/style/div/RowDiv";
import Button from "../../../../components/style/button/Button";
import {AgGridReact} from "ag-grid-react";
import {useForm, FormProvider} from "react-hook-form";
import TankReleaseIndex from "../TankReleaseIndex";
import TankReleaseCertificateRegistration from "./TankReleaseCertificateRegistration";

interface TankReleaseModalProps {
    showModal: boolean
    onCloseModal: () => void
    data?: TankRelease
    post: (tankReleaseId: string, tankReleaseCertificate: TankReleaseCertificate) => void,
    put: (tankReleaseId: string, tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) => void,
    delete: (tankReleaseId: string, tankReleaseCertificateId: string) => void
}

function TankReleaseModal(props: TankReleaseModalProps) {
    const [tankReleaseCertificates, setTankReleaseCertificates] = useState<TankReleaseCertificate[]>([])
    const [gridApi, setGridApi] = useState<GridApi>()
    const [, setGridColumnApi] = useState<ColumnApi>()
    const methods = useForm({
        mode: "onBlur"
    })

    useEffect(() => {
        if (props.data) {
            setTankReleaseCertificates([
                {
                    uid: undefined,
                    serialNumber: "",
                    isRegistration: false
                },
                ...props.data.adminTankReleaseCertificateDtoList
            ])
        }
    }, [props.data])

    useEffect(() => {
        if (gridApi && tankReleaseCertificates.length > 0) {
            gridApi.setRowData(tankReleaseCertificates)
        }
    }, [gridApi, tankReleaseCertificates])

    function postTankCertificate(tankReleaseCertificate: TankReleaseCertificate) {
        if (props.data && props.data.uid) {
            props.post(
                props.data.uid,
                tankReleaseCertificate
            )
        }
    }

    function putTankCertificate(tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) {
        if (props.data && props.data.uid) {
            props.put(
                props.data.uid,
                tankReleaseCertificateId,
                tankReleaseCertificate
            )
        }
    }

    function deleteTankCertificate(tankReleaseCertificateId: string) {
        if (props.data && props.data.uid) {
            props.delete(
                props.data.uid,
                tankReleaseCertificateId
            )
        }
    }

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    function closeParents() {
        props.onCloseModal()
    }

    return (
        <FormProvider {...methods}>
            <form>
                <ConfirmModal showModal={props.showModal} onCancel={closeParents}>
                    <ModalDiv>
                        <ModalHeader title={"합격증 등록"} onCancel={closeParents}/>
                        <ContentDiv>
                            <TableDiv>{tankReleaseCertificates.length > 0 ?
                                <div className="ag-theme-alpine" style={{width: 602, height: 650}}>
                                    <AgGridReact
                                        scrollbarWidth={4}
                                        onGridReady={onGridReady}
                                        gridOptions={{
                                            columnDefs: [
                                                {
                                                    headerName: "시리얼 번호",
                                                    field: "serialNumber",
                                                    cellEditor: "input",
                                                    editable: true,
                                                    width: 150
                                                },
                                                {
                                                    headerName: "등록여부",
                                                    field: "isRegistration",
                                                    valueFormatter: (params => params.value ? "등록" : "미등록"),
                                                    editable: false,
                                                    width: 150
                                                },
                                                {
                                                    headerName: "등록",
                                                    cellRendererFramework: (iCellRendererParams: ICellRendererParams) => TankReleaseCertificateRegistration({
                                                        iCellRendererParams: iCellRendererParams,
                                                        post: props.post,
                                                        putTankCertificate: putTankCertificate
                                                    }),
                                                    editable: false,
                                                    width: 150
                                                },
                                                {
                                                    headerName: "기능",
                                                    colId: "actions",
                                                    cellRenderer: "actionsRenderer",
                                                    editable: false,
                                                    filter: false,
                                                    width: 150
                                                },
                                            ],

                                            defaultColDef: {
                                                editable: true,
                                                suppressKeyboardEvent: params => params.editing
                                            },

                                            frameworkComponents: {
                                                input: TankReleaseIndex.TankReleaseInputEditor,
                                                actionsRenderer: (data: any) => TankReleaseIndex.TankReleaseCertificateAction(data, postTankCertificate, putTankCertificate, deleteTankCertificate)
                                            },

                                            editType: "fullRow",

                                            // 가로 스크롤바 없애기
                                            suppressHorizontalScroll: true,

                                            suppressClickEdit: true,

                                            rowData: tankReleaseCertificates,
                                        }}/>
                                </div> : <></>
                            }
                            </TableDiv>

                            <ButtonDiv>
                                <SubmitButton onClick={closeParents}>
                                    <FixedWeightText color={"var(--blue)"} fontSize={"14px"}
                                                     fontWeight={500}>닫기</FixedWeightText>
                                </SubmitButton>
                            </ButtonDiv>

                        </ContentDiv>
                    </ModalDiv>
                </ConfirmModal>
            </form>
        </FormProvider>
    )
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 832px;
  border-radius: 4px;
  background-color: var(--white);
`

const ContentDiv = styled.div`
  display: flex;
  height: 710px;
  flex-direction: column;
  padding: 22px 32px 32px 32px;
`

const TableDiv = styled(RowDiv)`
  justify-content: center;
`

const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`

const SubmitButton = styled(Button)`
  width: 120px;
  height: 48px;
  margin-left: 8px;
  border: solid 1px var(--gray-4);
  background-color: var(--white);
`

export default TankReleaseModal