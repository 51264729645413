import React, {useEffect, useState} from "react";
import {MemberTableButton} from "../../../member/list/MemberListTableLinkButton";
import {ICellRendererParams} from "ag-grid-community";
import SimpleConfirmModal from "../../../../components/modal/SimpleConfirmModal";
import {TankReleaseCertificate} from "../../../../api/tank/release/getTankReleaseList";
import {useAsync2} from "../../../../hooks/useAsync2";
import {getCertificateByTankReleaseCertificate} from "../../../../api/tank/release/certificate/getCertificateByTankReleaseCertificate";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";

interface TankReleaseCertificateRegistrationProps {
    iCellRendererParams: ICellRendererParams
    post: (tankReleaseId: string, tankReleaseCertificate: TankReleaseCertificate) => void
    putTankCertificate: (tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) => void
}

function TankReleaseCertificateRegistration(props: TankReleaseCertificateRegistrationProps) {
    const {
        execute: getCertificate,
        status: getCertificateStatus
    } = useAsync2(getCertificateByTankReleaseCertificate, false)
    const tankReleaseCertificate: TankReleaseCertificate = props.iCellRendererParams.data
    const [showModal, setShowModal] = useState<boolean>(false)
    const [text, setText] = useState<string>()

    useEffect(() => {
        if (text) {
            setShowModal(true)
        }
    }, [text])

    function handleClick() {
        if (tankReleaseCertificate.serialNumber == null || tankReleaseCertificate.serialNumber == "") {
            alert("작성 완료 후 등록해주세요.")
            return
        }

        if (tankReleaseCertificate.isRegistration) {
            alert("이미 등록된 합격증입니다.")
            return
        }

        getCertificate(tankReleaseCertificate.uid).then(r => {
            if (r) {
                // companyName은 없고 memberId만 있는 합격증이 있음
                if (r.companyName) {
                    setText(`${r.companyName}에 등록된 합격증입니다. 합격증을 등록하시겠습니까?`)
                } else if (r.memberId) {
                    setText(`이미 등록된 합격증입니다. 합격증을 등록하시겠습니까?`)
                } else {
                    setText("합격증을 등록하시겠습니까?")
                }
            }
        })
    }

    function closeModal() {
        setShowModal(false)
    }

    function onConfirm() {
        if (tankReleaseCertificate.uid) {
            props.putTankCertificate(tankReleaseCertificate.uid, {
                uid: tankReleaseCertificate.uid,
                serialNumber: tankReleaseCertificate.serialNumber,
                isRegistration: true
            })
        }
    }

    return (
        <>
            {(getCertificateStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <MemberTableButton onClick={handleClick}>
                {props.iCellRendererParams.colDef.headerName}
            </MemberTableButton>

            <SimpleConfirmModal
                headerContentText={"합격증 매핑"}
                subText={text ? text : ""}
                submitText={"확인"}
                showModal={showModal}
                onCloseModal={closeModal}
                onConfirm={onConfirm}
            />
        </>
    )
}

export default TankReleaseCertificateRegistration