import React, {useEffect, useState} from 'react';
import {LINK_TANK_CHECK_REGISTRATION} from "../../../resources/LinkConstants";
import {GridOptions, ICellRendererParams} from "ag-grid-community";
import ErpTableComponent from "../../../components/table/ErpTable";
import {Link} from "react-router-dom";
import {TankAllInOneTestWithTestType, TankPressureTestWithTestType} from "../../../api/tank/check/getTankCheckList";
import {TankInfo} from "../../../lib/fileDownload";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";


interface CheckListTableProps {
    data: (TankPressureTestWithTestType | TankAllInOneTestWithTestType)[] | undefined
    setTankInfo: React.Dispatch<React.SetStateAction<TankInfo[]>>
}

function TankCheckListTable(props: CheckListTableProps) {
    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "제조번호",
                field: "manufacturerNumber",
                width: 340,
                cellRendererFramework: linkRenderer
            },
            {
                headerName: "검사종류",
                width: 340,
                valueFormatter: params => params.data.testType
            },
            {
                headerName: "제조국",
                width: 280,
                valueFormatter: params => params.data.countryOfProduction
            },
            {
                headerName: "",
                field: "selected",
                width: 64,
                checkboxSelection: true,
                headerCheckboxSelection: true
            },
        ],

        rowData: [],

        rowMultiSelectWithClick: true,

        onRowSelected(event: RowSelectedEvent) {
            const node = event.node

            if (node) {
                if (node.isSelected()) {
                    props.setTankInfo(prevState => [
                        ...prevState,
                        {
                            data: node.data.url,
                            name: node.data.manufacturerNumber
                        }
                    ])
                } else {
                    props.setTankInfo(prevState =>
                        prevState.filter(prev => prev.name !== node.data.manufacturerNumber)
                    )
                }
            }
        }
    })

    useEffect(() => {
        if (props.data) {
            setGridOptions({
                ...gridOptions,
                rowData: props.data
            })
        }
    }, [props.data])

    function linkRenderer(cellParams: ICellRendererParams) {
        return (
            <Link to={LINK_TANK_CHECK_REGISTRATION + `/${cellParams.data.uid}`}
                  style={{textDecoration: 'none', justifyContent: 'center'}}>
                {cellParams.data.manufacturerNumber}
            </Link>
        )
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                gridOptions={gridOptions}
                withNumber={true}
            />
        </>
    )
}

export default TankCheckListTable
