import React from 'react';
import MemberScreen from "./MemberScreen";


interface MemberProps {

}

function MemberContainer(props: MemberProps) {

    return (
        <div>
            <MemberScreen/>
        </div>
    )
}

export default MemberContainer