import JSZip from "jszip";
import FileSaver from "file-saver";

export type TankInfo = {
    data: string | Blob,
    name: string
}

export type TankInfoFolder = {
    name: string
    tankInfo: Array<TankInfo>
}

export async function SingleFileDownload(tankInfo: TankInfo) {
    try {
        const blob: Blob = typeof tankInfo.data == "string" ? await fetch(tankInfo.data).then(res => res.blob()) : tankInfo.data
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style.display = "none";
        a.download = tankInfo.name + ".pdf"
        document.body.append(a);
        a.click();
    } catch (err) {
        console.log(err)
    }
}

export function fileDownload(name: string, tankInfo: Array<TankInfo>) {

    let count = 0;
    const zip = new JSZip();
    const zipName = name.endsWith(".zip") ?
        name :
        name + ".zip"

    tankInfo.forEach(async function (item) {
        try {
            const blob: Blob = typeof item.data == "string" ? await fetch(item.data).then(res => res.blob()) : item.data
            const fileName = item.name.substring(item.name.length - 3, item.name.length) == "pdf" ? item.name : item.name + ".pdf"
            count++;
            zip.file(fileName, blob, {binary: true});

            if (count === tankInfo.length) {
                zip.generateAsync({type: 'blob'}).then(function (content) {
                    FileSaver.saveAs(content, zipName);
                });
            }
        } catch (err) {
            console.log(err);
        }
    })

}

export function fileDownloadWithFolder(tankInfoFolder: Array<TankInfoFolder>) {

    const zip = new JSZip();
    const zipFilename = "저장탱크 합격증.zip";
    let count = 0
    let total = 0

    tankInfoFolder.forEach(item => {
        total += item.tankInfo.length
    })

    tankInfoFolder.forEach(async (item) => {
        const folder = zip.folder(item.name)
        for (const tankInfo of item.tankInfo) {
            try {
                const blob: Blob = typeof tankInfo.data == "string" ? await fetch(tankInfo.data).then(res => res.blob()) : tankInfo.data
                const fileName = await tankInfo.name.substring(tankInfo.name.length - 3, tankInfo.name.length) == "pdf" ? tankInfo.name : tankInfo.name + ".pdf"
                folder?.file(fileName, blob, {binary: true})
                count++

                if (count == total) {
                    zip.generateAsync({type: 'blob'}).then(function (content) {
                        FileSaver.saveAs(content, zipFilename);
                    });
                }

            } catch (err) {
                console.log(err)
            }
        }
    })
}