import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import {pdfjs} from 'react-pdf'
import FixedWeightText from "../../../../components/style/text/FixedWeightText"
import CancelButton from "../../../../components/style/button/CancelButton"
import styled from "styled-components"
import {LINK_TANK} from "../../../../resources/LinkConstants"
import Input from "../../../../components/style/input/Input"
import Button from "../../../../components/style/button/Button"
import TopButton from "../../../../components/style/button/TopButton"
import {FormProvider, useForm} from "react-hook-form";
import {ExaminationHistory, TankCertificate} from "../../../../api/tank/certification/postCertificate";
import {CertificateDetailForm} from "../../../../api/tank/certification/getCertificateDetailForm";
import HorizontalSpace from "../../../../components/HorizontalSpace";
import TankExaminationHistoryTable from "./component/TankExaminationHistoryTable";
import TankCertificatePdfScreen from "./component/TankCertificatePdfScreen";
import TankCertificateContentScreen from "./component/TankCertificateContentScreen";
import {ItemBoxDiv} from "../../../../components/style/div/ItemBoxDiv";
import {Link} from "react-router-dom";
import moment from "moment";
import {Member} from "../../../../api/member/Member";
import {SalesAccount} from "../../../../api/sales/account/getSalesAccountList";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TankCertificationRegistrationScreenComponentProps {
    post?: (data: Array<TankCertificate>) => void
    put?: (data: TankCertificate, uid: string) => void
    delete?: (uid: string) => void
    form?: CertificateDetailForm
    isUpdate?: boolean
    tankCertificateData?: Array<TankCertificate>
    updateData?: TankCertificate
    postOcr?: (files: FormData) => void
    loading: boolean
    memberList?: Member[]
    getMemberList: (
        dateType?: number,
        startDate?: string,
        endDate?: string,
        searchType?: number,
        searchQuery?: string) => void
    salesAccountList?: SalesAccount[]
    getSalesAccountList: (
        memberId: string,
        code: string,
        accountName: string,
        address: string,
        phone: string
    ) => void
}

type TankCertificateList = {
    items: Array<TankCertificate>
}

function getCurrentDate(dateStr1: string, dateStr2: string): string {
    const date1 = moment(dateStr1, "YYYY-MM-DD")
    const date2 = moment(dateStr2, "YYYY-MM-DD")

    return date1.isAfter(date2) ? date1.format("YYYY-MM-DD") : date2.format("YYYY-MM-DD")
}

function getNextExaminationDate(nearDate: string): string {
    const nextExaminationDate = moment(nearDate, "YYYY-MM-DD")
    const now = moment().format("YYYY-MM-DD")

    while (true) {
        if (nextExaminationDate.isAfter(now)) {
            return nextExaminationDate.subtract(1, "days").format("YYYY-MM-DD")
        }
        nextExaminationDate.add(5, "years")
    }
}

function TankCertificateRegistrationScreen(props: TankCertificationRegistrationScreenComponentProps) {
    const methods = useForm<Array<TankCertificate>>({
        mode: "onBlur",
        criteriaMode: 'all'
    })
    const inputFile = useRef<HTMLInputElement>(null)

    const [selectedFile, setSelectedFile] = useState<FileList>()
    const [index, setIndex] = useState(0)
    const [files, setFiles] = useState<Array<Array<ExaminationHistory>>>(Array<Array<ExaminationHistory>>())
    const [fileList, setFileList] = useState<string>()

    function onSubmit(data: TankCertificateList) {
        if (files && data && data.items && files.length > 0 && data.items.length > 0) {
            for (let index = 0; index < data.items.length; index++) {

                if (data.items[index].certificateNumber == "") {
                    alert(`${index + 1}페이지 검사 증명서 번호를 확인해주세요`)
                    return
                }

                if (data.items[index].manufacturerNumber == "") {
                    alert(`${index + 1}페이지 제조번호를 확인해주세요.`)
                    return
                }

                if (data.items[index].finalTestDate == "") {
                    data.items[index].finalTestDate = data.items[index].passDate
                }

                data.items[index].history = []
                files[index].forEach(item => data.items[index].history.push(item))
            }

            if (props.updateData && props.put && data.items[0].uid) {
                props.put(data.items[0], data.items[0].uid)
            } else if (props.post) {
                data.items.forEach(item => {
                    item.nextExaminationDate = getNextExaminationDate(getCurrentDate(
                        item.passDate,
                        item.finalTestDate))
                })
                props.post(data.items)
            } else {
                return
            }
        } else {
            alert(`OCR 변환 후 등록해 주세요.`)
        }
    }

    function handleClick() {
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    function setFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            if (event.target.files.length > 100) {
                alert("100개이하 파일만 선택됩니다.")
                return
            }

            setSelectedFile(event.target.files)
            const fileList = Array<Array<ExaminationHistory>>()

            let fileNameList = "";

            for (let i = 0; i < event.target.files.length; i++) {
                fileList.push([])
                fileNameList += event.target.files[i].name + ", "
            }

            const fileName = fileNameList.slice(0, fileNameList.length - 2)
            setFiles(fileList)
            setFileList(fileName)
        }
    }

    function handleClickUpload() {
        if (selectedFile) {
            const formData = new FormData();
            Array.from(selectedFile).forEach(file => {
                formData.append('files', file, file.name);
            })
            props.postOcr?.(formData)
        }
    }

    function handleDeleteClick() {
        if (props.delete && props.updateData?.uid) {
            props.delete(props.updateData.uid)
        }
    }

    useEffect(() => {
        if (props.updateData?.history) {
            setFiles([props.updateData.history])
        }
    }, [props.tankCertificateData, index, props.updateData, selectedFile, props.loading])

    return (
        <>
            <FormProvider {...methods}>
                <form>
                    <input name={"[" + index + "].history"} ref={methods.register} hidden={true}/>

                    <input
                        type={"file"}
                        name={"file"}
                        accept=".pdf"
                        ref={inputFile}
                        style={{display: 'none'}}
                        onChange={setFile}
                        multiple={true}
                        disabled={props.updateData != undefined}
                    />

                    <HeaderDiv>
                        <FixedWeightText fontSize={"14px"} fontWeight={500}>
                            합격증{props.updateData ? " 편집" : " 등록"}
                        </FixedWeightText>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <CancelButton marginRight={8} link={LINK_TANK}/>
                            {props.updateData && <DeleteButton onClick={handleDeleteClick}>삭제</DeleteButton>}
                            <ConfirmButton
                                onClick={methods.handleSubmit(onSubmit)}>{props.updateData ? "수정" : "등록"}</ConfirmButton>
                        </div>
                    </HeaderDiv>


                    <SelectFileDiv>
                        <ItemBoxDiv>파일등록</ItemBoxDiv>
                        <SelectFileButton onClick={handleClick}>파일선택</SelectFileButton>
                        <FileSelectInput onClick={handleClick} value={fileList} readOnly={true}/>
                        <StyledButton onClick={handleClickUpload}>
                            <FixedWeightText color={"var(--white)"} fontSize={"10px"}
                                             fontWeight={500}>OCR</FixedWeightText>
                        </StyledButton>
                    </SelectFileDiv>

                    <WarningText>한번에 30개 까지만 업로드 가능합니다. OCR 변환 시, 1개에 4~5초 정도 시간이 소요됩니다.</WarningText>

                    <HorizontalDiv>
                        <HorizontalSpace height={"4px"}/>
                    </HorizontalDiv>

                    <TitleDiv>
                        <TitleText fontSize={"18px"} fontWeight={500}>합격증</TitleText>
                    </TitleDiv>
                    <ContentDiv>
                        <TankCertificatePdfScreen
                            index={index}
                            setIndex={setIndex}
                            selectedFile={selectedFile}
                            updateData={props.updateData?.fileUri}
                        />
                        {(!props.tankCertificateData && !props.updateData) &&
                        <TankCertificateContentScreen
                            form={props.form}
                            index={index}
                            myIndex={-1}
                            memberList={props.memberList}
                            getMemberList={props.getMemberList}
                            salesAccountList={props.salesAccountList}
                            getSalesAccountList={props.getSalesAccountList}
                        />
                        }
                        {props.tankCertificateData?.map((item, idx) =>
                            <TankCertificateContentScreen
                                form={props.form}
                                index={index}
                                myIndex={idx}
                                data={item}
                                memberList={props.memberList}
                                getMemberList={props.getMemberList}
                                salesAccountList={props.salesAccountList}
                                getSalesAccountList={props.getSalesAccountList}
                            />)
                        }
                        {props.updateData &&
                        <TankCertificateContentScreen
                            form={props.form}
                            index={index}
                            myIndex={0}
                            data={props.updateData}
                            memberList={props.memberList}
                            getMemberList={props.getMemberList}
                            salesAccountList={props.salesAccountList}
                            getSalesAccountList={props.getSalesAccountList}
                        />
                        }

                    </ContentDiv>

                    <HorizontalDiv>
                        <HorizontalSpace height={"4px"}/>
                    </HorizontalDiv>

                    <TitleDiv>
                        <TitleText fontSize={"18px"} fontWeight={500}>검사 이력</TitleText>
                    </TitleDiv>
                </form>
            </FormProvider>

            <TankExaminationHistoryTable
                files={files[index]}
                form={props.form?.examHistoryType}
            />

            <BottomButtonGroup>
                <Link to={LINK_TANK}
                      style={{textDecoration: 'none', justifyContent: 'center'}}>
                    <BottomCancelButton>취소</BottomCancelButton>
                </Link>
                <BottomConfirmButton
                    onClick={methods.handleSubmit(onSubmit)}>{props.updateData ? "수정" : "등록"}</BottomConfirmButton>
            </BottomButtonGroup>

            <TopButton/>
        </>
    );
}

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  margin-top: 20px;
  margin-bottom: 16px;
`
const SelectFileButton = styled(Button)`
  width: 120px;
  height: 32px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--white);
  font-size: 10px;
  margin-left: 8px;
`
const SelectFileDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

const HorizontalDiv = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 26px;
`

const WarningText = styled(FixedWeightText)`
  font-size: 12px;
  text-align: left;
  color: var(--red);
`
const FileSelectInput = styled(Input)`
  width: 384px;
  height: 32px;
  margin-left: 8px;
  background-color: #eeeeee;
`
export const StyledButton = styled(Button)`
  width: 61px;
  height: 32px;
  margin-left: 8px;
`

const TitleDiv = styled.div`
  margin-bottom: 12px;
`

const TitleText = styled(FixedWeightText)``
const ContentDiv = styled.div`
  display: flex;
  justify-content: center;
`
const ConfirmButton = styled(Button)`
  width: 43px;
  color: var(--white);
`

const BottomConfirmButton = styled(Button)`
  width: 80px;
  height: 32px;
  background-color: var(--blue);
  color: var(--white);
`

const BottomCancelButton = styled(Button)`
  width: 80px;
  height: 32px;
  background-color: var(--white);
  border: solid 1px rgba(0, 0, 0, 0.12);
  color: var(--red);
  margin-right: 8px;
`

const DeleteButton = styled(Button)`
  width: 43px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--gray-3);
  color: var(--white);
  margin-right: 8px;
`

const BottomButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`
export default TankCertificateRegistrationScreen 