import React from "react";
import {Link} from "react-router-dom";
import {LINK_MEMBER} from "../../../resources/LinkConstants";


function MemberListTableLinkText(props: any) {

    return (
        <>
            <Link to={LINK_MEMBER + `/${props.data.userId}`} style={{textDecoration: 'none', justifyContent: 'center'}}>
                {props.data.companyName}
            </Link>
        </>
    )
}

export default MemberListTableLinkText