import React, {useState} from "react";
import TankDownScreen from "./TankDownScreen";
import TankDownTable from "./TankDownTable";
import {useAsync2} from "../../../../hooks/useAsync2";
import {getTankList, Tank} from "../../../../api/tank/certification/getTankList";
import ConfirmModal from "../../../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../../../components/style/button/CircularIndeterminate";
import {PDFDocument, PDFPage} from "pdf-lib";
import {fileDownloadWithFolder, TankInfo, TankInfoFolder} from "../../../../lib/fileDownload";
import {getTankForm} from "../../../../api/tank/certification/getTankForm";


interface TankDownContainerProps {

}

function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
}

function TankDownContainer(props: TankDownContainerProps) {

    const [isLoading, setIsLoading] = useState(false)
    const {data: data, execute: getList, status: getStatus} = useAsync2(getTankList, false)
    const {data: form} = useAsync2(getTankForm, true)

    function drawCover(page: PDFPage, manufacturerNumber: string | undefined) {

        if (manufacturerNumber == undefined) return

        const index = manufacturerNumber.indexOf("(V)") + 3

        if (index > 2) {
            const prefix = manufacturerNumber.substring(0, index)
            const suffix = manufacturerNumber.substring(index + 1)

            page.drawText(prefix, {
                x: 130, y: 702, size: 20
            })
            page.drawText(suffix, {
                x: 350, y: 673, size: 20
            })
        }
    }

    async function onPrint(tankArr: Tank[]) {

        if (tankArr.length == 0) return

        setIsLoading(true)

        const mergedPdf = await PDFDocument.create();

        for (const tank of tankArr) {
            const keys = Object.keys(tank)

            for (const key of keys) {
                const url = key == "certificateDto" ? tank[key]?.fileUri : tank[key]?.url

                if (url) {
                    const buffer = await fetch(url + "?x-request=html").then(res => res.arrayBuffer())
                    const pdf = await PDFDocument.load(buffer)
                    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices())

                    copiedPages.forEach((page) => {
                        if (key == "coverDto") {
                            const cNum = tank.certificateDto?.manufacturerNumber
                            const pNum = tank.pressureTestDto?.manufacturerNumber
                            const aNum = tank.allInOneTestDto?.manufacturerNumber

                            drawCover(page, cNum ? cNum : pNum ? pNum : aNum)
                        }

                        mergedPdf.addPage(page)
                    })
                }
            }
        }

        const mergedPdfFile = await mergedPdf.save()
        const blob = new Blob([mergedPdfFile], {type: "application/pdf"})
        const url = URL.createObjectURL(blob)

        setIsLoading(false)

        window.open(url)
    }

    async function onDown(tankArr: Tank[]) {

        if (tankArr.length == 0) return;

        setIsLoading(true)

        const tankInfoFolder: Array<TankInfoFolder> = []
        let temp = 0

        for (const tank of tankArr) {
            const keys = Object.keys(tank)
            const fileArr: Array<TankInfo> = []

            for (const key of keys) {
                const url = key == "certificateDto" ? tank[key]?.fileUri : tank[key]?.url
                const index = url?.lastIndexOf("__") + 2
                const name = index > 1 ? url?.substring(index, url?.length) : `file.pdf`

                if (url && name) {
                    const buffer = await fetch(url + "?x-request=html").then(res => res.arrayBuffer())
                    const pdf = await PDFDocument.load(buffer)
                    const firstPage = pdf.getPage(0)

                    if (key == "coverDto") {
                        const cNum = tank.certificateDto?.manufacturerNumber
                        const pNum = tank.pressureTestDto?.manufacturerNumber
                        const aNum = tank.allInOneTestDto?.manufacturerNumber

                        drawCover(firstPage, cNum ? cNum : pNum ? pNum : aNum)
                    }

                    // drawCover(key, firstPage, tank.certificateDto)

                    const base64 = await pdf.saveAsBase64()
                    const blob = await b64toBlob(base64, "pdf")

                    fileArr.push({
                        data: blob,
                        name: name
                    })
                }
            }

            if (fileArr.length > 0) {
                tankInfoFolder.push({
                    name: "탱크" + ++temp,
                    tankInfo: fileArr
                })
            }
        }

        fileDownloadWithFolder(tankInfoFolder)
        setIsLoading(false)
    }

    function getFilteredData(data: Tank[] | undefined): Tank[] | undefined {
        if (data) {
            return data.filter(tank => {
                if (tank.allInOneTestDto || tank.pressureTestDto || tank.certificateDto) return true
            })
        }

        return undefined
    }

    return (
        <>
            {(getStatus == 'loading' || isLoading) &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <TankDownScreen/>
            <TankDownTable
                form={form}
                data={getFilteredData(data)}
                getList={getList}
                onPrint={onPrint}
                onDown={onDown}
            />
        </>
    )
}

export default TankDownContainer