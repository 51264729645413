import {GridApi} from "ag-grid-community";
import React, {useEffect, useState} from "react";
import {useComponentWillMount} from "../../../../price/component/utiles";
import SimpleModal from "../../../../../components/modal/SimpleModal";
import styled from "styled-components";
import Button from "../../../../../components/style/button/Button";
import {TankRelease} from "../../../../../api/tank/release/getTankReleaseList";
import {useFormContext} from "react-hook-form";

function TankReleaseAction(props: any,
                           handlePost: (tankRelease: TankRelease) => void,
                           handlePut: (tankReleaseId: string, tankRelease: TankRelease) => void,
                           handleDelete: (tankReleaseId: string) => void) {
    const {getValues} = useFormContext()
    const gridApi: GridApi = props.api
    const [showPostModal, setShowPostModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // custom hook
    useComponentWillMount(() => {
        let editingCells = gridApi.getEditingCells()
        if (editingCells.length !== 0) {
            setDisabled(true)
        }
    })

    useEffect(() => {
        gridApi.addEventListener('rowEditingStarted', onRowEditingStarted)
        gridApi.addEventListener('rowEditingStopped', onRowEditingStopped)

        return () => {
            gridApi.removeEventListener('rowEditingStarted', onRowEditingStarted)
            gridApi.removeEventListener('rowEditingStopped', onRowEditingStopped)
        }
    }, [])

    function onRowEditingStarted(params: any) {
        if (props.node === params.node) {
            setEditing(true)
        } else {
            setDisabled(true)
        }
    }

    function onRowEditingStopped(params: any) {
        if (props.node === params.node) {
            if (isEmptyRow(params.data)) {
                deleteRow(true)
            } else {
                setEditing(false)
            }
        } else {
            setDisabled(false)
        }
    }

    function startEditing() {
        gridApi.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId,
            rowPinned: props.node.rowPinned
        })
    }

    function deleteRow(force = false) {
        if (force) {
            props.api.applyTransactionAsync({remove: [props.data]});
            props.api.refreshCells({force: true});
        } else {
            setShowDeleteModal(true)
        }
    }

    function onConfirm(type: "post" | "edit" | "delete") {
        props.api.stopEditing(false)

        const values = getValues()

        const tankRelease: TankRelease = {
            uid: props.data.uid,
            memberId: values.memberId,
            companyName: values.companyName,
            state: values.state,
            receptionDate: values.receptionDate,
            releaseDate: values.releaseDate,
            arrivalDateTime: values.arrivalDateTime,
            salesManager: values.salesManager,
            volume: values.volume,
            tankReleaseCertificateCount: values.tankReleaseCertificateCount,
            // 조정기
            controller: values.controller,
            hasTongsori: values.hasTongsori,
            hasPackage: values.hasPackage,
            destination: values.destination,
            phoneNumber: values.phoneNumber,
            adminTankReleaseCertificateDtoList: props.data.adminTankReleaseCertificateDtoList,
            memo1: values.memo1,
            memo2: values.memo2
        }

        if (tankRelease.memberId == "" ||
            tankRelease.companyName == "" ||
            tankRelease.state == "" ||
            tankRelease.receptionDate == "" ||
            tankRelease.releaseDate == "" ||
            tankRelease.arrivalDateTime == "" ||
            tankRelease.salesManager == "" ||
            tankRelease.volume == "" ||
            tankRelease.controller == "" ||
            tankRelease.hasTongsori == "" ||
            tankRelease.hasPackage == "" ||
            tankRelease.destination == "" ||
            tankRelease.phoneNumber == "") {
            alert("모든 값을 입력해야합니다.")
            return
        }

        switch (type) {
            case "post":
                handlePost(tankRelease)
                break
            case "edit":
                if (tankRelease.uid) {
                    handlePut(tankRelease.uid, tankRelease)
                }
                break
            case "delete":
                if (tankRelease.uid) {
                    handleDelete(tankRelease.uid)
                }
                break
            default:
                break
        }
    }

    function isEmptyRow(data: any) {
        let dataCopy = {...data}
        delete dataCopy.id
        return !Object.values(dataCopy).some(value => value)
    }

    return (
        <>
            {editing ?
                <>
                    <SearchButton
                        onClick={() => props.node.rowPinned == undefined ? setShowEditModal(true) : setShowPostModal(true)}
                        disabled={disabled}
                        style={{marginRight: "5px"}}
                    >
                        확인
                    </SearchButton>
                    <SearchButton
                        onClick={() => props.api.stopEditing(true)}
                        disabled={disabled}
                    >
                        취소
                    </SearchButton>
                </> :
                <>
                    {props.node.rowPinned == undefined ?
                        <>
                            <SearchButton
                                onClick={startEditing}
                                disabled={disabled}
                                style={{marginRight: "5px"}}
                            >
                                수정
                            </SearchButton>
                            <SearchButton
                                onClick={() => deleteRow()}
                                disabled={disabled}
                            >
                                삭제
                            </SearchButton>
                        </> :
                        <>
                            <SearchButton
                                onClick={startEditing}
                                disabled={disabled}
                            >
                                작성
                            </SearchButton>
                        </>
                    }
                </>
            }

            <SimpleModal
                headerContentText="탱크 출고 설정"
                subText={"입력한 값을 등록하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showPostModal}
                onConfirm={() => onConfirm("post")}
                onCloseModal={() => setShowPostModal(false)}
            />
            <SimpleModal
                headerContentText="탱크 출고 설정"
                subText={"입력한 값으로 수정하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showEditModal}
                onConfirm={() => onConfirm("edit")}
                onCloseModal={() => setShowEditModal(false)}
            />
            <SimpleModal
                headerContentText="탱크 출고 설정"
                subText={"해당 탱크 출고 값을 삭제하시겠습니까?"}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showDeleteModal}
                onConfirm={() => onConfirm("delete")}
                onCloseModal={() => setShowDeleteModal(false)}
            />
        </>
    )
}

const SearchButton = styled(Button)`
  background-color: var(--blue);
  color: var(--white);
`

export default TankReleaseAction