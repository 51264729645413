import React, {ChangeEvent, useRef} from 'react';
import styled from 'styled-components';
import Button from "../../../../components/style/button/Button";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import TextArea from "../../../../components/style/input/TextArea";
import {UseFormMethods} from "react-hook-form";
import {SmsFile} from "../../../../api/sms/getSmsList";
import {fileToBase64} from "../../../../lib/fileToBase64";


interface CustomSmsContentProps {
    methods: UseFormMethods<Record<string, any>>
    setSmsFile: React.Dispatch<React.SetStateAction<SmsFile | undefined>>
    textSize: number
    setTextSize: React.Dispatch<React.SetStateAction<number>>
}

function CustomerSmsContent(props: CustomSmsContentProps) {

    const inputFile = useRef<HTMLInputElement>(null)

    function handleTextArea(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const byte = getEucKrByte(event.currentTarget.value)

        props.setTextSize(byte)
    }

    function getEucKrByte(str: string) {
        return str
            .split('')
            .map(s => s.charCodeAt(0))
            .reduce((prev, c) => (prev + ((c === 10) ? 2 : ((c >> 7) ? 2 : 1))), 0)
    }

    function handleClick() {
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    function handleFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0]

            fileToBase64(file).then(base64 => {
                const convertedBase64 = base64 ? base64.substring(base64.indexOf(",") + 1) : undefined

                if (convertedBase64) {
                    props.setSmsFile({
                        fullName: file.name,
                        base64: convertedBase64
                    })
                }
            })
        }
    }

    return (
        <Wrap>
            <SmsContentTitle>
                <SmsContentTitleText>
                    SMS/LMS/MMS
                </SmsContentTitleText>

                <input
                    type={"file"}
                    name={"file"}
                    accept=".jpg"
                    ref={inputFile}
                    style={{display: 'none'}}
                    onChange={handleFile}
                />
                <PhotoButton onClick={handleClick}>사진첨부</PhotoButton>
            </SmsContentTitle>

            <Contents>
                <ContentsHeader>
                    <ContentsHeaderText>문자</ContentsHeaderText>
                    <ContentsHeaderText style={{color: props.textSize > 2000 ? "var(--red)" : "var(--blue)"}}>
                        {props.textSize} / 2000 Byte
                    </ContentsHeaderText>
                </ContentsHeader>

                <ContentsTextArea
                    name={"contents"}
                    ref={props.methods.register}
                    onChange={handleTextArea}
                />
            </Contents>
        </Wrap>
    );
}

const Wrap = styled.div`
  margin-left: 16px;
`

const SmsContentTitle = styled.div`
  display: flex;
  height: 48px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SmsContentTitleText = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
`

const PhotoButton = styled(Button)`
  font-size: 12px;
  font-weight: 400;
  width: 61px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--white);`

const Contents = styled.section`
  width: 258px;
  height: 394px;
  border: solid 1px #c8cacc;
`

const ContentsHeader = styled.section`
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
  background-color: #f0f3f6;
`

const ContentsHeaderText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 500;
`

const ContentsTextArea = styled(TextArea)`
  width: 240px;
  height: 350px;
  font-size: 8px;
  padding: 8px 12px;
  margin: 4px 8px 0 8px;
`

export default CustomerSmsContent