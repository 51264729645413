import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {MenuItem} from "../../../components/style/select/Select";

export async function getTankForm() {
    const response = await axios.get<BaseResponse<TankForm>>("/tank/form")

    return response.data
}

export interface TankForm {
    searchType: Array<MenuItem>
}

interface TankSearchItem {
    id: number
    title: string
}