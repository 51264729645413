import React from 'react';
import styled from 'styled-components';
import FixedWeightText from "../../../../components/style/text/FixedWeightText";
import SectionTitle from "../../../../components/style/div/SectionTitle";
import {Member, MemberForm, MemberFormType} from "../../../../api/member/Member";
import {RowDiv} from "../../../../components/style/div/RowDiv";
import {getPhoneNumber} from "../../../../lib/getManagerPhoneNumber";


interface CustomerReceiverListProps {
    selectedMemberList: Member[]
    form?: MemberForm
}

function CustomerReceiverList(props: CustomerReceiverListProps) {

    function getReceivedText(member: Member, carrierType?: MemberFormType[]): string {
        return `${getPhoneNumber(member.mobile1, carrierType)} ( ${member.companyName} )`
    }

    return (
        <Wrap>
            <SectionTitle>
                받는사람 목록
            </SectionTitle>

            <Contents>
                <ContentsHeader>
                    <ContentsHeaderText>받는사람</ContentsHeaderText>
                    <HeaderCount>{props.selectedMemberList.length}명</HeaderCount>
                </ContentsHeader>
                {
                    props.selectedMemberList.map((selectedMember, index) =>
                        <ContentsRow key={index}>
                            {getReceivedText(selectedMember, props.form?.memberPhoneCarrierType)}
                        </ContentsRow>
                    )
                }
            </Contents>
        </Wrap>
    );
}

export const Wrap = styled.div`
  margin-left: 16px;
`
const Contents = styled.section`
  width: 258px;
  height: 394px;
  border: solid 1px #c8cacc;
`

const ContentsRow = styled(RowDiv)`
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  font-size: 12px;
`

const ContentsHeader = styled.section`
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.12);
  background-color: #f0f3f6;
`

const ContentsHeaderText = styled(FixedWeightText)`
  font-size: 10px;
  font-weight: 500;
`

const HeaderCount = styled(ContentsHeaderText)`
  color: #4191ff;
`
export default CustomerReceiverList