import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function getRetestDetailForm() {
    const response = await axios.get<BaseResponse<RetestDetailForm>>(`/tank/retest/detail/form`)

    return response.data
}

export interface RetestDetailForm {
    stateType: Array<RetestDetailStateItem>

    [key: string] : Array<RetestDetailStateItem>
}

export interface RetestDetailStateItem {
    id: number
    title: string
}