import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import moment from "moment";
import getNowDate from "../../../lib/getNowDate";
import Moment from "moment";

export async function getTankReleaseList(
    startDate: string = Moment().format('YYYY-MM-01'),
    endDate: string = getNowDate()
) {
    const response = await axios.get<BaseResponse<Array<TankRelease>>>('/tank/release', {
        params: {
            startDate: startDate,
            endDate: endDate
        }
    })

    return response.data
}

export interface TankRelease {
    uid?: string
    memberId: string
    companyName?: string
    state: string
    receptionDate: string
    releaseDate: string
    arrivalDateTime: string
    salesManager: string
    volume: string
    tankReleaseCertificateCount: number
    // 조정기
    controller: string
    hasTongsori: string
    hasPackage: string
    destination: string
    phoneNumber: string
    adminTankReleaseCertificateDtoList: Array<TankReleaseCertificate>
    memo1?: string
    memo2?: string
}

export interface TankReleaseCertificate {
    uid?: string
    serialNumber: string
    isRegistration: boolean
}