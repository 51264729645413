import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {FormProvider, useForm} from "react-hook-form";
import {useHistory, useLocation} from "react-router-dom";
import HorizontalSpace from "../../../components/HorizontalSpace";
import {CertificateForm} from "../../../api/tank/certification/getCertificatelForm";
import StringItemSelect from "../../../components/style/select/StringItemSelect";
import {RowDiv} from "../../../components/style/div/RowDiv";
import {ItemBoxDiv} from "../../../components/style/div/ItemBoxDiv";
import {CommonButton} from "../CommonButton";
import {LINK_TANK_DOWN} from "../../../resources/LinkConstants";
import {TableTitleDiv} from "../../../components/style/div/TableTitleDiv";
import ResultText from "../../../components/ResultText";
import {SearchSelect} from "../../../components/style/select/SearchSelect";
import {Searchbar} from "../../../components/style/input/Searchbar";
import {SearchButton} from "../../../components/style/button/SearchButton";
import {TableTitleText} from "../../../components/style/text/TableTitleText";


interface TankCertificationComponentProps {
    form?: CertificateForm
    result?: number
    selectCount?: number
    getList: (dateType: number, startDate: string, searchType: number, searchQuery: string) => void
    download: () => void
}

type TankSearch = {
    dateType: number
    year: string
    searchType: number
    searchQuery: string
}

function TankCertificateScreen(props: TankCertificationComponentProps) {

    const methods = useForm<TankSearch>({
        mode: "onBlur",
        criteriaMode: "all"
    })
    const history = useHistory()
    const [type, setType] = useState(1)
    const location = useLocation()
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        if (props.form) {
            // methods.setValue("year", "전체")
        }
    }, [props.form])

    function onSubmit() {
        props.getList(
            methods.watch("dateType"),
            methods.watch("year"),
            methods.watch("searchType"),
            methods.watch("searchQuery")
        )
    }

    function goTankDownload() {
        history.push(LINK_TANK_DOWN)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <RowDiv>
                    <ItemBoxDiv>
                        검색
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.searchType}
                        name={"searchType"}
                        reference={methods.register}
                        defaultValue={3}
                    />
                    <Searchbar placeholder={"검색어를 입력하세요"} name={"searchQuery"} ref={methods.register}/>
                    <SearchButton type="submit">검색</SearchButton>
                </RowDiv>
                <RowDiv>
                    <ItemBoxDiv>
                        기간
                    </ItemBoxDiv>
                    <SearchSelect
                        menuItems={props.form?.dateType}
                        name={"dateType"}
                        reference={methods.register}
                        defaultValue={2}
                    />
                    <YearSelect
                        menuItems={props.form?.dateType[methods.watch("dateType") - 1]?.value}
                        name={"year"}
                        reference={methods.register}
                        value={props.form?.dateType[methods.watch("dateType") - 1]?.value[0].value}
                    />
                </RowDiv>

                <HorizontalSpace/>

                <TableTitleDiv>
                    <TableTitleText>합격증 리스트</TableTitleText>
                    <RowDiv>
                        <CertificateButton onClick={goTankDownload}>출고서류 다운로드</CertificateButton>
                        <CertificateButton onClick={props.download}>합격증 다운로드</CertificateButton>
                    </RowDiv>
                </TableTitleDiv>

                <ResultText count={props.result} selectedCount={props.selectCount} />
            </form>
        </FormProvider>
    );
}

const CertificateButton = styled(CommonButton)`
  margin-left: 10px;
  width: 91px;
`

const YearSelect = styled(StringItemSelect).attrs({
    width: "130px",
    height: "32px",
    marginLeft: "8px"
})``


export default TankCertificateScreen