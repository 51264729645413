import React, {useEffect, useState} from 'react';
import {
    ColumnApi,
    GridApi, GridOptions,
    GridReadyEvent
} from "ag-grid-community";
import {AgGridReact} from "ag-grid-react";
import {ColDef, ColGroupDef} from "ag-grid-community/dist/lib/entities/colDef";
import TableNumberColumn from "../../../../../components/table/TableNumberColumn";
import {LINK_TANK} from "../../../../../resources/LinkConstants";
import styled from "styled-components";
import {RowDiv} from "../../../../../components/style/div/RowDiv";
import {TankCheckDefault} from "../../../../../api/tank/check/getTankCheckDefaultList";
import ConfirmButton from "../../../../../components/style/button/ConfirmButton";
import CancelButton from "../../../../../components/style/button/CancelButton";


interface DefaultTableProps {
    tableWidth: number
    tableHeight: number
    gridOptions: GridOptions
    withNumber?: boolean
    handlePut: (payload: TankCheckDefault[]) => void
}

function DefaultTable(props: DefaultTableProps) {

    const [gridApi, setGridApi] = useState<GridApi>()
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()

    useEffect(() => {
        if (gridApi) {
            if (props.gridOptions.rowData) {
                gridApi.setRowData(props.gridOptions.rowData)
            }
        }
    }, [gridApi, props.gridOptions.rowData]);

    function onGridReady(event: GridReadyEvent) {
        setGridApi(event.api)
        setGridColumnApi(event.columnApi)
    }

    function getWithNumberColDef(colDef: (ColDef | ColGroupDef)[]): ColDef[] | ColGroupDef[] {
        if (colDef[0].headerName !== "No") {
            colDef.unshift(
                {
                    headerName: "No",
                    width: 54,
                    cellRendererFramework: TableNumberColumn
                })
        }

        return colDef
    }

    function onSave() {
        let rowData: TankCheckDefault[] = [];

        if (gridApi) {
            gridApi.forEachNode(node => {
                    rowData.push(node.data)
                }
            )

            props.handlePut(rowData)
        }
    }

    return (
        <div className="ag-theme-alpine" style={{height: props.tableHeight, width: props.tableWidth}}>
            <AgGridReact
                rowSelection={props.gridOptions.rowSelection ? props.gridOptions.rowSelection : 'multiple'}
                defaultColDef={{...props.gridOptions.defaultColDef, lockPosition: true}}
                columnDefs={props.withNumber && props.gridOptions.columnDefs ?
                    getWithNumberColDef(props.gridOptions.columnDefs) :
                    props.gridOptions.columnDefs}
                scrollbarWidth={4}
                onGridReady={onGridReady}
                gridOptions={props.gridOptions}
            />

            <DefaultTableButtonGroup>
                <ConfirmButton
                    title="저장"
                    modalTitle="기본값 설정"
                    modalText="해당 값으로 기본값을 저장하시겠습니까?"
                    onSubmit={onSave}
                    marginRight={10}
                />
                <CancelButton link={LINK_TANK}/>
            </DefaultTableButtonGroup>
        </div>
    )
}

const DefaultTableButtonGroup = styled(RowDiv)`
  justify-content: flex-end;
  margin-top: 10px;
`

export default DefaultTable