import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect} from "react";
import {FormControl, MenuItem, SvgIcon, SvgIconProps} from "@material-ui/core";
import MaterialSelect from "@material-ui/core/Select"
import {Controller, useFormContext} from "react-hook-form";
import {BaseFormType} from "../../form/BaseFormType";

export interface StyleProps {
    innerPaddingRight: string
    innerPaddingLeft: string
    iconRight: string
}

const useStyles = makeStyles<Theme, StyleProps>(theme =>
    createStyles({
        formControl: {
            //margin: theme.spacing(1),
            fontSize: '13px',
            '& .MuiOutlinedInput-root': {
                backgroundColor: 'transparent',
                '& fieldset': {
                    borderColor: 'var(--gray-4)',
                },
                '&:hover fieldset': {
                    borderColor: 'var(--blue)',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'var(--blue)',
                },
            },
            '& .MuiSelect-outlined.MuiSelect-outlined': {
                paddingRight: ({innerPaddingRight}) => innerPaddingRight,
                paddingLeft: ({innerPaddingLeft}) => innerPaddingLeft
            },
            "& .MuiMenu-paper": {
                minWidth: '120px'
            },
            '& .MuiSelect-iconOutlined': {
                right: ({iconRight}) => iconRight
            },
        },
        iconStyle: {
            position: 'absolute',
            top: '0.5',
            right: '0',
            display: 'inline-block',
            padding: '12px',
            pointerEvents: 'none'
        }
    }),
);

function HomeIcon(props: SvgIconProps) {
    return (
        <SvgIcon viewBox={"0 0 24 24"} {...props}>
            <rect width="24" height="24" fill="#4191FF" rx="2"/>
            <path fill="#fff" fillRule="evenodd"
                  d="M6.293 10.707l1.414-1.414L12 13.586l4.293-4.293 1.414 1.414L12 16.414l-5.707-5.707z"
                  clipRule="evenodd"/>
        </SvgIcon>
    );
}

export type MenuItem = {
    id: number,
    title: string,
}

type SelectProps = BaseFormType & {
    menuItems?: Array<MenuItem>
    width?: string,
    height?: string,
    marginLeft?: string,
    initialMenuKey?: number
    reference?: any
    value?: number
    defaultValue?: number
    onChange?: () => void
    hideDefaultText?: boolean
    defaultText?: string
    defaultTextSelectable?: boolean
    disabled?: boolean
    innerPaddingRight?: string
    innerPaddingLeft?: string
    iconRight?: string
}

export default function Select(props: SelectProps) {
    const {control} = useFormContext()
    const classes = useStyles({
        innerPaddingRight: props.innerPaddingRight != undefined ? props.innerPaddingRight : "42px",
        innerPaddingLeft: props.innerPaddingLeft != undefined ? props.innerPaddingLeft : "14px",
        iconRight: props.iconRight != undefined ? props.iconRight : "7px",
    });

    useEffect(() => {
        if (props.name && props.value) {
            control.setValue(props.name, props.value)
        }
    }, [props.value])

    return (
        <FormControl
            variant="outlined"
            className={classes.formControl}>
            <Controller
                as={
                    <MaterialSelect
                        style={{
                            boxSizing: "border-box",
                            width: props.width || "fit-contents",
                            height: props.height || "fit-contents",
                            marginLeft: props.marginLeft,
                            fontSize: "10px",
                            fontWeight: "normal"
                        }}
                        displayEmpty
                        onChange={props.onChange}
                        disabled={props.disabled}
                        // IconComponent={() => <HomeIcon className={classes.iconStyle}/>}
                    >
                        {!props.hideDefaultText && <MenuItem value={0} disabled={!props.defaultTextSelectable}>
                            <text style={{fontSize: '10px'}}>{props.defaultText ? props.defaultText : "선택하세요"}</text>
                        </MenuItem>}
                        {props.menuItems &&
                        props.menuItems.map((item, key) => <MenuItem value={item.id} key={key}>
                            <text style={{fontSize: '10px'}}>{item.title}</text>
                        </MenuItem>)}
                    </MaterialSelect>
                }
                defaultValue={props.defaultValue || 0}
                name={props.name || ""}
                rules={props.rules}
                control={control}
                onChange={props.onChange}
            />
        </FormControl>
    );
}