import React, {useState} from "react";
import TankReleaseScreen from "./TankReleaseScreen";
import TankReleaseTable from "./TankReleaseTable";
import {useAsync2} from "../../../hooks/useAsync2";
import {getTankReleaseList, TankRelease, TankReleaseCertificate} from "../../../api/tank/release/getTankReleaseList";
import {getTankReleaseForm} from "../../../api/tank/release/getTankReleaseForm";
import {postTankReleaseCertificate} from "../../../api/tank/release/certificate/postTankReleaseCertificate";
import {putTankRelease} from "../../../api/tank/release/putTankRelease";
import {postTankRelease} from "../../../api/tank/release/postTankRelease";
import {deleteTankRelease} from "../../../api/tank/release/deleteTankRelease";
import {putTankReleaseCertificate} from "../../../api/tank/release/certificate/putTankReleaseCertificate";
import {deleteTankReleaseCertificate} from "../../../api/tank/release/certificate/deleteTankReleaseCertificate";
import {useHistory} from "react-router-dom";
import {LINK_TANK} from "../../../resources/LinkConstants";

export interface SearchDateRange {
    contents: string
    name: "threeMonths" |
        "halfYear" |
        "thisYear" |
        "lastYear"
}

interface TankReleaseContainerProps {

}

function TankReleaseContainer(props: TankReleaseContainerProps) {
    const history = useHistory()

    const {data: form} = useAsync2(getTankReleaseForm, true)
    const {data: tankReleaseList, execute: executeGetList} = useAsync2(getTankReleaseList, true)

    const {execute: executePostRelease} = useAsync2(postTankRelease, false)
    const {execute: executePutRelease} = useAsync2(putTankRelease, false)
    const {execute: executeDeleteRelease} = useAsync2(deleteTankRelease, false)

    const {execute: executePostReleaseCertification} = useAsync2(postTankReleaseCertificate, false)
    const {execute: executePutReleaseCertification} = useAsync2(putTankReleaseCertificate, false)
    const {execute: executeDeleteReleaseCertification} = useAsync2(deleteTankReleaseCertificate, false)

    const [startDate, setStartDate] = useState<string>()
    const [endDate, setEndDate] = useState<string>()

    const searchDateRanges: SearchDateRange[] = [
        {
            contents: "최근 3개월",
            name: "threeMonths"
        },
        {
            contents: "최근 6개월",
            name: "halfYear"
        },
        {
            contents: "올해",
            name: "thisYear"
        },
        {
            contents: "최근 1년",
            name: "lastYear"
        },
    ]

    /*
    * 탱크 출고
    * */
    function getReleases(start?: string, end?: string) {
        if (start && end) {
            executeGetList(start, end).then(() => {
                setStartDate(start)
                setEndDate(end)
            })
        }else {
            executeGetList(startDate, endDate)
        }
    }

    function postRelease(tankRelease: TankRelease) {
        callAPIAndReloadThisPage(executePostRelease, tankRelease)
    }

    function putRelease(tankReleaseId: string, tankRelease: TankRelease) {
        callAPIAndReloadThisPage(executePutRelease, tankReleaseId, tankRelease)
    }

    function deleteRelease(tankReleaseId: string) {
        callAPIAndReloadThisPage(executeDeleteRelease, tankReleaseId)
    }

    function callAPIAndReloadThisPage(execute: (...payload1: any[]) => Promise<any>, ...params: any) {
        execute(...params).then(r => {
            history.replace(LINK_TANK, {page: 4})
            history.go(0)
        })
    }

    /*
    * 탱크 출고 합격증
    * */
    function postReleaseCertificate(tankReleaseId: string, tankReleaseCertificate: TankReleaseCertificate) {
        callAPIAndGetReleases(executePostReleaseCertification, tankReleaseId, tankReleaseCertificate)
    }

    function putReleaseCertificate(tankReleaseId: string, tankReleaseCertificateId: string, tankReleaseCertificate: TankReleaseCertificate) {
        callAPIAndGetReleases(executePutReleaseCertification, tankReleaseId, tankReleaseCertificateId, tankReleaseCertificate)
    }

    function deleteReleaseCertificate(tankReleaseId: string, tankReleaseCertificateId: string) {
        callAPIAndGetReleases(executeDeleteReleaseCertification, tankReleaseId, tankReleaseCertificateId)
    }

    function callAPIAndGetReleases(execute: (...payload1: any[]) => Promise<any>, ...params: any) {
        execute(...params).then(() => {
            getReleases(startDate, endDate)
        })
    }

    return (
        <>
            <TankReleaseScreen
                result={tankReleaseList?.length}
                searchDateRanges={searchDateRanges}
                getList={getReleases}
            />

            <TankReleaseTable
                form={form}
                data={tankReleaseList}
                getList={getReleases}

                postTankRelease={postRelease}
                putTankRelease={putRelease}
                deleteTankRelease={deleteRelease}

                postTankReleaseCertificate={postReleaseCertificate}
                putTankReleaseCertificate={putReleaseCertificate}
                deleteTankReleaseCertificate={deleteReleaseCertificate}
            />
        </>
    )
}

export default TankReleaseContainer