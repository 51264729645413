import React, {useEffect, useState} from 'react';
import {LINK_TANK_REGISTRATION} from "../../../resources/LinkConstants";
import {Link} from "react-router-dom";
import {
    FilterChangedEvent,
    GridOptions,
    ICellRendererParams
} from "ag-grid-community";
import {RowSelectedEvent} from "ag-grid-community/dist/lib/events";
import {ExaminationHistory} from "../../../api/tank/certification/postCertificate";
import styled from "styled-components";
import FixedWeightText from "../../../components/style/text/FixedWeightText";
import {TankInfo} from "../../../lib/fileDownload";
import ErpTableComponent from "../../../components/table/ErpTable";
import ErpTableFilter from "../../../components/table/filter/ErpTableFilter";
import {CertificateForm} from "../../../api/tank/certification/getCertificatelForm";


interface TableExampleGridComponentProps {
    data?: Array<any>
    setTankInfo: React.Dispatch<React.SetStateAction<TankInfo[]>>
    form?: CertificateForm
}

function TankListTable(props: TableExampleGridComponentProps) {

    const [gridOptions, setGridOptions] = useState<GridOptions>({})

    useEffect(() => {
        if (props.data && props.form) {
            setGridOptions({
                columnDefs: [
                    {
                        headerName: " 제조번호",
                        field: "manufacturerNumber",
                        width: 101.6,
                        cellRendererFramework: manufacturerNumberCellRenderer
                    },
                    {
                        headerName: "제조사",
                        field: "manufacturer",
                        width: 101.6
                    },
                    {
                        headerName: " 용량",
                        field: "volume",
                        filterFramework: ErpTableFilter,
                        filterParams: {
                            checkBoxInfos: props.form.volumeType.map(volumeType => {
                                return {
                                    name: volumeType.title,
                                    checked: true
                                }
                            })
                        },
                        width: 101.6
                    },
                    {
                        headerName: " 자가여부",
                        field: "ownerType",
                        width: 101.6
                    },
                    {
                        headerName: " 회원사명",
                        field: "companyName",
                        width: 101.6
                    },
                    {
                        headerName: " 검사합격일",
                        field: "passDate",
                        width: 101.6
                    },
                    {
                        headerName: " 다음검사",
                        field: "nextExaminationDate",
                        width: 101.6
                    },
                    {
                        headerName: " 최신검사",
                        field: "history",
                        width: 303.8,
                        cellRendererFramework: historyCellRenderer
                    },
                    {
                        headerName: "",
                        field: "selected",
                        width: 64,
                        checkboxSelection: true,
                        headerCheckboxSelection: true
                    },
                ],

                rowData: props.data,

                rowMultiSelectWithClick: true,

                onFilterChanged(event: FilterChangedEvent) {
                    props.setTankInfo([])
                    event.api.deselectAll()
                },

                onRowSelected(event: RowSelectedEvent) {

                    const node = event.api.getDisplayedRowAtIndex(event.rowIndex)

                    if (node) {
                        if (node.isSelected()) {
                            props.setTankInfo(prevState => [
                                ...prevState,
                                {
                                    data: node.data.fileUri,
                                    name: node.data.manufacturerNumber
                                }
                            ])
                        } else {
                            props.setTankInfo(prevState =>
                                prevState.filter(prev => prev.name !== node.data.manufacturerNumber)
                            )
                        }
                    }
                },
            })
        }
    }, [props.data, props.form])

    function manufacturerNumberCellRenderer(params: ICellRendererParams) {
        return (
            <>
                <Link to={LINK_TANK_REGISTRATION + `/${params.data.uid}`}
                      style={{textDecoration: 'none', justifyContent: 'center'}}>
                    {params.data.manufacturerNumber}
                </Link>
            </>
        )
    }

    function historyCellRenderer(params: ICellRendererParams) {
        const threeHistories = params.value.slice(-3)

        return (
            <Div>
                {threeHistories.map((item: ExaminationHistory, index: number) => {
                    const testType = props.form ?
                        "(" + props.form.examinationHistoryType[item.testType - 1].title + ")" :
                        ""

                    return (
                        <Flex1 key={index}>
                            <StyledText>{item.testDate} {testType}</StyledText>
                        </Flex1>
                    )
                })}
            </Div>
        )
    }

    return (
        <>
            <ErpTableComponent
                tableWidth={1080}
                tableHeight={576}
                gridOptions={gridOptions}
            />
        </>
    )
}

const Div = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const Flex1 = styled.div`
  display: flex;
  flex: 1;
  width: 101.3px;
  justify-content: center;
`

const StyledText = styled(FixedWeightText)`
  font-size: 100%;
  font-weight: 400;
`
export default TankListTable