import axios from "axios";
import {BaseResponse} from "../../BaseResponse";

export async function postCertificate(data: Array<TankCertificate>) {
    const response = await axios.post<BaseResponse<boolean>>("/tank/certificate", data)

    return response.data
}

export type TankCertificate = {
    uid?: string
    memberId?: string
    companyName?: string
    certificateNumber: string
    type?: number
    typeValue?: string
    manufacturer: string
    manufacturerNumber: string
    volume?: string
    gasName?: string
    passDate: string
    nextExaminationDate?: string
    ownerType?: string
    memo?: string
    finalTestDate: string
    salesAccountName?: string
    userName?: string
    address?: string
    fileUri: string
    history: Array<ExaminationHistory>
}

export type ExaminationHistory = {
    uid?: string
    certificateId?: string
    testDate: string
    testType: number
    testingOrganization: string
    fileUri: string
    fileName?: string
}