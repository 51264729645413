import React from 'react';
import {Redirect, Route} from "react-router-dom";
import getLocalStorageItemOrNull from "../../lib/getLocalStorageItemOrNull";
import {LOCAL_STORAGE_KEY_JWT} from "../../resources/LocalStorageConstants";
import {useSelector} from "react-redux";
import {RootState} from "../../redux";
import {RouteProps} from "react-router";
import {LINK_SIGN_IN} from "../../resources/LinkConstants";

interface MainContainerProps extends RouteProps {
}

function AuthRoute(props: MainContainerProps) {

    const token: string | null = getLocalStorageItemOrNull(LOCAL_STORAGE_KEY_JWT)
    const {data: erpUser} = useSelector((state: RootState) => state.accountReducers.signInReducer.signInResponse)

    if (token == null && erpUser == null) {
        return (<Redirect to={{
            pathname: LINK_SIGN_IN,
            state: {referrer: props.location}
        }}/>)
    }
    return (
        <Route {...props}/>
    );
}

export default AuthRoute