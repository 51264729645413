import React from "react";
import styled from "styled-components";
import FixedWeightText from "../../../../components/style/text/FixedWeightText";


interface RadioButtonGroupProps {
    isCms: boolean
    setIsCms: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

function MemberInfoCMSRadioButton(props: RadioButtonGroupProps) {

    return (
        <ItemDiv>
            <StyledItemBoxDiv>
                <Text>
                    CMS 여부
                </Text>
            </StyledItemBoxDiv>
            <>
                <StyledDiv>
                    <RadioButton
                        type="radio"
                        value="사용"
                        checked={props.isCms}
                        onChange={() => {
                            props.setIsCms(true)
                        }}
                    />
                    <InputText>사용</InputText>
                </StyledDiv>

                <StyledDiv>
                    <RadioButton
                        type="radio"
                        value="미사용"
                        checked={!props.isCms}
                        onChange={() => {
                            props.setIsCms(false)
                        }}
                    />
                    <InputText>미사용</InputText>
                </StyledDiv>
            </>
        </ItemDiv>
    )
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  padding-left: 12px;
`

const StyledItemBoxDiv = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  width: 120px;
  height: 64px;
  padding-left: 16px;
  padding-right: 20px;
  background-color: var(--pale-grey);
  align-items: center;
`

const Text = styled(FixedWeightText)`
  font-size: 14px;
  font-weight: 400;
`

const InputText = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  align-self: center;
  margin-left: 8px;
  margin-right: 16px;
`

const RadioButton = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 8px;
  border: solid 1px #bec0c2;
  background-color: white;
  align-self: center;
  justify-self: center;
`

const ItemDiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding-bottom: 10px;
`

export default MemberInfoCMSRadioButton