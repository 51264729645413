import React, {forwardRef, useImperativeHandle} from "react";
import {Controller, useFormContext} from "react-hook-form";
import {useStringItemSelectStyles} from "../../../../../components/style/select/StringItemSelect";
import {Member} from "../../../../../api/member/Member";
import {FormControl, TextField} from "@material-ui/core";
import {getPhoneNumber} from "../../../../../lib/getManagerPhoneNumber";
import Autocomplete from '@material-ui/lab/Autocomplete';

export default forwardRef((props: any, ref) => {
    const {control, watch, setValue} = useFormContext()
    const classes = useStringItemSelectStyles()

    const memberPhoneCarrierType = props.memberPhoneCarrierType
    const memberList: Array<Member> = props.memberList
    const defaultMember = memberList.find(member => member.companyName == props.value)

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return watch("companyName")
            }
        }
    })

    function onChange(
        event: React.ChangeEvent<{}>,
        value: Member
    ) {
        setValue("memberId", value.uid)
        setValue("companyName", value.companyName)
        setValue("destination", value.deliveryAddress)
        setValue("phoneNumber", getPhoneNumber(value.mobile1, memberPhoneCarrierType))
    }

    return (
        <>
            <FormControl
                variant="outlined"
                className={classes.formControl}>
                <Controller
                    name="memberId"
                    control={control}
                    // to hide the error message
                    defaultValue={defaultMember ? defaultMember.uid : ""}
                    render={() => (
                        <Autocomplete
                            className={classes.autoComplete}
                            style={{
                                boxSizing: "border-box",
                                width: `${props.colDef.width}px`,
                                height: "36px",
                                fontSize: "10px",
                                fontWeight: "normal"
                            }}
                            defaultValue={defaultMember}
                            renderOption={(options) => <div
                                style={{fontSize: "10px", fontWeight: "normal"}}>{options.companyName}</div>}
                            options={memberList}
                            getOptionLabel={(member) => member.companyName}
                            renderInput={(params) => <TextField{...params}/>}
                            disableClearable={true}
                            onChange={onChange}
                        />
                    )}
                />

                {/* for setting companyName */}
                <Controller
                    name="companyName"
                    // to hide the error message
                    defaultValue={defaultMember ? defaultMember.companyName : ""}
                    control={control}
                    render={() => (<></>)}
                />
                {/* for setting destination */}
                <Controller
                    name="destination"
                    // to hide the error message
                    defaultValue={defaultMember ? defaultMember.deliveryAddress : ""}
                    control={control}
                    render={() => (<></>)}
                />
                {/* for setting phoneNumber */}
                <Controller
                    name="phoneNumber"
                    // to hide the error message
                    defaultValue={defaultMember ? getPhoneNumber(defaultMember.mobile1, memberPhoneCarrierType) : ""}
                    control={control}
                    render={() => (<></>)}
                />
            </FormControl>
        </>
    )
})