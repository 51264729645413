import React, {ReactNode, useState} from 'react';
import styled from 'styled-components';
import Button from "./Button";
import FixedWeightText from "../text/FixedWeightText";
import SimpleModal from "../../modal/SimpleModal";


interface ConfirmButtonProps {
    title: string
    modalTitle: string
    modalText: string
    onSubmit: () => void
    width?: number
    height?: number
    marginLeft?: number
    marginRight?: number
    disabled?: boolean
    children?: ReactNode
}

function ConfirmButton(props: ConfirmButtonProps) {

    const [showModal, setShowModal] = useState(false)

    function onConfirm() {
        props.onSubmit()
    }

    return (
        <>
            <StyledButton
                style={{
                    width: props.width,
                    height: props.height,
                    marginLeft: props.marginLeft,
                    marginRight: props.marginRight,
                }}
                type="submit"
                onClick={() => setShowModal(true)}
                disabled={props.disabled}
            >
                <ButtonText>{props.children ? props.children : props.title}</ButtonText>
            </StyledButton>

            <SimpleModal
                headerContentText={props.modalTitle}
                subText={props.modalText}
                submitText="확인"
                buttonColor="var(--blue)"
                showModal={showModal}
                onConfirm={onConfirm}
                onCloseModal={() => setShowModal(false)}
            />
        </>
    )
}

const StyledButton = styled(Button)`
  width: 43px;
  height: 32px;
  background-color: var(--blue);
  align-items: center;
`

const ButtonText = styled(FixedWeightText).attrs({
    fontWeight: 500
})`
  width: 26px;
  height: 20px;
  font-size: 13px;
  color: var(--white);
`

export default ConfirmButton