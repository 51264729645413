import styled from "styled-components";
import {ReactNode} from "react";

type ButtonProps = {
    children: ReactNode
}

const Button = styled.button.attrs((props) => ({
    type: props.type || "button"
}))<ButtonProps>`
  border-radius: 4px;
  border-width: 0;
  background: var(--blue);

  &:disabled {
    background-color: var(--gray-4);
  }
`

export default Button