import axios from "axios";
import {BaseResponse} from "../../BaseResponse";
import {TankCertificate} from "../certification/postCertificate";
import getFirstDateOfTheMonth from "../../../lib/getFirstDateOfTheMonth";

export async function getRetestList(
    searchType: number = 1,
    searchDate: string = getFirstDateOfTheMonth()
) {
    const response = await axios.get<BaseResponse<Retest[]>>(`/tank/retest`, {
        params: {
            searchType: searchType,
            searchDate: searchDate
        }
    })

    return response.data
}

export interface Retest {
    uid?: string
    memberId: string
    companyName: string
    requestDate: string
    completionRequestDate: string
    completionDate?: string
    retestTankList: Array<RetestTank>
}

export interface RetestTank extends TankCertificate {
    uid: string
    retestId: string
    certificateId: string
    companySalesPlaceName?: string
    address?: string
    state: number
}