import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Button from "../../../../../components/style/button/Button";
import BaseModal from "../../../../../components/modal/BaseModal";
import ModalHeader from "../../../../../components/modal/Header";
import {FormProvider, useForm} from "react-hook-form";
import Input from "../../../../../components/style/input/Input";
import ErpTableComponent from "../../../../../components/table/ErpTable";
import {GridOptions} from "ag-grid-community";
import TableNumberColumn from "../../../../../components/table/TableNumberColumn";
import TankCertificateModalButton from "./TankCertificateModalButton";
import {Member} from "../../../../../api/member/Member";
import {ItemBoxDiv} from "../../../../../components/style/div/ItemBoxDiv";


interface TankCertificateSearchForMemberModalProps {
    showModal: boolean
    onCloseModal: () => void
    onConfirm: (memberId: string | undefined, companyName: string) => void
    memberList?: Member[]
    getMemberList: (
        dateType?: number,
        startDate?: string,
        endDate?: string,
        searchType?: number,
        searchQuery?: string) => void
}

function TankCertificateMemberModal(props: TankCertificateSearchForMemberModalProps) {

    const methods = useForm({
        mode: "onBlur"
    })

    const [gridOptions, setGridOptions] = useState<GridOptions>({
        columnDefs: [
            {
                headerName: "No",
                width: 54,
                cellRendererFramework: TableNumberColumn
            },
            {
                headerName: "회사명",
                field: "companyName",
                width: 100
            },
            {
                headerName: "사업자 번호",
                field: "businessNumber",
                width: 100
            },
            {
                headerName: "주소",
                field: "address",
                width: 324
            },
            {
                headerName: "기능",
                cellRenderer: "modalButton",
                width: 60
            }
        ],

        rowData: [],

        frameworkComponents: {
            modalButton: (params: any) => TankCertificateModalButton(params, onConfirm)
        }
    })

    useEffect(() => {
        if (props.memberList) {
            setGridOptions({
                ...gridOptions,
                rowData: props.memberList
            })
        }
    }, [props.memberList])

    function onConfirm(data: Member) {
        props.onConfirm(data.uid, data.companyName)
        closeModal()
    }

    function closeModal() {
        props.onCloseModal()
    }

    function onSubmit() {
        props.getMemberList(
            undefined,
            undefined,
            undefined,
            1,
            methods.watch("searchQuery")
        )
    }

    return (
        <FormProvider {...methods}>
            <form>
                <BaseModal showModal={props.showModal} onCancel={closeModal}>
                    <ModalDiv>
                        <ModalHeader title={"회원사 검색"} onCancel={closeModal}/>
                        <ContentDiv>
                            <SearchSection>
                                <Row>
                                    <ItemBoxDiv>
                                        회원사명
                                    </ItemBoxDiv>
                                    <Searchbar placeholder={"검색어를 입력하세요"} name={"searchQuery"} ref={methods.register}/>
                                    <SearchButton onClick={onSubmit}>검색</SearchButton>
                                </Row>
                            </SearchSection>

                            <ErpTableComponent
                                tableWidth={640}
                                tableHeight={500}
                                gridOptions={gridOptions}
                            />
                        </ContentDiv>
                    </ModalDiv>
                </BaseModal>
            </form>
        </FormProvider>
    )
}

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 720px;
  height: 832px;
  border-radius: 4px;
  background-color: var(--white);
`
const ContentDiv = styled.div`
  display: flex;
  height: 710px;
  flex-direction: column;
  padding: 22px 32px 32px 32px;
`

const Searchbar = styled(Input)`
  display: flex;
  box-sizing: border-box;
  width: 500px;
  height: 32px;
  padding-left: 12px;
  margin-left: 8px;
  font-size: 10px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 802px;
  align-items: center;
`

const SearchSection = styled(Row)`
  margin-top: 22px;
  align-items: center;
`

const SearchButton = styled(Button)`
  width: 43px;
  height: 32px;
  margin-left: 8px;
  background-color: var(--blue);
  color: var(--white);
  font-size: 10px;
`

export default TankCertificateMemberModal