import React, {useEffect, useState} from 'react';
import TankScreen from "./TankScreen";
import {RouteComponentProps} from "react-router-dom";
import {LINK_TANK} from "../../resources/LinkConstants";

function TankContainer(props: RouteComponentProps) {
    const history = props.history;
    const [page, setPage] = useState()

    useEffect(() => {
        if (history.location.state && history.location.state.page) {
            setPage(history.location.state.page)
            history.replace(LINK_TANK, undefined)
        }
    }, [])

    return (
        <TankScreen
            page={page}
        />
    );
}

export default TankContainer