import axios from 'axios';
import {BaseResponse} from "../BaseResponse";


export async function signUp(adminDto: SignUpRequest) {
    const response = await axios.post<BaseResponse<string>>(`/signUp`, adminDto)

    return response.data
}

export async function haveDuplicateId(adminId: string) {
    const response = await axios.get<BaseResponse<boolean>>(`/signUp/checkDuplicate`, {
        params: {
            adminId: adminId
        }
    })

    return response.data
}

export interface AdminEmail {
    name: string
    value: string
}

export interface AdminPhoneNumber {
    name: string
    body: string
}

export interface SignUpRequest {
    adminId: string,
    password: string,
    tel: string,
    mobile: AdminPhoneNumber[],
    fax: string | undefined,
    email: AdminEmail | undefined,
    address: string,
    addressDetail: string | undefined
}