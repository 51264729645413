import React from "react";
import {
    deletePrice,
    GasPriceTrend,
    getGasPriceForm,
    getPriceArr,
    postPrice,
    putPrice
} from "../../api/price/Price";
import PriceScreen from "./PriceScreen";
import {useHistory} from "react-router-dom";
import {useAsync2} from "../../hooks/useAsync2";
import ConfirmModal from "../../components/modal/ConfirmModal";
import CircularIndeterminate from "../../components/style/button/CircularIndeterminate";


interface PriceContainerProps {

}

function PriceContainer(props: PriceContainerProps) {

    const history = useHistory()

    const {data: tableData, execute: executeGet, status: getStatus} = useAsync2(getPriceArr, true)
    const {data: form} = useAsync2(getGasPriceForm, true)
    const {execute: executePost, status: postStatus} = useAsync2(postPrice, false)
    const {execute: executePut, status: putStatus} = useAsync2(putPrice, false)
    const {execute: executeDelete, status: deleteStatus} = useAsync2(deletePrice, false)

    function handlePost(payload: GasPriceTrend) {
        executePost(payload).then(() => {
            history.go(0)
        })
    }

    function handlePut(payload: GasPriceTrend) {
        executePut(payload).then(() => {
            history.go(0)
        })
    }

    function handleDelete(uid: string) {
        executeDelete(uid).then(() => {
            history.go(0)
        })
    }

    return (
        <>
            {(getStatus == 'loading' || postStatus == 'loading' || putStatus == 'loading' || deleteStatus == 'loading') &&
            <ConfirmModal showModal={true}><CircularIndeterminate/></ConfirmModal>}

            <PriceScreen
                form={form}
                tableData={tableData}
                handleGet={executeGet}
                handlePost={handlePost}
                handlePut={handlePut}
                handleDelete={handleDelete}
            />
        </>
    )
}

export default PriceContainer