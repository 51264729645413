import React, {ReactNode} from 'react';
import styled from 'styled-components';
import FixedWeightText from "../text/FixedWeightText";

interface SectionTitleComponentProps {
    children: ReactNode
}

function SectionTitle(props: SectionTitleComponentProps) {
    return (
        <WrapDiv>
            <ContentsTitle>
                {props.children}
            </ContentsTitle>
        </WrapDiv>
    );
}

const WrapDiv = styled.div`
  display: flex;
  height: 48px;
  align-items: center; 
`

const ContentsTitle = styled(FixedWeightText)` 
  font-size : 14px;
  font-weight: 400;
`

export default SectionTitle