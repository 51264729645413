import React from "react";
import moment from "moment";
import styled from "styled-components";


type FormatType =
    "bizNo"
    | "phoneNo"
    | "YYYY-MM-DD HH:mm:ss"
    | "YYYY-MM-DD"
    | "YYYY-MM"
    | "YYYY"
    | "HH:mm:ss"
    | "removeHyphen"

interface FormatterProps {
    data: string | undefined
    format: FormatType
    subData?: string
    subFormat?: FormatType
}

function Formatter(props: FormatterProps) {

    return (
        <>
            <MainDiv>
                {getFormattedContents(props.data, props.format)}
            </MainDiv>
            {
                props.subData &&
                props.subFormat &&
                <SubDiv>
                    {' '}
                    {getFormattedContents(props.subData, props.subFormat)}
                </SubDiv>
            }
        </>
    )
}

export function getFormattedContents(data: string | undefined, formatType: FormatType): string {

    if (!data) {
        return ""
    }

    switch (formatType) {
        case "bizNo":
            return data.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')
        case "phoneNo":
            return data.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, "$1-$2-$3")
        case "YYYY":
        case "YYYY-MM":
        case "YYYY-MM-DD":
        case "YYYY-MM-DD HH:mm:ss":
        case "HH:mm:ss":
            return moment(data).format(formatType)
        case "removeHyphen":
            return data.replace(/-/g, "")
        default:
            return ""
    }
}

const MainDiv = styled.div`
  height: 15px;
`

const SubDiv = styled.div`
  text-align: center;
`

export default Formatter