import TankReleaseSelectBoxEditor from  "./component/editor/TankReleaseSelectBoxEditor";
import TankReleaseDateEditor from "./component/editor/TankReleaseDateEditor";
import TankReleaseInputEditor from "./component/editor/TankReleaseInputEditor";
import TankReleaseMemberSelectBoxEditor from "./component/editor/TankReleaseMemberSelectBoxEditor";
import TankReleaseCertificateAction from "./component/editor/TankReleaseCertificateAction";
import TankReleaseAction from "./component/editor/TankReleaseAction";

export default {
    TankReleaseMemberSelectBoxEditor,
    TankReleaseSelectBoxEditor,
    TankReleaseDateEditor,
    TankReleaseInputEditor,
    TankReleaseAction,
    TankReleaseCertificateAction
}